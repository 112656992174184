import { Component, ViewChild, OnInit } from '@angular/core';
import { TournamentService } from '../../tournamentsSetup/tournament.service';
import { ConfigService } from '../../../infrastructure/config.service';
import { Router } from '@angular/router';
import { BasicService, TournamentTable, TournamentMetadata,TournamentInfo } from '../../common/basic.service';
import { AccountService, LoggedInUser } from '../../navigation/common/account.service';
import { SpinnerService } from '../../../infrastructure/network/spinner.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-table',
  templateUrl: './tournamentTable.component.html',
  styleUrls: ['./tournamentTable.component.css']
})
export class TournamentTableComponent implements OnInit {
  tournamentInfo: TournamentInfo;
  tournamentMetadata: TournamentMetadata;
  potSumZero: boolean = false;
  user:LoggedInUser;
  dataSource: MatTableDataSource<TournamentTable>;
  displayedColumns: string[] = ['place', 'userName', 'winningsInTournamentPoints', 'potentialWinningsInTournamentPoints', 'button'];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private tournamentService: TournamentService,
    private configService: ConfigService,
    private router: Router,
    private basicService: BasicService,
    private accountService: AccountService,
    public spinnerService: SpinnerService,
  ) { }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async ngOnInit() {
    this.tournamentMetadata = history.state.tournamentMetadata;
    if(!this.tournamentMetadata) {
      this.router.navigateByUrl('',{skipLocationChange:true});
      return;
    }
    this.user = this.accountService.getLoggedInUser();
    this.tournamentInfo = await this.basicService.getTournamentTable(this.tournamentMetadata.id)
    this.dataSource = new MatTableDataSource(this.tournamentInfo.results);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.user = this.accountService.getLoggedInUser()
    let results = this.tournamentInfo.results
    let potSum = 0;
    for(let result of results){
      potSum = potSum + result.potentialWinningsInTournamentPoints;
    }
    if(potSum == 0){
      this.potSumZero = true;
    }
  }
}