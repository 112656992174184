<app-top-bar-tournament></app-top-bar-tournament>

<mat-progress-spinner *ngIf="spinnerService.visibility | async" style="margin-top: 30vh; margin-left: auto; margin-right: auto; align-items: center; text-align: center;" [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>


<mat-card style="text-align: center; margin-bottom: 1vh; max-width: 680px; margin-left: auto; margin-right: auto;">
  <div style="font-weight: 600; font-size: 25px;">News</div>
  <div style="padding-top: 0px;">
    <mat-card style="margin-bottom: 2vh; margin-top: 2vh;">
      <div style="text-align: center; font-weight: 600;font-size: 20px; margin-bottom: 1vh;">Welcome to Oddsee!</div>
        <div style="width:100%; text-align: justify; align-items: center; margin-left: auto; margin-right: auto;">
          Enjoy competing with others in betting tournaments! This is an early alfa version so please remember we're constantly improving the app on a daily basis. 
          <span style="font-weight: 600;">Please send us as much feedback as possible :) Good luck!</span>
        </div>
    </mat-card>
  </div>
</mat-card>

<mat-card *ngIf="myTournamentsData?.length > 0" style="text-align: center; margin-bottom: 1vh; max-width: 680px; margin-left: auto; margin-right: auto;">
  <div style="font-weight: 600; font-size: 25px;">My Tournaments</div>
  <div style="padding-top: 0px;" *ngFor="let tournament of myTournamentsData">
    <mat-card style="margin-bottom: 2vh; margin-top: 2vh;">
        <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
          {{ tournament.name }} 
        </div>
      <mat-card-actions>
        <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 7px;">
          Participants count: {{ tournament.rT_ParticipantsCount }}
          <div *ngIf="tournament.entryFee != 0">
            Prize pool: {{ tournament.rT_PricePool }}
          </div>
        </div>
          <div *ngIf="!tournament.started; else tournamentStarted" style="margin-bottom: 3vh;">
            <div style="width:100%; margin-top: 7px; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
              <div *ngIf="86400 > tournament.secondsToStart">
                <span style="font-weight: 600">Tournament starts in: </span> <countdown [config]="{leftTime: tournament.secondsToStart}"></countdown> <br />
              </div>
              <span style="font-weight: 600;">Tournament starts at:</span> {{ tournament.betsOpenDateUtcForFirstTurn | date:'HH:mm dd-MM-yyyy' }}
            </div>
            <div style="margin-top: 10px;">
              <button color="success" mat-raised-button class="button fancy-button"
                (click)="openTournamentDetails(tournament)"
                style="height: 40px; text-align: center;" >
                  Details
                </button>
            </div>
          </div>
          <ng-template #tournamentStarted>
            <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
              <button color="success" mat-raised-button class="button fancy-button" 
                style="padding: 0px 16px; margin: 0px 8px; height: 40px; text-align: center;" 
                [routerLink]="['/oddsee/tournaments', tournament.id]" 
                (click)="couponService.specifySelectedTournament(tournament.id)">
                Open
              </button>
              <button color="success" mat-raised-button class="button fancy-button" 
              [routerLink]="['/oddsee/tournaments', tournament?.id, 'table']" 
              [state]="{tournamentMetadata: tournament}" 
              style="height: 40px; text-align: center;" >
              Table
              </button>
              <button color="success" mat-raised-button class="button fancy-button"
              (click)="openTournamentDetails(tournament)"
              style="height: 40px; text-align: center;" >
                Details
              </button>
            </div>
          </ng-template>
        
      </mat-card-actions>
    </mat-card>
  </div>
</mat-card>

<mat-card style="text-align: center; max-width: 680px; margin-left: auto; margin-right: auto;">
  <div style="font-weight: 600; font-size: 25px;">Tournament Registation</div>

  <div *ngIf="readyToJoinTournaments?.length == 0">
    <div style="text-align: center; margin-top: 1vh;">
      <div *ngIf="myTournamentsData?.length > 0; else noTournaments">
        You are registered to all open for registration tournaments!
      </div>
      <ng-template #noTournaments>
        Right now there are no open for registration tournaments :( Please check again later!  
      </ng-template>
    </div>
  </div>
  <div style="padding-top: 0px;" *ngFor="let tournament of readyToJoinTournaments">
    <mat-card style="margin-bottom: 2vh; margin-top: 2vh;">
      <mat-card-header>
        <mat-card-title>{{ tournament.name }} </mat-card-title>
        <mat-card-subtitle>
          Registration Open Date: {{ tournament.registrationOpenDateUtc | date:'HH:mm dd-MM-yyyy' }} <br />
          Registration Close Date: {{ tournament.registrationCloseDateUtc | date:'HH:mm dd-MM-yyyy' }} <br />
          <!-- Tournament Start: {{ tournament.betsOpenDateForFirstTurn | date:'HH:mm dd-MM-yyyy' }} <br />
          First Turn End: {{ tournament.betsCloseDateForFirstTurn | date:'HH:mm dd-MM-yyyy' }}  -->
        </mat-card-subtitle>
      </mat-card-header>
      <!-- <img mat-card-image src="https://i.postimg.cc/fTnXskYR/asdd.png"> -->
      <mat-card-content>
        <!-- <p style="text-align: justify;">
          <span style="font-weight: 600;">Place for tournament structure description. </span><br /> <br />
          orem ipsum dolor sit amet, consectetur adipiscing elit. 
          Sed sed libero quis leo tincidunt tempus. 
          Sed pellentesque quam ut tortor feugiat sodales. Vestibulum interdum dapibus libero, 
          et lacinia felis vestibulum ac. Interdum et malesuada fames ac ante ipsum primis in faucibus. 
          Cras et arcu vel nibh semper varius. Praesent quis vulputate felis. Vestibulum placerat massa enim, 
          a porttitor turpis vehicula in. Phasellus tempor dolor quis bibendum scelerisque. 
          Sed lacinia eget urna ut sagittis. Proin sed ante eu nulla rhoncus egestas. 
          Praesent sagittis facilisis luctus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; 
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id venenatis turpis, eget blandit nulla. 
          Aenean purus libero, feugiat ut cursus ut, semper eget nulla. <br /><br />
          <span style="font-size: 11px;">Tournament GUID: {{ tournament.id }} <br /><span style="font-weight: 600;">Oddsee commission: </span>10%</span><br />
        </p> -->
      </mat-card-content>
      <div *ngIf="tournament.entryFee != 0; else FreeTournament">
        <div style="font-size: 16px; color:#6200EE; font-weight: 600; width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
          Entry Fee: {{ tournament.entryFee }}
        </div>
      </div>
      <ng-template #FreeTournament>
        <div style="font-size: 16px; color:#17a105b0; font-weight: 600; width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
          Free Tournament
        </div>
      </ng-template>
      <mat-card-actions>
        <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 7px;">
          Participants count: {{ tournament.rT_ParticipantsCount }}
          <div *ngIf="tournament.entryFee != 0">
            Prize pool: {{ tournament.rT_PricePool }}
          </div>
        </div>
        <div *ngIf="tournament.joined; else elseBlock" style="display: flex;">
          <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
            <div *ngIf="!tournament.started" style="margin-bottom: 3vh;">
              <span style="font-weight: 600">Tournament starts in: </span> <countdown [config]="{leftTime: tournament.secondsToStart}"></countdown> <br />
              <span style="font-weight: 600">Tournament starts at:</span> {{ tournament.betsOpenDateForFirstTurn | date:'HH:mm dd-MM-yyyy' }}
            </div>
            <button color="success" mat-raised-button class="button fancy-button" 
              style="padding: 0px 16px; margin: 0px 8px; height: 40px; text-align: center;" 
              [routerLink]="['/oddsee/tournaments', tournament.id]" 
              (click)="couponService.specifySelectedTournament(tournament.id)">
              Open
              </button>
              <button color="success" mat-raised-button class="button fancy-button" 
              [routerLink]="['/oddsee/tournaments', tournament?.id, 'table']" 
              [state]="{tournamentMetadata: tournament}" 
              style="height: 40px; text-align: center;" >
              Table
              </button>
              <button color="success" mat-raised-button class="button fancy-button"
              (click)="openTournamentDetails(tournament)"
              style="height: 40px; text-align: center;" >
                Details
              </button>
          </div>
        </div>
        <ng-template #elseBlock>
          <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
            <div style="margin-bottom: 3vh;">
              <span style="font-weight: 600">Registration closes by:</span> {{ tournament.registrationCloseDateUtc | date:'HH:mm dd-MM' }}
            </div>
            <button color="success" mat-raised-button class="button fancy-button" style="height: 40px; text-align: center" (click)="intentionToJoinTournament(tournament.id)">
              Join! 
            </button> 
            <button color="success" mat-raised-button class="button fancy-button"
            (click)="openTournamentDetails(tournament)"
            style="height: 40px; text-align: center;" >
              Details
            </button>
          </div> 
        </ng-template>
      </mat-card-actions>
    </mat-card>
  </div>
</mat-card>
  
<ng-template #tournamentJoin let-close="close">
  <div class="modal-header">
    <h5 class="modal-title" style="text-align: center;">Do you want to join this tournament?</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <div style="display: flex;">
      <button type="button" class="btn btn-outline-secondary" (click)="close();joint3(modalData?.tournament)" >
        Yes
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        No
      </button>
    </div>        
  </div>
</ng-template>

<ng-template #tournamentDetails let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Tournament details</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span style="font-weight: 700;">Tournament name:</span> {{modalData2?.tournament.name}} <br/>
    Entry Fee: {{modalData2?.tournament.entryFee}}<br/><br/>
    Tournament registration start: {{modalData2?.tournament.registrationOpenDateUtc| date:'HH:mm dd-MM'}} <br/>
    Tournament registration end: {{modalData2?.tournament.registrationCloseDateUtc| date:'HH:mm dd-MM'}}<br/><br/>
    
    Tournament has {{modalData2?.tournament.turns.length}} Turns<br/>
    Tournament first turn start: {{modalData2?.tournament.startDateUtc | date:'HH:mm dd-MM'}} <br/>
    Tournament first turn end: {{modalData2?.tournament.endDateUtc | date:'HH:mm dd-MM'}} <br/>
    
    <br/>

    <span style="font-weight: 700;">Prize pool:</span><br/>
    30% of top players wins prize pool devided equally among them. Prize pool is a sum of all entry fees decreased by 10% (Oddsee Commission). 
    To win a prize participant needs to score at least one point. If less than 30% of participants score any points then whole prize pool is divided equally among all participants that scored a point.
    <br/><br/>
    <span style="font-weight: 700;">Allowed events:</span><br/>
    <div *ngFor="let allowedEvent of allowedEvents">
      {{allowedEvent}}
    </div>

    <span style="font-size: 13px;">Tournament id: {{modalData2?.tournament.id}}</span> 

  </div>    

  <!-- <div class="modal-footer"> 
    Thank you for all your support that makes oddsee a better place for all of us! <span style="font-weight: 700;">Oddsee Founders</span>
  </div> -->
</ng-template>