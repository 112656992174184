import { Component, ViewChild, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AccountService, User } from "../common/account.service";
import { FormControl } from "@angular/forms";
import { AlertService } from "../common/alert.service";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Component({
  selector: "app-users-table",
  templateUrl: "./users-table.component.html",
  styleUrls: ["./users-table.component.css"],
})
export class UsersTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<User>;
  changePasswordForm: FormGroup;
  displayedColumns: string[] = ["userName", "id", "balance"];
  users: User[];
  userName: string = null;
  selected = new FormControl(0);
  userBalance: number;
  depositTokens: any;
  withdrawTokens: any;
  depositAmount: number;
  withdrawAmount: number;
  loading = false;
  submitted = false;
  returnUrl: string;
  newPassword: any;

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiUrlService: ApiUrlService
  ) {
    this.depositTokens = this.formBuilder.group({
      depositAmount: [this.depositAmount, Validators.required],
    });
    this.withdrawTokens = this.formBuilder.group({
      withdrawAmount: [this.withdrawAmount, Validators.required],
    });
    this.changePasswordForm = this.formBuilder.group({
      login: ["", Validators.required],
      newPassword: ["", Validators.required],
    });
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  onPasswordSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.accountService
      .resetPassword(this.f.login.value, this.f.newPassword.value)
      .then(
        () => {
          this.openSnackBarPassoword();
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  openSnackBarPassoword() {
    this._snackBar.open("You have succesfully changed password!", "Close", {
      duration: 2000,
    });
  }

  getUsers(): Promise<User[]> {
    return this.http
      .get<User[]>(environment[this.apiUrlService.env] + "api/users")
      .toPromise();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openRecord(userName: string) {
    this.userName = userName;
    this.getUsersBalance(this.userName);
    this.nextStep();
  }

  nextStep() {
    this.selected.setValue(1);
  }

  getUsersBalance(userName: string) {
    this.accountService
      .getBalance(userName)
      .subscribe((userBalance) => (this.userBalance = userBalance));
  }

  ngOnInit() {
    this.updateTable();
  }

  async updateTable() {
    this.users = await this.getUsers();
    this.updateDataSource();
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openSnackBar() {
    this._snackBar.open("You have succesfully deposit tokens!", "Close", {
      duration: 2000,
    });
  }

  async postDepositTokens(userId: string, amount: number): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] + `api/users/${userId}/deposit`,
        { value: amount }
      )
      .toPromise();
  }

  async postWithdrawTokens(userId: string, amount: number): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] + `api/users/${userId}/withdraw`,
        { value: amount }
      )
      .toPromise();
  }

  async depositSubmit() {
    await this.postDepositTokens(this.userName, this.depositAmount).then(
      (_) => {
        this.depositAmount = null;
        this.getUsersBalance(this.userName);
        this.openSnackBar();
      },
      (err) => {
        console.error(err);
      }
    );
    await this.updateTable();
  }

  async withdrawSubmit() {
    await this.postWithdrawTokens(this.userName, this.withdrawAmount).then(
      (_) => {
        this.withdrawAmount = null;
        this.getUsersBalance(this.userName);
        this.openSnackBar();
      },
      (err) => {
        console.error(err);
      }
    );
    await this.updateTable();
  }
}
