import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Injectable()
export class InPlayService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) {}

  async getInPlayFromAPI(): Promise<InPlayFromAPIDto[]> {
    return await this.http
      .get<InPlayFromAPIDto[]>(
        environment[this.apiUrlService.env] + "api/user-coupons"
      )
      .toPromise();
  }

  deleteCoupon(couponId: string): Promise<void> {
    return this.http
      .delete<void>(
        environment[this.apiUrlService.env] + `api/user-coupons/${couponId}`
      )
      .toPromise();
  }
}

export class InPlayFromAPIDto {
  id: string;
  creationTimestamp: number;
  projectedResolutionTimestamp: number;
  amount: number;
  winningsInTournamentPoints: number;
  combinedOdds: string;
  couponStatus: string;
  couponStatusLabel: string;
  tournamentId: string;
  turnId: string;
  bets: InPlayBetsFromAPIDto;
  isExpanded: boolean = false;
}

export class InPlayBetsFromAPIDto {
  leagueId: string;
  leagueName: string;
  fixtureId: string;
  localTeamName: string;
  visitorTeamName: string;
  startingAtTimestamp: number;
  fixtureStatus: string;
  id: string;
  name: string;
  total: string;
  handicap: string;
  label: string;
  odds: string;
  probabilityLabel: string;
  betStatus: string;
  betStatusLabel: string;
}
