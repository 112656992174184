<app-top-bar-tournament></app-top-bar-tournament>


<mat-tab-group [selectedIndex]="selected.value" mat-align-tabs="start" (selectedIndexChange)="selected.setValue($event)">
  <mat-tab selectedIndex="0" label="User List">

   <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
      <mat-form-field style="width:100%;">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input>
      </mat-form-field>
    </div>

   <div style = "width: 100%;">
       <table mat-table [dataSource]="dataSource" 
       matSort  matSortActive="userName" matSortDirection="desc" matSortDisableClear 
       style = "width: 100%;">
     
         <ng-container matColumnDef="userName" >
           <th mat-header-cell *matHeaderCellDef mat-header style = "width: 20%;"> userName </th>
           <td mat-cell *matCellDef="let row"> {{row.userName}} </td>
         </ng-container>

         <!-- <ng-container matColumnDef="email" >
           <th mat-header-cell *matHeaderCellDef mat-header style = "width: 20%;"> email </th>
           <td mat-cell *matCellDef="let row"> {{row.email}} </td>
         </ng-container> -->
     
         <ng-container matColumnDef="id">
           <th mat-header-cell *matHeaderCellDef mat-header style = "width: 20%;"> userId </th>
           <td mat-cell *matCellDef="let row"> {{row.id}} </td>
         </ng-container>
     
         <ng-container matColumnDef="balance">
           <th mat-header-cell *matHeaderCellDef mat-header style = "width: 20%;"> userBalance </th>
           <td mat-cell *matCellDef="let row"> {{row.balance}} </td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openRecord(row.userName)"></tr>
     
         <tr class="mat-row" *matNoDataRow>
           <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
         </tr>
       </table>
     
       <mat-paginator [pageSize]="25"  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </mat-tab>
  <mat-tab selectedIndex="1" label="User Management" [(disabled)]="!userName">

  <div style="height: 55px; width: 100%; background: #6200EE; color: white;">   
    <div style="text-align: center; display: flex; align-items: center"> 
      <div style="width: 10%; padding-top: 10px;">
      </div>  
      <div style="margin-left: auto; width: 80%; margin-right: auto;">
        <div style="font-size: 20px; margin-top: 12.5px"> 
          {{userName}}'s management panel
        </div>
      </div>
      <div style="width: 10%;">
      </div>
    </div>
  </div>

  <mat-card style="margin-bottom: 2vh; max-width: 400px; margin-left: auto; margin-right: auto;">
    <mat-card-title style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
       User's balance
    </mat-card-title>

    <div style="margin-left: auto; margin-right: auto; text-align: center; align-items: center"> User balance: {{userBalance}}</div>
  </mat-card>
  
  <mat-card style="margin-bottom: 2vh; max-width: 400px; margin-left: auto; margin-right: auto;">
      <mat-card-title style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
          Deposit tokens
      </mat-card-title>
      <form [formGroup]="depositTokens" (ngSubmit)="depositSubmit();" style="text-align: center;">
      
          <div> 
              <!-- <div style="font-weight: bold; margin-right: 50px;">Deposit amount</div>  -->
              <input 
              style="width: 100px"
              matInput
              type="number"
              formControlName="depositAmount"  
              [(ngModel)]="depositAmount"
              >
          </div> 
  
          <button mat-raised-button [disabled]="!depositAmount" style="margin-bottom: 10px; margin-top: 10px;" color="success" type="submit" >
              Deposit Tokens
          </button>
  
      </form>
  </mat-card>
  
  <mat-card style="margin-bottom: 2vh; max-width: 400px; margin-left: auto; margin-right: auto;">
      <mat-card-title style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
          Withdraw tokens
      </mat-card-title>
      <form [formGroup]="withdrawTokens" (ngSubmit)="withdrawSubmit();" style="text-align: center;">
      
          <div> 
              <!-- <div style="font-weight: bold;">Withdraw amount</div>  -->
              <input 
              style="width: 100px"
              matInput
              type="number"
              formControlName="withdrawAmount"  
              [(ngModel)]="withdrawAmount"
              >
          </div> 
  
          <button mat-raised-button [disabled]="!withdrawAmount" style="margin-bottom: 10px; margin-top: 10px;" color="success" type="submit" >
              Withdraw Tokens
          </button>
  
      </form>
  </mat-card>

  <mat-card style="margin-bottom: 2vh; max-width: 400px; margin-left: auto; margin-right: auto;">
    <mat-card-title style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
        Change password
    </mat-card-title>
    <form [formGroup]="changePasswordForm" (ngSubmit)="onPasswordSubmit()">
      <div class="form-group" style="display: none;">
          <label for="login">Login</label>
          <input oninput="this.value = this.value.toUpperCase()" type="text" formControlName="login" class="form-control" 
          [ngClass]="{ 'is-invalid': submitted && f.login.errors }" [(ngModel)]="userName" />
          <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
              <div *ngIf="f.login.errors.required">Old password is required</div>
          </div>
      </div>
      <div class="form-group">
          <label for="newPassword">New Password</label>
          <input type="newPassword" formControlName="newPassword" class="form-control" 
          [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" [(ngModel)]="newPassword"  />
          <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
              <div *ngIf="f.newPassword.errors.required"> New password is required</div>
          </div>
      </div>
      <div class="form-group">
        <button mat-raised-button [disabled]="!userName || !newPassword || loading" style="margin-bottom: 10px; margin-top: 10px;" color="success" type="submit" >
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Change Password
          </button>
      </div>
  </form>
</mat-card>
    
  </mat-tab>
</mat-tab-group>