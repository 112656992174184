import { Component, OnInit } from '@angular/core';
import { BasicService, TournamentMetadata, TournamentHeader } from '../../common/basic.service';
import { SpinnerService } from '../../../infrastructure/network/spinner.service';
import { CouponService, TournamentStatus, TournamentData, TurnData } from './../../betting/common/coupon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { addHours } from 'date-fns';

@Component({
  selector: 'app-tournaments-turns',
  templateUrl: './tournaments-turns.component.html',
  styleUrls: ['./tournaments-turns.component.css']
})
export class TournamentsTurnsComponent implements OnInit {
  tournamentHeader: TournamentHeader;
  tournamentMetadata: TournamentMetadata;
  myStatusForTournament: TournamentStatus[];
  tournament: string;
  tournamentData: TournamentData;
  now: Date;
  timezone = 2;

  constructor(
    private basicService: BasicService,
    public spinnerService: SpinnerService,
    private couponService: CouponService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.spinnerService.show();
    this.tournament = this.couponService.selectedTournament
    if(!this.tournament) {
      this.router.navigateByUrl('',{skipLocationChange:true});
      return;
    }
    this.now = new Date()
    this.myStatusForTournament = await this.couponService.asyncGetMyStatusForTournament(this.tournament)
    this.basicService.getTournamentMetadata(this.tournament).pipe(map(
      tournamentDto => {
        let tournament = new TournamentData()
        tournament.id = tournamentDto.id;
        tournament.startDateUtc = tournamentDto.startDateUtc 
        tournament.endDateUtc = tournamentDto.endDateUtc 
        tournament.tournamentName = tournamentDto.tournamentName 
        tournament.entryFee = tournamentDto.entryFee 
        for(let turn of tournamentDto.turns){
          let turnData = new TurnData()
          turnData.id = turn.id 
          turnData.title = turn.title
          turnData.startDateUtc = turn.startDateUtc
          turnData.endDateUtc = turn.endDateUtc
          turnData.events = turn.events
          turnData.startingStack = turn.startingStack
          turnData.maxBetOdds = turn.maxBetOdds
          turnData.betsOpenDateUtc = addHours(new Date(turn.betsOpenDateUtc), this.timezone);
          turnData.betsCloseDateUtc = addHours(new Date(turn.betsCloseDateUtc), this.timezone);
          if(turnData.betsOpenDateUtc < this.now && this.now < turnData.betsCloseDateUtc){
            turnData.status = "open";
            let dif = this.now.getTime() - turnData.betsCloseDateUtc.getTime();
            let secondsFromNowtoClose = dif/1000
            turnData.secondsToEnd = Math.abs(secondsFromNowtoClose);
          } else if(this.now > turnData.betsCloseDateUtc){
            turnData.status = "closed";
          } else {
            let dif = this.now.getTime() - turnData.betsOpenDateUtc.getTime();
            let secondsFromNowtoOpen = dif/1000
            turnData.secondsToStart = Math.abs(secondsFromNowtoOpen);
          }
          turnData.maxCoupons = turn.maxCoupons;
          turnData.maxBets = turn.maxBets;
          turnData.maxCombinedOdds = turn.maxCombinedOdds;
          turnData.fixtures = turn.fixtures
          if(this.myStatusForTournament.some(tournament => turnData.id == tournament.turnId)){
            turnData.couponsCount = this.myStatusForTournament.find(tournament => turnData.id == tournament.turnId).couponsCount;
            turnData.betsSum = this.myStatusForTournament.find(tournament => turnData.id == tournament.turnId).betsSum;
          }
          tournament.turns.push(turnData)
        }
        return tournament
      }
    )).subscribe(tournament => {
      this.tournamentData = tournament;
      this.spinnerService.hide();
    })
  }
}