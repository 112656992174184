import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ApiUrlService {
  set env(env: "prodUrl" | "devUrl") {
    localStorage.setItem("ENV_KEY", env);
  }

  get env() {
    return localStorage.getItem("ENV_KEY") as any;
  }
}
