<app-top-bar></app-top-bar>
<div class="card">
  <h4 class="card-header">Login</h4>
  <div class="card-body">
    <form [formGroup]="loginForm">
      <div
        class="form-group"
        style="max-width: 400px; margin-left: auto; margin-right: auto"
      >
        <label for="login">Login</label>
        <input
          type="text"
          formControlName="login"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.login.errors }"
        />
        <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
          <div *ngIf="f.login.errors.required">login is required</div>
        </div>
      </div>
      <div
        class="form-group"
        style="max-width: 400px; margin-left: auto; margin-right: auto"
      >
        <label for="password">Password</label>
        <input
          type="password"
          formControlName="password"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
      </div>
      <div
        class="form-group"
        style="max-width: 400px; margin-left: auto; margin-right: auto"
      >
        <button
          [disabled]="loading"
          class="btn btn-primary"
          (click)="!loading && onSubmit('prodUrl')"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Login PROD
        </button>
        <button
          [disabled]="loading"
          class="btn btn-primary"
          style="margin-left: 20px"
          (click)="!loading && onSubmit('devUrl')"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Login DEV
        </button>
        <!-- <button [routerLink]="['/oddsee/changepassword']" class="btn btn-primary">
                    Change Password
                </button> -->
        <!-- <a [routerLink]="['']" class="btn btn-link">Register</a> -->
      </div>
    </form>
  </div>
</div>

<ng-template
  #incorrectLoginAndPassword
  let-close="close"
  style="margin-top: 30vh"
>
  <div class="modal-header">
    <div>Incorrect combination of Login & Password</div>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="close(); clearForm()"
    >
      Ok
    </button>
  </div>
</ng-template>
