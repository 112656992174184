import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import {
  BasicService,
  TournamentHeader,
  TournamentMetadata,
  TurnMetadata,
} from "../../common/basic.service";
import { SpinnerService } from "../../../infrastructure/network/spinner.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { CouponService } from "./../../betting/common/coupon.service";
import { addHours } from "date-fns";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Location } from "@angular/common";
import { environment } from "../../../../environments/environment";
import {
  AccountService,
  LoggedInUser,
} from "../../navigation/common/account.service";
import { ConfigService } from "../../../infrastructure/config.service";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Component({
  selector: "app-tournaments-list",
  templateUrl: "./tournaments-list.component.html",
  styleUrls: ["./tournaments-list.component.css"],
})
export class TournamentsListComponent implements OnInit {
  @ViewChild("tournamentJoin", { static: true })
  tournamentJoin: TemplateRef<any>;
  @ViewChild("tournamentDetails", { static: true })
  tournamentDetails: TemplateRef<any>;
  @Output() mainWalletChange = new EventEmitter();

  mainWallet: number;
  tournaments: TournamentMetadata[];
  myTournaments: string[];
  myTournamentsData: TournamentMetadata[];
  readyToJoinTournaments: TournamentMetadata[];
  timezone = 2;
  loggedInUser: LoggedInUser;
  allowedEvents: string[];
  noTournaments = false;

  constructor(
    private basicService: BasicService,
    public spinnerService: SpinnerService,
    private http: HttpClient,
    private modal: NgbModal,
    private router: Router,
    private configService: ConfigService,
    private couponService: CouponService,
    private _snackBar: MatSnackBar,
    private location: Location,
    private accountService: AccountService,
    private apiUrlService: ApiUrlService
  ) {}

  modalData: {
    tournament: TournamentHeader;
    tournamentStart: Date;
  };

  modalData2: {
    tournament: TournamentHeader;
  };

  localwithdraw(amount: number) {
    this.basicService.withdraw(amount);
    this.mainWalletChange.emit(this.mainWallet);
  }

  intentionToJoinTournament(
    tournament: TournamentHeader,
    tournamentStart: Date
  ): void {
    this.modalData = {
      tournament: tournament,
      tournamentStart: tournamentStart,
    };
    this.modal.open(this.tournamentJoin, { size: "lg" });
  }

  openTournamentDetails(tournament: TournamentHeader): void {
    this.modalData2 = { tournament: tournament };
    this.modal.open(this.tournamentDetails, {
      size: "lg",
      windowClass: "custom-class",
    });
  }

  joinTournament(tournamentId: string): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] +
          `api/tournaments/${tournamentId}/join`,
        null
      )
      .toPromise();
  }

  async joint3(tournamentId: string) {
    await this.joinTournament(tournamentId).then(
      () => {
        this.accountService.getUserBalance(this.loggedInUser.userName);
        this.openSnackBarJoin();
        let t = this.readyToJoinTournaments.find((t1) => t1.id == tournamentId);
        if (
          addHours(new Date(t.betsOpenDateUtcForFirstTurn), this.timezone) <
          new Date()
        ) {
          this.router.navigateByUrl(`/oddsee/tournaments/${tournamentId}`, {
            skipLocationChange: true,
          });
          return;
        }
      },
      (error) => {
        this.openSnackBarNoJoin();
      }
    );
    this.couponService.specifySelectedTournament(tournamentId);
    await this.refresh();
  }

  private async refresh(): Promise<void> {
    let user: LoggedInUser;
    user = this.accountService.getLoggedInUser();
    this.accountService.getUserBalance(user.userName);
    this.spinnerService.show();
    this.mainWallet = this.basicService.getMainWalletValue();
    this.myTournaments = await this.basicService.getMyTournaments();
    this.basicService
      .getActiveTournaments()
      .pipe(
        map((tournamentsDto) => {
          let tournaments: TournamentMetadata[] = [];
          for (let tournament of tournamentsDto) {
            let tournamentData = new TournamentMetadata();
            tournamentData.id = tournament.id;
            tournamentData.name = tournament.name;
            tournamentData.betsCloseDateUtcForFirstTurn = addHours(
              new Date(tournament.betsCloseDateUtcForFirstTurn),
              this.timezone
            );
            tournamentData.betsOpenDateUtcForFirstTurn = addHours(
              new Date(tournament.betsOpenDateUtcForFirstTurn),
              this.timezone
            );
            tournamentData.registrationCloseDateUtc = addHours(
              new Date(tournament.registrationCloseDateUtc),
              this.timezone
            );
            tournamentData.registrationOpenDateUtc = addHours(
              new Date(tournament.registrationOpenDateUtc),
              this.timezone
            );
            tournamentData.entryFee = tournament.entryFee;
            tournamentData.startDateUtc = tournament.startDateUtc;
            tournamentData.rT_ParticipantsCount =
              tournament.rT_ParticipantsCount;
            tournamentData.rT_PricePool = tournament.rT_PricePool;
            tournamentData.endDateUtc = addHours(
              new Date(tournament.turns[0]?.betsCloseDateUtc),
              this.timezone
            );
            if (
              this.myTournaments.some((tournament) => {
                return tournamentData.id == tournament;
              })
            ) {
              tournamentData.joined = true;
            }
            for (let turn of tournament.turns) {
              let turnData = new TurnMetadata();
              turnData.id = turn.id;
              turnData.title = turn.title;
              turnData.betsCloseDateUtc = addHours(
                new Date(turn.betsCloseDateUtc),
                this.timezone
              );
              tournamentData.turns.push(turnData);
            }
            tournaments.push(tournamentData);
          }
          return tournaments;
        })
      )
      .subscribe((tournaments) => {
        this.tournaments = tournaments;
        let myTournamentsData: TournamentMetadata[] = [];
        this.myTournamentsData = myTournamentsData;
        for (let tournament of this.tournaments) {
          let tournamentData = new TournamentMetadata();
          tournamentData.id = tournament.id;
          tournamentData.name = tournament.name;
          tournamentData.entryFee = tournament.entryFee;
          tournamentData.betsOpenDateUtcForFirstTurn =
            tournament.betsCloseDateUtcForFirstTurn;
          tournamentData.betsOpenDateUtcForFirstTurn =
            tournament.betsOpenDateUtcForFirstTurn;
          tournamentData.registrationCloseDateUtc =
            tournament.registrationCloseDateUtc;
          tournamentData.registrationOpenDateUtc =
            tournament.registrationOpenDateUtc;
          tournamentData.startDateUtc = tournament.startDateUtc;
          tournamentData.endDateUtc = tournament.endDateUtc;
          tournamentData.rT_ParticipantsCount = tournament.rT_ParticipantsCount;
          tournamentData.rT_PricePool = tournament.rT_PricePool;
          tournamentData.joined = tournament.joined;
          if (
            addHours(
              new Date(tournamentData.betsOpenDateUtcForFirstTurn),
              this.timezone
            ) < new Date()
          ) {
            tournamentData.started = true;
          } else {
            let t1 = new Date();
            let t2 = addHours(
              new Date(tournamentData.betsOpenDateUtcForFirstTurn),
              this.timezone
            );
            let dif = t1.getTime() - t2.getTime();
            let secondsFromT1toT2 = dif / 1000;
            tournamentData.secondsToStart = Math.abs(secondsFromT1toT2);
          }
          for (let turn of tournament.turns) {
            let turnData = new TurnMetadata();
            turnData.id = turn.id;
            turnData.title = turn.title;
            turnData.betsCloseDateUtc = turn.betsCloseDateUtc;
            tournamentData.turns.push(turnData);
          }
          if (
            this.myTournaments.some((tournament) => {
              return tournament == tournamentData.id;
            })
          ) {
            this.myTournamentsData.push(tournamentData);
          }
        }
      });
    this.basicService
      .getReadyToJoinTournaments()
      .pipe(
        map((tournamentsDto) => {
          let tournaments: TournamentMetadata[] = [];
          for (let tournament of tournamentsDto) {
            let tournamentData = new TournamentMetadata();
            tournamentData.id = tournament.id;
            tournamentData.name = tournament.name;
            tournamentData.registrationOpenDateUtc = addHours(
              new Date(tournament.registrationOpenDateUtc),
              this.timezone
            );
            tournamentData.registrationCloseDateUtc = addHours(
              new Date(tournament.registrationCloseDateUtc),
              this.timezone
            );
            tournamentData.betsOpenMarginInMinutes =
              tournament.betsOpenMarginInMinutes;
            tournamentData.betsCloseMarginInMinutes =
              tournament.betsCloseMarginInMinutes;
            tournamentData.betsCloseDateUtcForFirstTurn =
              tournament.betsCloseDateUtcForFirstTurn;
            tournamentData.betsCloseDateForFirstTurn = addHours(
              new Date(tournament.betsCloseDateUtcForFirstTurn),
              this.timezone
            );
            tournamentData.betsOpenDateUtcForFirstTurn =
              tournament.betsOpenDateUtcForFirstTurn;
            tournamentData.betsOpenDateForFirstTurn = addHours(
              new Date(tournament.betsOpenDateUtcForFirstTurn),
              this.timezone
            );
            tournamentData.entryFee = tournament.entryFee;
            tournamentData.startDateUtc = tournament.startDateUtc;
            tournamentData.rT_ParticipantsCount =
              tournament.rT_ParticipantsCount;
            tournamentData.rT_PricePool = tournament.rT_PricePool;
            if (
              addHours(
                new Date(tournamentData.betsOpenDateUtcForFirstTurn),
                this.timezone
              ) < new Date()
            ) {
              tournamentData.started = true;
            } else {
              let t1 = new Date();
              let t2 = addHours(
                new Date(tournamentData.betsOpenDateUtcForFirstTurn),
                this.timezone
              );
              let dif = t1.getTime() - t2.getTime();
              let secondsFromT1toT2 = dif / 1000;
              tournamentData.secondsToStart = Math.abs(secondsFromT1toT2);
            }
            if (
              this.myTournaments.some((tournament) => {
                return tournamentData.id == tournament;
              })
            ) {
              tournamentData.joined = true;
            }
            for (let turn of tournament.turns) {
              let turnData = new TurnMetadata();
              turnData.id = turn.id;
              turnData.title = turn.title;
              turnData.status = turn.status;
              turnData.betsCloseDateUtc = addHours(
                new Date(turn.betsCloseDateUtc),
                this.timezone
              );
              tournamentData.turns.push(turnData);
            }
            if (
              !tournamentData.joined &&
              tournamentData.turns[0].status != "3"
            ) {
              tournaments.push(tournamentData);
            }
          }
          return tournaments;
        })
      )
      .subscribe((tournaments) => {
        this.readyToJoinTournaments = tournaments;
        this.spinnerService.hide();
      });
  }

  openSnackBarJoin() {
    this._snackBar.open("You have succesfully joined to tournament!", "Close", {
      duration: 2000,
    });
  }

  openSnackBarNoJoin() {
    this._snackBar.open("You can't join this tournament!", "Close", {
      duration: 2000,
    });
  }

  async ngOnInit() {
    this.loggedInUser = this.accountService.getLoggedInUser();
    this.allowedEvents = await this.configService.getAllowedEventsNames();
    await this.refresh();
  }
}
