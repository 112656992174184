
<div style="padding: 12px 20px">
  <div style="display: flex;">
    <div [routerLink]="['/oddsee/tournaments']">
      <div style="text-align: center; align-items: center;">
        <!-- <div>
          <img style="height: 38px" src="https://i.ibb.co/qJFN8DF/oddseeL.png">
        </div> -->
        <div style="font-size: 18px; color: white;">
          Oddsee
        </div>
      </div> 
    </div>
    
    <div *ngIf='!accountService.isAdminLoggedIn'
    style="margin-left: 15px; margin-top: 5px; font-size: 13px; color: white;"> 
      Balance: {{accountService.userBalance}}
    </div>
  </div>
  <div style="margin-left: 15px; margin-top: 12px; text-align: center; align-items: center; position: absolute; right: 12px; top: 5px; margin-right: 12px;">
    <i class="material-icons" [matMenuTriggerFor]="menu" style="font-size: 28px;color: white;">more_vert</i>
  </div>
</div> 
 
  <mat-menu #menu="matMenu">
    <!-- <button mat-menu-item [routerLink]="['']">Tournament List</button> -->
    <div *ngIf='accountService.isAdminLoggedIn'>
      <button mat-menu-item [routerLink]="['/oddsee/calendar']">Calendar</button>
      <button mat-menu-item [routerLink]="['/oddsee/tournament-manager']">Tournament Manager</button>
      <button mat-menu-item [routerLink]="['/oddsee/register']">Register</button>
      <button mat-menu-item [routerLink]="['/oddsee/users-table']">Users Table</button>
      <button mat-menu-item [routerLink]="['/oddsee/cancel-fixture']">Cancel Fixture</button>
      <!-- <button mat-menu-item [routerLink]="['/oddsee/change-other-user-password']">Change User Password</button> -->
    </div>
    <button mat-menu-item [routerLink]="['/oddsee/mytournaments']">My Tournaments</button>
    <button mat-menu-item [routerLink]="['/oddsee/inplay']">My Coupons</button>
    <button mat-menu-item style = "color: red;" (click)="reportABug()">Report a Bug</button>
    <button mat-menu-item [routerLink]="['/oddsee/changepassword']">Change Password</button>
    <button mat-menu-item (click)="accountService.logout()">Log out</button>
  </mat-menu>

<ng-template #bugReport let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Report a Bug</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    If you have found any bug please let us know about it at: <span style="font-weight: 700;">szymon.kot.consulting@gmail.com.</span> <br /><br />
    When you are reporting a bug please provide us anything from the following (whatever is available):<br /> 1) steps to reproduce it,<br /> 2) screenshots,<br /> 3) brief description of bug.<br />
    </div>    
  <div class="modal-footer"> 
    Thank you for all your support that makes oddsee a better place for all of us! - <span style="font-weight: 700;">Oddsee Founders</span>
  </div>
</ng-template>