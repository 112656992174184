import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "../common/account.service";
import { AlertService } from "../common/alert.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @ViewChild("incorrectLoginAndPassword", { static: true })
  incorrectLoginAndPassword: TemplateRef<any>;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private modal: NgbModal,
    private apiUrlService: ApiUrlService
  ) {
    if (this.accountService.isUserLoggedIn()) {
      this.router.navigate(["/oddsee/tournaments"]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.returnUrl =
      this.route.snapshot.queryParams[""] || "oddsee/tournaments";
  }
  get f() {
    return this.loginForm.controls;
  }

  clearForm() {
    this.loginForm.reset();
  }

  onSubmit(env: "prodUrl" | "devUrl") {
    this.apiUrlService.env = env;
    this.submitted = true;
    this.alertService.clear();
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.accountService.login(this.f.login.value, this.f.password.value).then(
      () => {
        this.router.navigate([this.returnUrl]);
        this.accountService.getUserBalance(this.f.login.value);
      },
      (error) => {
        this.modal.open(this.incorrectLoginAndPassword, { size: "lg" });
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
}
