<div style="width: 67vw; max-width: 680px;">
<mat-sidenav-container style="background-color: white;">
  
  <div style="height: max-content; margin-top: 25vh; margin-left: 40vw; padding-bottom: 100vh;" *ngIf="spinnerService.visibility | async">
    <mat-progress-spinner [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>

  <mat-sidenav #sidenav mode="side" style="width:35vw; max-height: 70vh; overflow: scroll;">
    <!-- <div *ngIf="showMenu" >
      <mat-icon style="font-size: 32px; height: 3vh; margin-left: 85%" (click)="closeMenu();">
        chevron_left
      </mat-icon>
    </div> -->
    <div *ngFor="let turn of turnCoupons"></div>
      <mat-card style="margin-bottom: 2vh; margin-top: 2vh;">
        <mat-card-content>
          <mat-accordion> 
            <mat-expansion-panel *ngFor="let coupon of turnCoupons">
              <mat-expansion-panel-header>
                <mat-panel-title style=" width: 100%">
                  <div style="width: 100%;">
                    <div class="fixtureLabel" style="width: 100%; text-align: center; display: flex; align-items: center">
                      <div>
                        <div>
                          Value: 
                          <span style="font-weight: bold">
                            {{ coupon.value }}
                          </span>
                        </div>
                      </div>      
                      <div style="padding-right: 3vw; display: flex; margin-left: auto; margin-right: auto;">
                          Possible Winnings: 
                          <span style="font-weight: bold; margin-left: 0.5 vw;">
                            {{ coupon.winningsInTournamentPoints }}
                          </span>
                      </div>
                    </div>   
                      
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-selection-list [multiple]="false"> 
                <mat-list-option>
                    
                  <div class="fixtureTime" style="margin-left: auto; margin-right: auto;">
                    <div style="text-align: center;display: flex;">
                      <div style="width:5vw; margin-right:5vw;">
                        <mat-icon class="trashcan" (click)="intentionToDeleteCoupon(coupon.tournamentId, coupon.turnId, coupon.id)" color="warn">delete</mat-icon>
                      </div>
                      <div style="display: flex;">
                        <div style="padding-top: 0.35vh; font-size: 13px"> 
                          Combined Odds: 
                          <span style="font-weight: bold">
                            {{ coupon.combinedOdds }}  
                          </span> 
                        </div>
                      </div>
                      <div style="width:5vw;margin-left:5vw;">
                      </div>
                    </div>
                  </div> 
                </mat-list-option>
                <mat-list-option *ngFor="let bet of coupon.bets" style="height: 100px;">
                  <div style="padding-left: 16px; display: flex; flex-direction: row; text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 2vh; margin-top: 2vh;">             
                    <div style="margin-left: 10px; padding-top: 6px;">
                      <div class="betDesc" style="font-size:15px; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">  
                        <span style="font-weight: bold; color: #3700B3">
                          {{ bet.localTeamName }} - {{ bet.visitorTeamName }}
                        </span> 
                          {{ bet.leagueName }}  
                      </div>
                      <div class="betLabel" style="font-size:13px; text-align: center; align-items: center; margin-left: auto; margin-right: auto;"> 
                        <span style="color:black">
                          Odds: 
                          <span style="font-weight: bold">
                            {{ bet.odds }}  
                          </span> 
                          
                        </span> - 
                        {{ bet.name }} 
                        - Choice: 
                        <span style="font-weight: bold">
                          {{ bet.label }}     
                        </span> 
                            
                        <br  />
                        <div class="betLabel" style="font-size:13px; color:black; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                          {{ bet.startingAtTimestampInMs | date:'HH:mm dd-MM-yyyy' }}
                        </div>
                      </div>    
                    </div>  
                    <div style="margin-right: 10px; float: right;">
                    </div>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content> 
      </mat-card>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card>
      <h3 class="default" style="text-align: center;">Coupon</h3>

      
      <mat-card-content>
        <div [className]="couponSelectedOdds.length == 0 ? 'hidden' : ''">
          <mat-card >
            <mat-card-content>
              <mat-list style="padding-left: 0px;" *ngFor="let selectedOdd of couponSelectedOdds">
                <mat-list-item style="margin-top: 3vh;">  
                  <div style="display: flex; flex-direction: row;"> 
                    <div style="padding-top: 12px; margin-right: 2 vw;">
                      <mat-icon class="trashcan" (click)="removeOddFromCoupon(selectedOdd.tournamentId, selectedOdd.turnId, selectedOdd.label, selectedOdd.betName, selectedOdd.fixtureId)" color="warn">
                        delete
                      </mat-icon>
                    </div> 
                    <div style="margin-left: 10px;">
                      <div class="betLabel" style="font-size: 13px;">
                        {{ selectedOdd.betName }}  
                        <div >
                          Choice: 
                          <span style="font-weight: bold">
                            {{ selectedOdd.label }}     
                            {{ selectedOdd.total }}
                            {{ selectedOdd.handicap }}
                        </span>
                        </div>
                      </div>  
                      <div class="betDesc" *ngIf="turn?.maxBetOdds>=selectedOdd.odds ; else elseBlock1">
                        Odds: 
                          <span style="font-weight: bold">
                            {{ selectedOdd.odds }} 
                          </span>    
                          <span style="font-weight: bold; color: #3700B3"> 
                            {{ selectedOdd.leagueName }}
                          </span>
                      </div>
                      <ng-template  #elseBlock1>
                        <div class="betDesc">  
                          Odds: 
                          <span style="font-weight: bold">
                            {{ turn?.maxBetOdds }} 
                            <span style="margin-left:0.1vw; color: red; text-decoration-line: line-through;">
                              <span style="font-weight: 600;">{{ selectedOdd.odds }}</span>
                            </span> 
                          </span>    
                          <span style="font-weight: bold; color: #3700B3"> 
                            {{ selectedOdd.leagueName }}
                          </span>
                        </div>
                      </ng-template>
                      <div class="betDesc" style="margin-bottom: 3vh;"> 
                        {{ selectedOdd.localTeamName }} - {{ selectedOdd.visitorTeamName }} 
                      </div>
                    </div>        
                  </div> 
                </mat-list-item>
                <mat-divider style="margin-top: 1vh;" [inset]="true" *ngIf="!last" ></mat-divider>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
        <div [className]="couponSelectedOdds.length == 0 ? '' : 'hidden'">
          <mat-card style="text-align: center;">
            There are no bets selected!      
          </mat-card>
        </div>      
        <div class="betLabel" style="width: 100%; text-align: center; align-items: center">
          <div style="margin-top: 3vh;" *ngIf="turn?.maxCombinedOdds>=couponService.combinedOdds; else elseBlock">
            <div>
                <div> Combined Odds: <span style="font-weight: 600;">{{ couponService.combinedOdds | number: '1.2-2' }}</span></div>
            </div>
            <div> Possible winnings: <span style="font-weight: 600;">{{ couponService.combinedOdds * value | number: '1.2-2'}}</span></div>
          </div>
          <ng-template #elseBlock>
            <div>
                Combined Odds: <span style="font-weight: 600;">{{ turn?.maxCombinedOdds }}</span>
              <span style="margin-left:0.5vw; color: red; text-decoration-line: line-through;">
                <span style="font-weight: 600;">{{ couponService.combinedOdds | number: '1.2-2' }}</span>
              </span> 
            </div>
            <div> Possible winnings: <span style="font-weight: 600;">{{ turn?.maxCombinedOdds * value | number: '1.2-2'}}</span></div>
          </ng-template>
        </div>
        <form [formGroup]="betSubmit" (ngSubmit)="onSubmit()" style="text-align: center; margin-left: auto; margin-right: auto;">

          <div [className]="couponSelectedOdds.length == 0 ? 'hidden' : ''">
            <div style="min-height: fit-content; min-width: fit-content;">
              <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%;">
                <mat-label>Amount</mat-label>
                <input matInput id="Amount" type="number" class="example-right-align" formControlName="Amount" [(ngModel)]="value">
              </mat-form-field>
              <!-- <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%;">
                <mat-label>Amount</mat-label>
                <input matInput id="Amount" type="number" class="example-right-align" formControlName="Amount" [(ngModel)]="value">
                <span matPrefix>Oddsee coins &nbsp;</span>
              </mat-form-field> -->
            </div>
          </div>
        </form>
        <div [className]="couponSelectedOdds.length == 0 ? 'hidden' : ''">
          <div style="text-align: center;">
            <mat-slider
              color="primary"
              [max]="max"
              [min]="min"
              [step]="step"
              [thumbLabel]= true
              [(ngModel)]="value"
              style="margin-top: 3vh;">
            </mat-slider>
          </div>  
        </div>
        <div style="width: 100%; align-items: center; text-align: center; margin-left: auto; margin-right: auto;">
          <button mat-raised-button [disabled]="!couponSelectedOdds.length || turnCoupons?.length >= turn?.maxCoupons || couponService.turnWallet == 0" style="margin-bottom: 10px; margin-top: 10px;" color="success" (click)="onSubmit()" >Submit my Coupon!</button>
        </div>
        <div style="width: 100%; align-items: center; text-align: center; margin-left: auto; margin-right: auto;">
          <div *ngIf="turnCoupons?.length >= turn?.maxCoupons" style="font-weight: 600; color: #F44336;">You reached max coupon count!</div>
          <div *ngIf="couponService.turnWallet == 0" style="font-weight: 600; color: #F44336;">Whose stack has been submitted!</div>
        </div>
        <div style="width: 100%; text-align: center; align-items: center">
          <div style="width: 100%; font-size: 10px; padding-top: 5px;color: #3700B3"> 
            Current Stack:<span style="font-weight: bold; "> {{ couponService.turnWallet }}</span>
            Remaining Stack:<span style="font-weight: bold; "> {{ couponService.turnWallet - value }}</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
  <ng-template #couponDelete let-close="close" style="z-index: 3000;">
    <div class="modal-header">
      <h5 class="modal-title" style="text-align: center;">Do you want to delete this coupon?</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <div style="display: flex;">
        <button type="button" class="btn btn-outline-secondary" (click)="close();deleteCoupon(modalData?.couponId)">
          Yes
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="close()">
          No
        </button>
      </div>        
    </div>
  </ng-template>
</div>