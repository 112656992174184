import { Component, OnInit } from '@angular/core';
import { TournamentService } from '../../tournamentsSetup/tournament.service';
import { ConfigService } from '../../../infrastructure/config.service';
import { Router } from '@angular/router';
import { BasicService, TurnMetadata, TournamentMetadata } from '../../common/basic.service';
import { AccountService } from '../../navigation/common/account.service';
import { InPlayFromAPIDto } from '../../betting/in-play/in-play.service';
import { SpinnerService } from '../../../infrastructure/network/spinner.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-coupons',
  templateUrl: './user-coupons.component.html',
  styleUrls: ['./user-coupons.component.css']
})
export class UserCouponsComponent implements OnInit {
  tournamentMetadata:TournamentMetadata;
  userName:string;
  userCoupons:InPlayFromAPIDto[] = [];
  turns:TurnMetadata[];

  constructor(
    private tournamentService: TournamentService,
    private configService: ConfigService,
    private router: Router,
    private basicService: BasicService,
    private accountService: AccountService,
    public spinnerService: SpinnerService,
  ) { }

  setCouponsStatusLabels(){
    for(let coupon of this.userCoupons){
      const c = coupon;
      if(c.couponStatus == "0")
      {
        c.couponStatusLabel = "Awaiting"
      }
      else if(c.couponStatus == "1")
      {
        c.couponStatusLabel = "Won"
      }
      else if(c.couponStatus == "2")
      {
        c.couponStatusLabel = "Lost"
      }
      else if(c.couponStatus == "3")
      {
        c.couponStatusLabel = "Cancelled"
      }
    }
  }

  async ngOnInit() {
    this.tournamentMetadata = history.state.tournamentMetadata;
    this.userName = history.state.userName;
    if(!this.tournamentMetadata || !this.userName) {
      this.router.navigateByUrl('',{skipLocationChange:true});
      return;
    }
    this.userCoupons = await this.basicService.getUserCoupons(this.tournamentMetadata.id, this.userName);
    this.setCouponsStatusLabels();
    this.basicService.getTournamentMetadata(this.tournamentMetadata.id).pipe(map(
      tournamentDto => {
        let newTurns:TurnMetadata[] = [];
        for(let turn of tournamentDto.turns){
          let turnData = new TurnMetadata()
          turnData.id = turn.id 
          turnData.title = turn.title
          for(let coupon of this.userCoupons){
            if(coupon.turnId == turnData.id){
              if(coupon.couponStatusLabel == "Won"){
                turnData.won += coupon.winningsInTournamentPoints;
              }
              turnData.coupons.push(coupon)
            }
          }
          newTurns.push(turnData)
        }
        return newTurns
      }
    )).subscribe(newTurns => {
      this.turns = newTurns
    })
  }

}