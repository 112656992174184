import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../common/calendar.service';
import { TurnMetadata }from "../../../modules/common/basic.service"
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-turn-setup',
  templateUrl: './turn-setup.component.html',
  styleUrls: ['./turn-setup.component.scss']
})

export class TurnSetupComponent implements OnInit {
  turnData:TurnMetadata;

  constructor(
    public calendarService: CalendarService,
    public dialogRef: MatDialogRef<TurnSetupComponent>,

  ) { }


  postSettedUpTurn(turnData:TurnMetadata){
    this.calendarService.addSettedUpTurn(turnData)
  }

  ngOnInit() {
    this.turnData = this.calendarService.getSelectedTurnData();
  }

  uncheckAllEvents() {
      for(let j=0; j <= this.turnData.events.length; j++){
        this.turnData.events[j].isSelected = false;
      }   
  }

  sortBy(prop: string) {
    return this.turnData.fixtures.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
