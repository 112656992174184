<app-top-bar-tournament></app-top-bar-tournament>

<!-- <div style="height: 55px; width: 100%; background: #6200EE; color: white;">   
  <div style="text-align: center; display: flex; align-items: center"> 
      <div style="width: 10%; padding-top: 10px;">
      <mat-icon style="font-size: 32px;  -webkit-text-fill-color:white;" 
      [routerLink]="['/oddsee/tournaments/',tournamentMetadata.id]">
        chevron_left
      </mat-icon>
      </div>  
      <div style="margin-left: auto; margin-right: auto;">
      <div style="font-size: 20px; padding-top: 5px;"> 
        Tournament Table
      </div>
      </div>
  </div>
  </div> -->

<div style="height: 55px; width: 100%; background: #6200EE; color: white;">   
  
  <div style="text-align: center; display: flex; align-items: center"> 
    <div style="width: 10vw; padding-top: 10px;">
      <mat-icon style="font-size: 32px;  -webkit-text-fill-color:white;" 
      [routerLink]="['/oddsee/tournaments/']">
        chevron_left
      </mat-icon>
    </div>  

    <div style=" width: 80vw; margin-left: auto; margin-top:7px;  margin-right: auto;">
      <div style="font-size: 20px; padding-top: 5px;"> 
        Tournament Table
      </div>
    </div>
    <div style="width: 10vw;">

    </div>  


  </div>
  
</div>

<div style="height: max-content; margin-top: 25vh; margin-left: 40vw; padding-bottom: 100vh;" *ngIf="spinnerService.visibility | async">
  <mat-progress-spinner [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>
</div>

<div style="margin-top: 1vh; width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
  <mat-form-field style="height: 2vh;">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>
</div>

<!-- todo sk - (matSortChange)="sortData($event)" nie istnieje -->
<div style = "width: 100%;">
  <table mat-table [dataSource]="dataSource" 
  matSort  matSortActive="winningsInTournamentPoints" matSortDirection="desc" matSortDisableClear 
  style = "width: 100%;">

    <ng-container matColumnDef="place">
      <th mat-header-cell *matHeaderCellDef mat-header style = "width: 25vw; font-size: 15px;"> Place </th>
      
      <td mat-cell *matCellDef="let row"> 
        {{row.place}}
        <!-- <div *ngIf="row.place != row.potentialPlace; else Else1" style="font-size: 13px;"> 
          {{row.place}} ({{row.potentialPlace}}) 
        </div>
        <ng-template #Else1>
          <div style="font-size: 13px;"> 
            {{row.place}}
          </div>
        </ng-template> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="userName" >
      <th mat-header-cell *matHeaderCellDef mat-header style = "width: 25vw; font-size: 15px;"> Login </th>
      <td mat-cell *matCellDef="let row" style = "font-size: 13px;"> <span [className]="row.userName.toUpperCase() == user.userName.toUpperCase()  ? 'loggedInUser' : ''">{{row.userName}}</span> </td>
    </ng-container>

    

    <ng-container matColumnDef="winningsInTournamentPoints" >
      <th mat-header-cell *matHeaderCellDef mat-header style = "width: 25vw; font-size: 15px;"> Points </th>
      <td mat-cell *matCellDef="let row" style = "font-size: 13px;"> {{row.winningsInTournamentPoints | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="potentialWinningsInTournamentPoints" >
        <th mat-header-cell *matHeaderCellDef mat-header style = "width: 25vw; font-size: 15px;" > Pot. points (C) </th>
        <td mat-cell *matCellDef="let row"> 
          <div style="font-size: 13px;"> 
            {{row.potentialWinningsInTournamentPoints | number: '1.2-2'}} ({{row.awaitingCouponsCount}}) 
          </div>
        </td>
    </ng-container>

    <!-- <ng-container matColumnDef="awaitingCouponsCount">
      <th mat-header-cell *matHeaderCellDef mat-header style = "width: 20%;"> inplay Coupons </th>
      <td mat-cell *matCellDef="let row"> {{row.awaitingCouponsCount}} </td>
    </ng-container> -->

    <ng-container matColumnDef="button">
      <th mat-header-cell *matHeaderCellDef mat-header style = "width: 5vw"></th>
      <td mat-cell *matCellDef="let row"> 
        <!-- <div *ngIf="row.userName.toUpperCase() != user.userName.toUpperCase()">
          <button type="button" class="btn btn-outline-dark"style="height: 40px; text-align: center; float: right;" 
          [routerLink]="['/oddsee/tournaments', tournamentMetadata.id, 'user', row.userName]"
          [state]="{tournamentMetadata: tournamentMetadata, userName : row.userName}">
            Bets
          </button> 
        </div> -->
        <!-- <button type="button" class="btn btn-outline-dark"style="height: 5vh; text-align: center; float: right;" 
          [routerLink]="['/oddsee/tournaments', tournamentMetadata.id, 'user', row.userName]"
          [state]="{tournamentMetadata: tournamentMetadata, userName : row.userName}">
            Bets
          </button>  -->

          <mat-icon style="font-size: 3vh;  -webkit-text-fill-color:black ; padding-top: 1vh;" 
          [routerLink]="['/oddsee/tournaments', tournamentMetadata.id, 'user', row.userName]"
          [state]="{tournamentMetadata: tournamentMetadata, userName : row.userName}">
            chevron_right
          </mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSize]="25"  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>