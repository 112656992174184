import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TournamentService {

  constructor(
    private http: HttpClient
  ) {}
  

  getTable(): Observable<TableDto[]> {
    return this.http.get<TableDto[]>('/assets/Table.json'); //temporary function
  }

}

export class TableDto {
  userLogin: string;
  userId: string;
  points: number;
}
