import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { InPlayService, InPlayFromAPIDto } from './in-play.service';
import { ConfigService } from '../../../infrastructure/config.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../infrastructure/network/spinner.service';
import { AccountService, LoggedInUser } from '../../navigation/common/account.service';
import { CouponService, SelectedOdd, TurnData } from '../common/coupon.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CouponComponent } from '../../../modules/betting/coupon/coupon.component';
import { BasicService, TournamentHeader, TournamentMetadata, TurnMetadata } from '../../common/basic.service';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import { addHours } from 'date-fns';

@Component({
  selector: 'app-in-play',
  templateUrl: './in-play.component.html',
  styleUrls: ['./in-play.component.css']
})
export class InPlayComponent implements OnInit {
  @ViewChild('couponDelete', { static: true }) couponDelete: TemplateRef<any>; 

  coupons: InPlayFromAPIDto[];
  myTournaments: string[];
  myActiveTournamentsData: TournamentHeader[];
  myClosedTournamentsData: TournamentHeader[];
  readyToJoinTournaments: TournamentMetadata[];
  tournaments: TournamentHeader[];
  now: Date;
  timezone = 2;

  constructor(
    private inPlayService: InPlayService,
    private configService: ConfigService,
    private router: Router,
    public spinnerService: SpinnerService,
    public couponService: CouponService,
    public basicService: BasicService,
    public dialog: MatDialog,
    private modal: NgbModal,
    private _snackBar: MatSnackBar,
    private accountService: AccountService,
  ) { }

  modalData: {
    tournamentId: string, 
    turnId: string,
    couponId: string;
  }; 

  openDialog(): void {
    const dialogRef = this.dialog.open(CouponComponent);
  }


  async deleteCoupon(tournamentId: string, turnId: string, couponId: string){
    await this.inPlayService.deleteCoupon(couponId);
    this.deleteCouponFromMyTournamentsData(tournamentId, turnId, couponId);
    this.openSnackBar();
  }
  

  deleteCouponFromMyTournamentsData(tournamentId: string, turnId: string, couponId: string){
    const tournament = this.myActiveTournamentsData
      .find(tournament => tournament.id == tournamentId)

    const turn = tournament.turns
      .find(turn => turn.id == turnId)

    const coupon = turn.coupons
      .find(coupon => coupon.id == couponId)
   
    const index: number = turn.coupons.indexOf(coupon)
    if (index !== -1) {
      turn.coupons.splice(index, 1);
    }  

    if(turn.coupons.length == 0){
      const index: number = tournament.turns.indexOf(turn)
      if (index !== -1) {
        tournament.turns.splice(index, 1);
      } 
    }

    if(tournament.turns.length == 0){
      const index: number = this.myActiveTournamentsData.indexOf(tournament)
      if (index !== -1) {
        this.myActiveTournamentsData.splice(index, 1);
      } 
    }
  }

  openSnackBar() { 
    this._snackBar.open("Your Coupon was successfully deleted!", "Close", {
      duration: 3000,
    });
  }

  intentionToDeleteCoupon(tournamentId: string, turnId: string, couponId: string): void {
    this.modalData = { tournamentId: tournamentId, turnId: turnId, couponId: couponId };
    this.modal.open(this.couponDelete, { size: 'lg' });
  }

  setCouponsStatusLabels(){
    for(let coupon of this.coupons){
      const c = coupon;
      if(c.couponStatus == "0")
      {
        c.couponStatusLabel = "Awaiting"
      }
      else if(c.couponStatus == "1")
      {
        c.couponStatusLabel = "Won"
      }
      else if(c.couponStatus == "2")
      {
        c.couponStatusLabel = "Lost"
      }
      else if(c.couponStatus == "3")
      {
        c.couponStatusLabel = "Cancelled"
      }
    }
  }

  async ngOnInit() {
    this.spinnerService.show();
    let user:LoggedInUser;
    user = this.accountService.getLoggedInUser();
    this.accountService.getUserBalance(user.userName);
    this.now = new Date()
    this.coupons =  await this.inPlayService.getInPlayFromAPI();
    this.setCouponsStatusLabels()
    this.myTournaments = await this.basicService.getMyTournaments();
    this.basicService.getActiveTournaments().pipe(map(
      tournamentsDto =>{
      let tournaments: TournamentHeader[] = [];
      for (let tournament of tournamentsDto){
        let tournamentData = new TournamentHeader();
        tournamentData.id = tournament.id;
        tournamentData.name = tournament.name;
        tournamentData.betsCloseDateUtcForFirstTurn = tournament.betsCloseDateUtcForFirstTurn;
        tournamentData.betsOpenDateUtcForFirstTurn = tournament.betsOpenDateUtcForFirstTurn;
        tournamentData.registrationCloseDateUtc = tournament.registrationCloseDateUtc;
        tournamentData.registrationOpenDateUtc = tournament.registrationOpenDateUtc;
        tournamentData.entryFee = tournament.entryFee;
        tournamentData.startDateUtc = tournament.startDateUtc;
        tournamentData.endDateUtc = tournament.endDateUtc
        if(this.myTournaments.some(tournament => {
          return tournamentData.id == tournament
        })){
          tournamentData.joined = true;
        }
        for(let turn of tournament.turns){
          let turnData = new TurnMetadata();
          turnData.id = turn.id;
          turnData.title = turn.title;
          turnData.betsCloseDateUtc = addHours(new Date(turn.betsCloseDateUtc), this.timezone);
          for(let coupon of this.coupons){
            if(coupon.turnId == turnData.id){
              turnData.coupons.push(coupon);
            }
          }
          tournamentData.turns.push(turnData);
        }
        tournaments.push(tournamentData)
      }
      return tournaments
    }
    )).subscribe(tournaments => {
      this.tournaments = tournaments;
      let myTournamentsData: TournamentHeader[] = [];
      this.myActiveTournamentsData = myTournamentsData;
        for (let tournament of this.tournaments){
          let tournamentData = new TournamentHeader();
          tournamentData.id = tournament.id;
          tournamentData.name = tournament.name;
          tournamentData.entryFee = tournament.entryFee;
          tournamentData.betsCloseDateUtcForFirstTurn = tournament.betsCloseDateUtcForFirstTurn;
          tournamentData.betsOpenDateUtcForFirstTurn = tournament.betsOpenDateUtcForFirstTurn;
          tournamentData.registrationCloseDateUtc = tournament.registrationCloseDateUtc;
          tournamentData.registrationOpenDateUtc = tournament.registrationOpenDateUtc;
          tournamentData.startDateUtc = tournament.startDateUtc;
          tournamentData.endDateUtc = tournament.endDateUtc;
          tournamentData.joined = tournament.joined;
          for(let turn of tournament.turns){
            let turnData = new TurnMetadata();
            turnData.id = turn.id;
            turnData.title = turn.title;
            turnData.betsCloseDateUtc = turn.betsCloseDateUtc;
            for(let coupon of this.coupons){
              if(coupon.turnId == turnData.id){
                if(coupon.couponStatusLabel == "Won"){
                  turnData.won += coupon.winningsInTournamentPoints;
                }
                turnData.coupons.push(coupon);
              }
            }
            tournamentData.turns.push(turnData);
          }        
          if(this.myTournaments.some(tournament => {
            return tournament == tournamentData.id
          })){
            this.myActiveTournamentsData.push(tournamentData)
          }
        }
    });  
    this.basicService.getClosedTournaments().pipe(map(
      tournamentsDto =>{
      let tournaments: TournamentHeader[] = [];
      for (let tournament of tournamentsDto){
        let tournamentData = new TournamentHeader();
        tournamentData.id = tournament.id;
        tournamentData.name = tournament.name;
        tournamentData.betsCloseDateUtcForFirstTurn = tournament.betsCloseDateUtcForFirstTurn;
        tournamentData.betsOpenDateUtcForFirstTurn = tournament.betsOpenDateUtcForFirstTurn;
        tournamentData.registrationCloseDateUtc = tournament.registrationCloseDateUtc;
        tournamentData.registrationOpenDateUtc = tournament.registrationOpenDateUtc;
        tournamentData.entryFee = tournament.entryFee;
        tournamentData.startDateUtc = tournament.startDateUtc;
        tournamentData.endDateUtc = tournament.endDateUtc
        if(this.myTournaments.some(tournament => {
          return tournamentData.id == tournament
        })){
          tournamentData.joined = true;
        }
        for(let turn of tournament.turns){
          let turnData = new TurnMetadata();
          turnData.id = turn.id;
          turnData.title = turn.title;
          for(let coupon of this.coupons){
            if(coupon.turnId == turnData.id){
              turnData.coupons.push(coupon);
            }
          }
          tournamentData.turns.push(turnData);
        }
        tournaments.push(tournamentData)
      }
      return tournaments
    }
    )).subscribe(tournaments => {
      this.tournaments = tournaments;
      let myTournamentsData: TournamentHeader[] = [];
      this.myClosedTournamentsData = myTournamentsData;
        for (let tournament of this.tournaments){
          let tournamentData = new TournamentHeader();
          tournamentData.id = tournament.id;
          tournamentData.name = tournament.name;
          tournamentData.entryFee = tournament.entryFee;
          tournamentData.betsCloseDateUtcForFirstTurn = tournament.betsCloseDateUtcForFirstTurn;
          tournamentData.betsOpenDateUtcForFirstTurn = tournament.betsOpenDateUtcForFirstTurn;
          tournamentData.registrationCloseDateUtc = tournament.registrationCloseDateUtc;
          tournamentData.registrationOpenDateUtc = tournament.registrationOpenDateUtc;
          tournamentData.startDateUtc = tournament.startDateUtc;
          tournamentData.endDateUtc = tournament.endDateUtc;
          tournamentData.joined = tournament.joined;
          for(let turn of tournament.turns){
            let turnData = new TurnMetadata();
            turnData.id = turn.id;
            turnData.title = turn.title;
            for(let coupon of this.coupons){
              if(coupon.turnId == turnData.id){
                if(coupon.couponStatusLabel == "Won"){
                  turnData.won += coupon.winningsInTournamentPoints;
                }
                turnData.coupons.push(coupon);
              }
            }
            tournamentData.turns.push(turnData);
          }
          
          if(this.myTournaments.some(tournament => {
            return tournament == tournamentData.id
          })){
            this.myClosedTournamentsData.push(tournamentData)
          }
        }
    }); 
    this.spinnerService.hide();
  }
}