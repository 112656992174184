import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../common/account.service';
import { AlertService } from '../common/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
@ViewChild('incorrectLoginAndPassword', { static: true }) incorrectLoginAndPassword: TemplateRef<any>; 

  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private alertService: AlertService,
      private modal: NgbModal,
  ) {
      // redirect to home if already logged in
      // if (this.accountService.isUserLoggedIn()) {
      //     this.router.navigate(['oddsee/tournaments']);
      // }
  }

  ngOnInit() {
      this.changePasswordForm = this.formBuilder.group({
        login: ['', Validators.required],
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        // repeatedNewPassword: ['', Validators.required],
      });

      // get return url from route parameters or default to '/'
      // this.returnUrl = this.route.snapshot.queryParams[''] || 'oddsee/tournaments';
  }

  // convenience getter for easy access to form fields
  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.changePasswordForm.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.resetMyPassword(this.f.login.value, this.f.oldPassword.value, this.f.newPassword.value)
          .then(
              () => {
                    this.router.navigateByUrl('',{skipLocationChange:true});
              },
              error => {
                this.modal.open(this.incorrectLoginAndPassword, { size: 'lg' });
                this.alertService.error(error);
                this.loading = false;
              });    
  }
}
