import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import {
  InPlayService,
  InPlayFromAPIDto,
} from "../betting/in-play/in-play.service";
import { environment } from "../../../environments/environment";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Injectable()
export class BasicService {
  mainWallet: number = 500;

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiUrlService: ApiUrlService
  ) {}

  deposit(amount: number) {
    this.mainWallet += amount;
  }

  withdraw(amount: number) {
    this.mainWallet -= amount;
  }

  getMainWalletValue() {
    return this.mainWallet;
  }

  async getMyTournaments(): Promise<string[]> {
    return await this.http
      .get<string[]>(environment[this.apiUrlService.env] + "api/tournaments/my")
      .toPromise();
  }

  getLeaguesFromAPI(): Observable<LeagueDto[]> {
    return this.http.get<LeagueDto[]>(
      environment[this.apiUrlService.env] + "api/leagues"
    );
  }

  getActiveTournaments(): Observable<TournamentMetadata[]> {
    return this.http.get<TournamentMetadata[]>(
      environment[this.apiUrlService.env] + "api/tournaments/get-active"
    );
  }

  getClosedTournaments(): Observable<TournamentHeader[]> {
    return this.http.get<TournamentHeader[]>(
      environment[this.apiUrlService.env] + "api/tournaments/get-historical"
    );
  }

  getReadyToJoinTournaments(): Observable<TournamentMetadata[]> {
    return this.http.get<TournamentMetadata[]>(
      environment[this.apiUrlService.env] + "api/tournaments/get-ready-to-join"
    );
  }

  getTournamentMetadata(tournamentId: string): Observable<TournamentMetadata> {
    return this.http.get<TournamentMetadata>(
      environment[this.apiUrlService.env] +
        "api/tournaments/get/" +
        tournamentId
    );
  }

  async getTournamentTable(tournamentId: string): Promise<TournamentInfo> {
    return await this.http
      .get<TournamentInfo>(
        environment[this.apiUrlService.env] +
          `api/tournaments/${tournamentId}/table`
      )
      .toPromise();
  }

  async getUserCoupons(
    tournamentId: string,
    userId: string
  ): Promise<InPlayFromAPIDto[]> {
    return await this.http
      .get<InPlayFromAPIDto[]>(
        environment[this.apiUrlService.env] +
          `api/user-coupons/tournament/${tournamentId}/user/${userId}`
      )
      .toPromise();
  }

  getLeagueFromAPI(leagueId: string) {
    return this.getLeaguesFromAPI().pipe(
      map((leagues) => {
        for (let league of leagues) {
          if (league.id === leagueId) {
            return league;
          }
        }
        return null;
      })
    );
  } //todo trzeba usunąć

  getFixturesFromAPI(x: string): Observable<FixtureFromAPIDto[]> {
    return this.http.get<FixtureFromAPIDto[]>(
      environment[this.apiUrlService.env] + "api/fixtures/league/" + x
    );
  }

  getFixturesFromTurn(
    tournamentId: string,
    turnId: string
  ): Observable<FixtureFromAPIDto[]> {
    return this.http.get<FixtureFromAPIDto[]>(
      environment[this.apiUrlService.env] +
        `api/fixtures/tournament/${tournamentId}/turn/${turnId}`
    );
  }

  getFixtureFromAPI(x: string, y: string) {
    return this.getFixturesFromAPI(y).pipe(
      map((fixtures) => {
        for (let fixture of fixtures) {
          if (fixture.id === x) {
            return fixture;
          }
        }
        return null;
      })
    );
  }
}

export class TournamentInfo {
  results: TournamentTable[];
  rewardsPool: number;
}

export class TournamentTable {
  place: number;
  potentialPlace: number;
  userName: string;
  winningsInTournamentPoints: string;
  potentialWinningsInTournamentPoints: number;
  awaitingCouponsCount: number;
}

export class LeagueDto {
  id: string;
  name: string;
  country: string;
  public constructor(init?: Partial<LeagueDto>) {
    Object.assign(this, init);
  }
}

export class FixtureFromAPIDto {
  id: string;
  name: string;
  season: string;
  localTeamName: string;
  visitorTeamName: string;
  startingAtUnixTimestampInMilliseconds: number;
  standingsLocalTeamPosition: number;
  standingsVisitorTeamPosition: number;
  leagueName: string;
  leagueId: string;
  leagueCountry: string;
}

export class TournamentHeader {
  id: string;
  name: string;
  entryFee: number;
  startDateUtc: Date;
  endDateUtc: Date;
  status: string;
  registrationOpenDateUtc: Date;
  registrationCloseDateUtc: Date;
  registrationOpenDate: Date;
  registrationCloseDate: Date;
  betsOpenDateUtcForFirstTurn: Date;
  betsCloseDateUtcForFirstTurn: Date;
  betsOpenDateForFirstTurn: Date;
  betsCloseDateForFirstTurn: Date;
  joined: boolean;
  turns: TurnMetadata[] = [];
}

export class TournamentMetadata {
  id: string;
  tournamentName: string;
  name: string;
  displayDate: Date;
  status: string;
  registrationOpenDateUtc: Date; //dodać do wysyłki SK
  registrationCloseDateUtc: Date; //dodać do wysyłki SK
  registrationOpenDate: Date; //dodać do wysyłki SK
  registrationCloseDate: Date; //dodać do wysyłki SK
  registrationCloseMarginInMinutes: number;
  rT_ParticipantsCount: number;
  rT_PricePool: number;
  betsOpenDateUtcForFirstTurn: Date;
  betsCloseDateUtcForFirstTurn: Date;
  betsOpenDateForFirstTurn: Date;
  betsCloseDateForFirstTurn: Date;
  betsOpenMarginInMinutes: number; //dodać do wysyłki SK - minuty
  betsCloseMarginInMinutes: number; //dodać do wysyłki SK - minuty
  //dodać required przy wysyłce turnieju, że tura musi mieć fixa
  //AG powinien wysyłać mi error albo jakieś info, że nie ma oddsów dla wybranego fixa
  startDateUtc: Date;
  secondsToStart: number;
  started: boolean = false;
  entryFee: number;
  endDateUtc: Date;
  joined: boolean;
  turns: TurnMetadata[] = [];
  period: string;
  type: string;
  description: string;
  leaderboardBonus: number;
  LeaderboardId?: string;
  leaderboardIds?: string[];
}

export class SportEvent {
  eventName: string;
  isSelected: boolean;
}

export class TurnMetadata {
  id: string;
  title: string;
  startDateUtc: Date;
  endDateUtc: Date;
  betsCloseDateUtc: Date;
  betsOpenDateUtc: Date;
  startingStack: number;
  won: number = 0;
  maxBets: number;
  maxCombinedOdds: number;
  maxBetOdds: number;
  maxCoupons: number;
  verificationStatus: string;
  status: string;
  events: SportEvent[] = [];
  fixtures: FixtureMetadata[] = [];
  coupons: InPlayFromAPIDto[] = [];
}

export class FixtureMetadata {
  id: string;
  title: string;
  startDateUtc: Date;
  leagueName: string;
  leagueId: string;
  leagueCountry: string;
  isSelected: boolean;
}

//functions using local documents

// getLeagues(): Observable<LeagueDto[]> {
//   return this.http.get<LeagueDto[]>('/assets/leagues.json');
// }

// getInPlay(x: string): Observable<InPlayDto[]> {
//   return this.http.get<InPlayDto[]>('/assets/InPlay'+ x +'.json');

// }

// getLeague(x: string){
//   return this.getLeagues()
//     .pipe(map(leagues => {
//       for (let league of leagues) {
//         if(league.id === x) {
//           return league;
//         }
//       }
//       return null
//     }))
// }

// getFromTEMP(x: string) {
//   return this.http.get(x);
// }

// getFixtures(x: string): Observable<FixtureDto[]> {
//   return this.http.get<FixtureDto[]>('/assets/'+ x +'.json');
// }

// getFixture(x: string, y: string){
//   return this.getFixtures(y)
//     .pipe(map(fixtures => {
//       for (let fixture of fixtures) {
//         if(fixture.id === x) {
//           return fixture;
//         }
//       }
//       return null
//     }))
// }

// getBets(x: string): Observable<Bet[]> {
//   return this.http.get<BetDto[]>('/assets/'+ x +'.json')
//     .pipe(map(
//       betsDtos => {
//         let bets:Bet[] = [];
//         for (let betDto of betsDtos) {
//           let bet = new Bet();
//           bet.id = betDto.id;
//           bet.name = betDto.name;
//           bet.type = betDto.type;
//           bet.odds = [];
//           for (let oddFromBetDto of betDto.odds){
//             let odd = new Odd();
//             odd.bet = bet;
//             odd.handicap = oddFromBetDto.handicap;
//             odd.label = oddFromBetDto.label;
//             odd.probability = oddFromBetDto.probability;
//             odd.total = oddFromBetDto.total;
//             odd.value = oddFromBetDto.value;
//             bet.odds.push(odd);
//           }
//           bets.push(bet);
//         }
//         return bets;
//       }
//     ));
// }

// getOdd(x: string, y: string){
//   return this.getFixtures(y)
//     .pipe(map(fixtures => {
//       for (let fixture of fixtures) {
//         if(fixture.id === x) {
//           return fixture;
//         }
//       }
//       return null
//     }))
// }

// getPagedBets(fixtureId: string, from: number, count: number): Observable<BetDto[]> {
//   return this.http.get<BetDto[]>('/assets/'+ fixtureId +'.json')
//     .pipe(map(
//       betsDtos => {
//         let bets = betsDtos.slice(from, from + count);
//         return bets;
//       }
//     ));
// }

// export class InPlayDto {
//   creationTimestamp: number;
//   projectedResolutionTimestamp: number;
//   amount: number;
//   winnings: number;
//   combinedOdds: string;
//   couponStatus: string;
//   bets: InPlayBetsDto;
// }
// export class InPlayBetsDto {
//   league: string;
//   league_name: string;
//   fixture: string;
//   localteam_name: string;
//   visitorteam_name: string;
//   starting_at_timestamp: number;
//   fixtureStatus: string;
//   id: string;
//   name: string;
//   total: string;
//   handicap: string;
//   label: string;
//   value: string;
//   probability: string;
//   betStatus: string;
// }

// export class BetDto {
//   id: string;
//   name: string;
//   type: string;
//   odds: OddDto[];
// }

// export class OddDto {
//   value: number;
//   total: string;
//   label: string;
//   probability: string;
//   handicap: string;
// }

// class standings {
//   localteam_position: number;
//   visitorteam_position: number;
// }

// export class FixtureDto {
//   id: string;
//   season_name: string;
//   stage_name: string;
//   localteam_name: string;
//   visitorteam_name: string;
//   starting_at_timestamp: number;
//   standings: standings;
// }
