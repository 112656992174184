<div style="display: flex;">
  <div style="padding: 12px 20px">
    <div style="text-align: center; align-items: center;">
      <!-- <div >
        <img style="height: 38px" src="https://i.ibb.co/qJFN8DF/oddseeL.png">
      </div> -->
      <div style="font-size: 18px; color: white;">
        Oddsee
      </div>
    </div> 
  </div>
</div>
