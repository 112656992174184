import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

export class RegisterUserCommand {
  login: string;
  password: string;
  email: string;
}

export class LoggedInUser {
  userName: string;
  roles: string[];
  accessToken: string;
  refreshToken: string;
}

export class User {
  balance: number;
  email: string;
  id: string;
  userName: string;
}

@Injectable({ providedIn: "root" })
export class AccountService {
  loggedInUser: LoggedInUser;
  userBalance: number;
  isAdminLoggedIn = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private apiUrlService: ApiUrlService
  ) {}

  login(login, password): Promise<void> {
    return this.http
      .post<LoggedInUser>(
        environment[this.apiUrlService.env] + "api/users/login",
        { login, password }
      )
      .toPromise()
      .then((loggedInUser) => {
        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
        this.loggedInUser = this.getLoggedInUser();
        if (this.loggedInUser.roles.find((r) => r == "admin")) {
          this.isAdminLoggedIn = true;
        }
      });
  }

  getBalance(userId: string): Observable<number> {
    return this.http.get<number>(
      environment[this.apiUrlService.env] + `api/users/${userId}/balance`
    );
  }

  getUserBalance(userId: string) {
    this.getBalance(userId).subscribe(
      (userBalance) => (this.userBalance = userBalance)
    );
  }

  reLogin(loggedInUser: LoggedInUser): void {
    localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
  }

  isUserLoggedIn(): boolean {
    let loggedInUser = localStorage.getItem("loggedInUser");
    return loggedInUser != null && loggedInUser != undefined;
  }

  getLoggedInUser() {
    const loggedInUser = <LoggedInUser>(
      JSON.parse(localStorage.getItem("loggedInUser"))
    );
    return loggedInUser;
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem("loggedInUser");
    this.loggedInUser = null;
    this.isAdminLoggedIn = false;
    this.router.navigate([""]);
  }

  register(user: RegisterUserCommand) {
    return this.http.post(
      environment[this.apiUrlService.env] + "api/users/register",
      user
    );
  }

  resetPassword(login, newPassword): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] + `api/users/reset-password`,
        { login, newPassword }
      )
      .toPromise();
  }

  resetMyPassword(login, oldPassword, newPassword): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] + `api/users/change-my-password`,
        { login, oldPassword, newPassword }
      )
      .toPromise();
  }

  async test() {
    return await this.http
      .get(environment[this.apiUrlService.env] + "api/test")
      .toPromise();
  }
}

export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      required: "Required",
      invalidCreditCard: "Is invalid credit card number",
      invalidEmailAddress: "Invalid email address",
      invalidPassword:
        "Invalid password. Password must be at least 6 characters long, and contain a number.",
      minlength: `Minimum length ${validatorValue.requiredLength}`,
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (
      control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }
}
