<app-top-bar-tournament></app-top-bar-tournament>

<div style="height: 55px; width: 100%; background: #6200EE; color: white;">   
  <div style="text-align: center; display: flex; align-items: center"> 
    <div style="width: 10%; padding-top: 10px;">
      <mat-icon style="font-size: 32px;  -webkit-text-fill-color:white;" 
      [routerLink]="['/oddsee/tournaments']">
        chevron_left
      </mat-icon>
    </div>  
    <div style="margin-left: auto; width: 80%; margin-right: auto;">
      <div style="font-size: 20px; padding-top: 5px;"> 
        Coupon manager
      </div>
    </div>
    <div style="width: 10%;">
    </div>
  </div>
</div>

<mat-progress-spinner *ngIf="spinnerService.visibility | async" style="margin-top: 30vh; margin-left: auto; margin-right: auto; align-items: center; text-align: center;" [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>


<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Active tournaments">

    <div *ngIf="myActiveTournamentsData?.length == 0">
      <div style="text-align: center; margin-top: 1vh;">
        You have no coupons in active tournament. Please register to tournament <span style="font-weight: 700;" [routerLink]="['']">here</span> create a coupon and come back again!  
      </div>
    </div>
    <div>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let tournament of myActiveTournamentsData" style="max-width: 680px; margin-left: auto; margin-right: auto;">
          <mat-expansion-panel-header>
            <mat-panel-title style=" width: 100%">
              <span style="font-weight: 600;width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                {{ tournament.name }}
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list *ngFor="let turn of tournament.turns">
              <mat-card *ngIf="turn.coupons.length > 0" style="margin-bottom: 2vh; margin-top: 2vh;">
                <mat-card-header style="margin-bottom: 2vh; margin-right: 16px;">
                  <div style="font-weight: 600;width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                    {{ turn.title }}
                  </div>
                </mat-card-header>
                <mat-card-content>
                  <mat-accordion> 
                    <mat-expansion-panel *ngFor="let coupon of turn.coupons" [expanded]="coupon?.isExpanded">
                      <mat-expansion-panel-header (click)="coupon.isExpanded = !coupon.isExpanded">
                          <mat-panel-title>
                            <div style="width: 100%; display: flex;">
                              <div class="fixtureLabel" style="font-size: 12px; width: 10%; text-align: center;">
                                Value: 
                                <span style="font-weight: bold">
                                  {{ coupon.value }}
                                </span>
                              </div>        
                              <div style="width: 50%; display: flex; margin-left: 15%; margin-right: 8%; text-align: center">
                                <div *ngIf="coupon.couponStatusLabel == 'Awaiting'"> 
                                  <span style="font-size: 12px; padding-top: 10px">Pos. Winnings: 
                                    <span style="font-weight: bold">
                                      {{ coupon.winningsInTournamentPoints | number: '1.2-2' }}
                                    </span></span>
                                </div>
                                <div style="font-size: 12px; margin-left: auto; margin-right: auto;" *ngIf="coupon.couponStatusLabel == 'Won'"> 
                                  <div>Won: </div>
                                  <span style="font-weight: bold">
                                    {{ coupon.winningsInTournamentPoints | number: '1.2-2' }}
                                  </span>
                                </div>
                              </div> 
                              <div style="width: 10%; padding-top: 10px">
                                <div [className]="coupon.couponStatusLabel == 'Won' ? 'Won' : ''" style="font-size: 12px; font-weight: bold">
                                  <span [className]="coupon.couponStatusLabel == 'Lost' ? 'Lost' : ''">
                                    <span [className]="coupon.couponStatusLabel == 'Awaiting' ? 'InPlay' : ''">
                                      {{ coupon.couponStatusLabel }}
                                    </span>
                                  </span>
                                </div>
                              </div> 
                            </div>
                          </mat-panel-title>
                          <!-- <mat-panel-description style="width: 20vw; align-items: center;">

                          </mat-panel-description> -->
                      </mat-expansion-panel-header>
                        <mat-selection-list [multiple]="false"> 
                          <mat-list-option>
                            <div style="width: 100%;">
                              <div class="fixtureTime" style="margin-left: auto; margin-right: auto;">
                                <div style="text-align: center;display: flex;">
                                  <div style="width:25%">
                                  </div>
                                  <div style="display: flex; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                                    <div style="padding-bottom: 3px; font-size: 12px;"> 
                                      Combined Odds: 
                                      <span style="font-weight: bold">
                                        {{ coupon.combinedOdds | number: '1.2-2' }}  
                                      </span>
                                      <div>
                                        <span [className]="coupon.couponStatus == '1' ? 'hidden' : ''" style="font-weight: bold">
                                          <span [className]="coupon.couponStatus == '2' ? 'hidden' : ''">
                                            <span [className]="coupon.couponStatus == '3' ? 'hidden' : ''">
                                            Res. by: {{ coupon.projectedResolutionTimestampInMs | date:'HH:mm dd-MM' }}
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div style="font-size:10px;width:25%;padding-top: 5px;">
                                    <div *ngIf="now < turn.betsCloseDateUtc">
                                      <mat-icon class="trashcan" (click)="intentionToDeleteCoupon(coupon.tournamentId, coupon.turnId, coupon.id)" color="warn">delete</mat-icon>
                                    </div>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          </mat-list-option>
                          <mat-list-option *ngFor="let bet of coupon.bets" style="height: 165px">
                            <div style="display: flex; flex-direction: row; text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 2vh; margin-top: 2vh;">             
                              <div style="padding-top: 6px;">
                                <div style="font-size:15px; font-weight: bold">
                                  <div *ngIf="bet.betStatus == '0'" [className]="bet.betStatus == '0' ? 'InPlay' : ''">
                                    Awaiting
                                  </div>
                                  <div *ngIf="bet.betStatus == '1'" [className]="bet.betStatus == '1' ? 'Won' : ''">
                                    Won
                                  </div>
                                  <div *ngIf="bet.betStatus == '2'" [className]="bet.betStatus == '2' ? 'Lost' : ''">
                                    Lost
                                  </div>
                                  <div *ngIf="bet.betStatus == '3'">
                                    Cancelled
                                  </div>
                                </div>
                                <div class="betDesc" style="font-size:15px; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">  
                                  <div style="font-weight: bold; color: #3700B3">
                                    {{ bet.localTeamName }} - {{ bet.visitorTeamName }}
                                  </div> 
                                    {{ bet.leagueName }}  
                                </div>
                                <div class="betLabel" style="font-size:13px; text-align: center; align-items: center; margin-left: auto; margin-right: auto;"> 
                                  
                                  {{ bet.name }} 
                                  <div> Choice: 
                                    <span style="font-weight: bold">
                                      {{ bet.label }}     
                                    </span>
                                  </div> 
                                  <div style="color:black">
                                    Odds: 
                                    <span style="font-weight: bold">
                                      {{ bet.odds }}  
                                    </span> 
                                    <span class="betLabel" style="font-size:13px; color:black; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                                      Start: {{ bet.startingAtTimestampInMs | date:'HH:mm dd-MM-yyyy' }}
                                    </span>
                                  </div> 
                                </div>    
                              </div>  
                              <div style="margin-right: 10px; float: right;">
                              </div>
                            </div>
                            <div style="margin-top: 2vh;">
                              <mat-divider style="margin-top: 0.5vh;" [inset]="true" *ngIf="!last" ></mat-divider>
                            </div>
                          </mat-list-option>
                        </mat-selection-list>
                    </mat-expansion-panel>
                  </mat-accordion>
                </mat-card-content> 
                  <div *ngIf="turn.won > 0 && turn.coupons.length > 1">
                    <mat-card-actions style="margin-bottom: 1vh;">
                      <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                        Won in Turn: <span class="Won" style="font-weight: 600;">{{ turn.won }}</span>
                      </div>
                    </mat-card-actions>
                  </div>
              </mat-card>
          </mat-selection-list>
            <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
              <button color="success" mat-raised-button class="button fancy-button" style="height: 40px; text-align: center;"
                [routerLink]="['/oddsee/tournaments', tournament?.id, 'table']" 
                [state]="{tournamentMetadata: tournament}">
                Tournament Table 
              </button> 
            </div> 
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-tab>


  <mat-tab label="Closed tournaments">
    <div *ngIf="myClosedTournamentsData?.length == 0">
      <div style="text-align: center; margin-top: 1vh;">
        You have no coupons in closed tournament. Please register to tournament <span style="font-weight: 700;" [routerLink]="['']">here</span> create a coupon and after tournament will be closed come back again!  
      </div>
    </div>
    <div>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let tournament of myClosedTournamentsData" style="max-width: 680px; margin-left: auto; margin-right: auto;">
          <mat-expansion-panel-header>
            <mat-panel-title style=" width: 100%">
              <span style="font-weight: 600;width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                {{ tournament.name }}
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list *ngFor="let turn of tournament.turns">
              <mat-card *ngIf="turn.coupons.length > 0" style="margin-bottom: 2vh; margin-top: 2vh;">
                <mat-card-header style="margin-bottom: 2vh; margin-right: 16px;">
                  <div style="font-weight: 600;width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                    {{ turn.title }}
                  </div>
                </mat-card-header>
                <mat-card-content>
                  <mat-accordion> 
                    <mat-expansion-panel *ngFor="let coupon of turn.coupons" [expanded]="coupon?.isExpanded">
                      <mat-expansion-panel-header (click)="coupon.isExpanded = !coupon.isExpanded">
                          <mat-panel-title>
                            <div style="width: 100%; display: flex;">
                              <div class="fixtureLabel" style="font-size: 12px; width: 10%; text-align: center;">
                                Value: 
                                <span style="font-weight: bold">
                                  {{ coupon.value }}
                                </span>
                              </div>        
                              <div style="width: 50%; display: flex; margin-left: 15%; margin-right: 8%; text-align: center">
                                <div *ngIf="coupon.couponStatusLabel == 'Awaiting'"> 
                                  <span style="font-size: 12px; padding-top: 10px">Pos. Winnings: 
                                    <span style="font-weight: bold">
                                      {{ coupon.winningsInTournamentPoints | number: '1.2-2' }}
                                    </span></span>
                                </div>
                                <div style="font-size: 12px; margin-left: auto; margin-right: auto;" *ngIf="coupon.couponStatusLabel == 'Won'"> 
                                  <div>Won: </div>
                                  <span style="font-weight: bold">
                                    {{ coupon.winningsInTournamentPoints | number: '1.2-2' }}
                                  </span>
                                </div>
                              </div> 
                              <div style="width: 10%; padding-top: 10px">
                                <div [className]="coupon.couponStatusLabel == 'Won' ? 'Won' : ''" style="font-size: 12px; font-weight: bold">
                                  <span [className]="coupon.couponStatusLabel == 'Lost' ? 'Lost' : ''">
                                    <span [className]="coupon.couponStatusLabel == 'Awaiting' ? 'InPlay' : ''">
                                      {{ coupon.couponStatusLabel }}
                                    </span>
                                  </span>
                                </div>
                              </div> 
                            </div>
                          </mat-panel-title>
                          <!-- <mat-panel-description style="width: 20vw; align-items: center;">

                          </mat-panel-description> -->
                      </mat-expansion-panel-header>
                        <mat-selection-list [multiple]="false"> 
                          <mat-list-option>
                            <div style="width: 100%;">
                              <div class="fixtureTime" style="margin-left: auto; margin-right: auto;">
                                <div style="text-align: center;display: flex;">
                                  <div style="width:25%">
                                  </div>
                                  <div style="display: flex; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                                    <div style="padding-bottom: 3px; font-size: 12px;"> 
                                      Combined Odds: 
                                      <span style="font-weight: bold">
                                        {{ coupon.combinedOdds | number: '1.2-2' }}  
                                      </span>
                                      <div>
                                        <span [className]="coupon.couponStatus == '1' ? 'hidden' : ''" style="font-weight: bold">
                                          <span [className]="coupon.couponStatus == '2' ? 'hidden' : ''">
                                            <span [className]="coupon.couponStatus == '3' ? 'hidden' : ''">
                                            Res. by: {{ coupon.projectedResolutionTimestampInMs | date:'HH:mm dd-MM' }}
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div style="font-size:10px;width:25%;padding-top: 5px;">
                                    <div *ngIf="now < turn.betsCloseDateUtc">
                                      <mat-icon class="trashcan" (click)="intentionToDeleteCoupon(coupon.tournamentId, coupon.turnId, coupon.id)" color="warn">delete</mat-icon>
                                    </div>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          </mat-list-option>
                          <mat-list-option *ngFor="let bet of coupon.bets" style="height: 165px">
                            <div style="display: flex; flex-direction: row; text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 2vh; margin-top: 2vh;">             
                              <div style="padding-top: 6px;">
                                <div style="font-size:15px; font-weight: bold">
                                  <div *ngIf="bet.betStatus == '0'" [className]="bet.betStatus == '0' ? 'InPlay' : ''">
                                    Awaiting
                                  </div>
                                  <div *ngIf="bet.betStatus == '1'" [className]="bet.betStatus == '1' ? 'Won' : ''">
                                    Won
                                  </div>
                                  <div *ngIf="bet.betStatus == '2'" [className]="bet.betStatus == '2' ? 'Lost' : ''">
                                    Lost
                                  </div>
                                  <div *ngIf="bet.betStatus == '3'">
                                    Cancelled
                                  </div>
                                </div>
                                <div class="betDesc" style="font-size:15px; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">  
                                  <div style="font-weight: bold; color: #3700B3">
                                    {{ bet.localTeamName }} - {{ bet.visitorTeamName }}
                                  </div> 
                                    {{ bet.leagueName }}  
                                </div>
                                <div class="betLabel" style="font-size:13px; text-align: center; align-items: center; margin-left: auto; margin-right: auto;"> 
                                  
                                  {{ bet.name }} 
                                  <div> Choice: 
                                    <span style="font-weight: bold">
                                      {{ bet.label }}     
                                    </span>
                                  </div> 
                                  <div style="color:black">
                                    Odds: 
                                    <span style="font-weight: bold">
                                      {{ bet.odds }}  
                                    </span> 
                                    <span class="betLabel" style="font-size:13px; color:black; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                                      Start: {{ bet.startingAtTimestampInMs | date:'HH:mm dd-MM-yyyy' }}
                                    </span>
                                  </div> 
                                </div>    
                              </div>  
                              <div style="margin-right: 10px; float: right;">
                              </div>
                            </div>
                            <div style="margin-top: 2vh;">
                              <mat-divider style="margin-top: 0.5vh;" [inset]="true" *ngIf="!last" ></mat-divider>
                            </div>
                          </mat-list-option>
                        </mat-selection-list>
                    </mat-expansion-panel>
                  </mat-accordion>
                </mat-card-content> 
                  <div *ngIf="turn.won > 0 && turn.coupons.length > 1">
                    <mat-card-actions style="margin-bottom: 1vh;">
                      <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                        Won in Turn: <span class="Won" style="font-weight: 600;">{{ turn.won }}</span>
                      </div>
                    </mat-card-actions>
                  </div>
              </mat-card>
          </mat-selection-list>
            <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
              <button color="success" mat-raised-button class="button fancy-button" style="height: 40px; text-align: center;"
                [routerLink]="['/oddsee/tournaments', tournament?.id, 'table']" 
                [state]="{tournamentMetadata: tournament}">
                Tournament Table 
              </button> 
            </div> 
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template #couponDelete let-close="close">
  <div class="modal-header">
    <h5 class="modal-title" style="text-align: center;">Do you want to delete this coupon?</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <div style="display: flex;">
      <button type="button" class="btn btn-outline-secondary" (click)="close();deleteCoupon(modalData?.tournamentId, modalData?.turnId, modalData?.couponId)">
        Yes
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        No
      </button>
    </div>        
  </div>
</ng-template>
