import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  FixtureFromAPIDto,
  SportEvent,
  FixtureMetadata,
  TurnMetadata,
} from "../../common/basic.service";
import { MatDialog } from "@angular/material/dialog";
import { CouponComponent } from "../../../modules/betting/coupon/coupon.component";
import { environment } from "../../../../environments/environment";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Injectable()
export class CouponService {
  combinedOdds: number;
  displayCoupon: boolean = false;
  turn: TurnMetadata;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private apiUrlService: ApiUrlService
  ) {}

  selectedOdds: SelectedOdd[] = [];
  globalSelectedOdds: SelectedOdd[] = [];
  couponSelectedOdds: SelectedOdd[] = [];
  couponsToPost = new CouponsToPost();
  selectionData: OddsSelectionData;
  selectedTournament: string;
  turnWallet: number;

  addOddToCoupon(odd: SelectedOdd) {
    this.selectedOdds.push(odd);
    if (
      !this.globalSelectedOdds.some(
        (bet) =>
          bet.betName == odd.betName &&
          bet.label == odd.label &&
          bet.handicap == odd.handicap &&
          bet.total == odd.total &&
          bet.tournamentId == odd.tournamentId &&
          bet.turnId == odd.turnId &&
          bet.fixtureId == odd.fixtureId
      )
    ) {
      this.globalSelectedOdds.push(odd);
    }
  }

  recalcTurnWallet(startingStack: number, betsSum: number) {
    if (!betsSum) {
      this.turnWallet = startingStack;
      return;
    }
    this.turnWallet = startingStack - betsSum;
  }

  specifySelectedTournament(tournamentId: string) {
    this.selectedTournament = tournamentId;
  }

  addBetOrOddToCoupon(odd: SelectedOdd) {
    for (let coupon of this.couponsToPost.coupons) {
      if (
        coupon.turnId == odd.turnId &&
        coupon.tournamentId == coupon.tournamentId
      ) {
        if (
          !coupon.bets.some(
            (bet) =>
              bet.name == odd.betName &&
              bet.label == odd.label &&
              bet.handicap == odd.handicap &&
              bet.total == odd.total &&
              bet.tournamentId == odd.tournamentId &&
              bet.turnId == odd.turnId &&
              bet.fixtureId == odd.fixtureId
          )
        ) {
          let betToPost = new BetToPost();
          betToPost.name = odd.betName;
          betToPost.leagueId = odd.leagueId;
          betToPost.fixtureId = odd.fixtureId;
          betToPost.eventId = odd.oddId;
          betToPost.total = odd.total;
          betToPost.handicap = odd.handicap;
          betToPost.name = odd.betName;
          betToPost.label = odd.label;
          betToPost.odds = odd.odds;
          betToPost.turnId = odd.turnId;
          betToPost.tournamentId = odd.tournamentId;
          coupon.bets.push(betToPost);
        }
      }
    }
  }

  // openDialog(turn: TurnMetadata ): void {
  //   const dialogRef = this.dialog.open(CouponComponent);
  //   this.setTurn(turn);
  //   dialogRef.afterClosed().subscribe(
  //     _ => {
  //       this.showCoupon();
  //     }
  //   );
  // }

  getMyStatusForTournament(
    tournamentId: string
  ): Observable<TournamentStatus[]> {
    return this.http.get<TournamentStatus[]>(
      environment[this.apiUrlService.env] +
        `api/user-coupons/tournament/${tournamentId}/summary/my`
    );
  }

  async asyncGetMyStatusForTournament(
    tournamentId: string
  ): Promise<TournamentStatus[]> {
    return await this.http
      .get<TournamentStatus[]>(
        environment[this.apiUrlService.env] +
          `api/user-coupons/tournament/${tournamentId}/summary/my`
      )
      .toPromise();
  }

  async getTurn() {
    return await this.turn;
  }

  setTurn(turn: TurnMetadata) {
    this.turn = turn;
  }

  hideCoupon() {
    this.displayCoupon = false;
  }

  showCoupon() {
    if (!this.couponSelectedOdds.length) {
      return (this.displayCoupon = false);
    }
    this.displayCoupon = true;
  }

  deleteCoupon(tournamentId: string, turnId: string) {
    this.couponsToPost.coupons = this.couponsToPost.coupons.filter(
      (coupon) =>
        coupon !==
        this.couponsToPost.coupons.find(
          (coupon) =>
            coupon.turnId == turnId && coupon.tournamentId == tournamentId
        )
    );
    this.globalSelectedOdds = this.globalSelectedOdds.filter(
      (odd) => odd.turnId !== turnId
    );
    this.couponSelectedOdds = this.couponSelectedOdds.filter(
      (odd) => odd.turnId !== turnId
    );
  }

  deleteBetFromCoupon(
    tournamentId: string,
    turnId: string,
    label: string,
    betName: string,
    fixtureId: string
  ) {
    const coupon = this.couponsToPost.coupons.find(
      (coupon) => coupon.turnId == turnId && coupon.tournamentId == tournamentId
    );

    const odd = coupon.bets.find(
      (b) => b.label == label && b.name == betName && b.fixtureId == fixtureId
    );

    const index: number = coupon.bets.indexOf(odd);
    if (index !== -1) {
      coupon.bets.splice(index, 1);
    }

    this.globalSelectedOdds = this.globalSelectedOdds.filter((odd) => {
      return (
        odd.betName != betName ||
        odd.label != label ||
        odd.fixtureId != fixtureId
      );
    });

    this.couponSelectedOdds = this.couponSelectedOdds.filter((odd) => {
      return (
        odd.betName != betName ||
        odd.label != label ||
        odd.fixtureId != fixtureId
      );
    });
  }

  allignTurnOdds(tournamentId: string, turnId: string) {
    this.couponSelectedOdds = this.globalSelectedOdds.filter(
      (odd) => odd.turnId == turnId && odd.tournamentId == tournamentId
    );

    if (this.couponSelectedOdds) {
      let combinedOdds = 1;
      for (let selectedOdd of this.couponSelectedOdds) {
        combinedOdds *= selectedOdd.odds;
      }
      this.combinedOdds = combinedOdds;
    }
  }

  allignOddsCollections(tournamentId: string, turnId: string) {
    for (let odd of this.globalSelectedOdds.filter(
      (bet) =>
        bet.tournamentId == this.selectionData.tournamentId &&
        bet.turnId == this.selectionData.turnId
    )) {
      let fixtureSelectedOdds = this.globalSelectedOdds.filter(
        (odd) => odd.fixtureId == this.selectionData.fixture.id
      );
      if (fixtureSelectedOdds.some((odd1) => odd1.fixtureId == odd.fixtureId)) {
        if (
          !this.selectedOdds.some(
            (bet1) =>
              bet1.betName == odd.betName &&
              bet1.label == odd.label &&
              bet1.handicap == odd.handicap &&
              bet1.total == odd.total &&
              bet1.tournamentId == odd.tournamentId &&
              bet1.turnId == odd.turnId &&
              bet1.fixtureId == odd.fixtureId
          )
        ) {
          this.globalSelectedOdds = this.globalSelectedOdds.filter(
            (bet1) => bet1 !== odd
          );
        }
      }
    }
    for (let coupon of this.couponsToPost.coupons) {
      if (
        !this.globalSelectedOdds.some(
          (bet) =>
            bet.tournamentId == coupon.tournamentId &&
            bet.turnId == coupon.turnId
        )
      ) {
        this.deleteCoupon(coupon.tournamentId, coupon.turnId);
        // console.log(this.couponsToPost)
      }
      if (
        this.selectedOdds[0]?.turnId == coupon.turnId &&
        this.selectedOdds[0]?.tournamentId == coupon.tournamentId
      ) {
        const newBets = coupon.bets.filter((bet) =>
          this.globalSelectedOdds.some(
            (bet1) =>
              bet1.betName == bet.name &&
              bet1.label == bet.label &&
              bet1.handicap == bet.handicap &&
              bet1.total == bet.total &&
              bet1.tournamentId == bet.tournamentId &&
              bet1.turnId == bet.turnId &&
              bet1.fixtureId == bet.fixtureId
          )
        );
        coupon.bets = newBets;
      }
    }
    this.allignTurnOdds(tournamentId, turnId);
  }

  addOddToCouponsList(odd: SelectedOdd) {
    this.addOddToCoupon(odd);
    if (this.couponsToPost.coupons.length == 0) {
      let newCoupon = new CouponToPost();
      newCoupon.tournamentId = odd.tournamentId;
      newCoupon.turnId = odd.turnId;
      newCoupon.value = 0;
      newCoupon.bets = [];
      this.couponsToPost.coupons.push(newCoupon);
      this.addBetOrOddToCoupon(odd);
      // console.log(this.couponsToPost)
      return;
    } else if (
      !this.couponsToPost.coupons.some(
        (b1) => b1.tournamentId == odd.tournamentId && b1.turnId == odd.turnId
      )
    ) {
      let newCoupon = new CouponToPost();
      newCoupon.tournamentId = odd.tournamentId;
      newCoupon.turnId = odd.turnId;
      newCoupon.value = 0;
      newCoupon.bets = [];
      this.couponsToPost.coupons.push(newCoupon);
      this.addBetOrOddToCoupon(odd);
      // console.log(this.couponsToPost)
      return;
    }
    this.addBetOrOddToCoupon(odd);
    console.log(this.couponsToPost);
  }

  specifySelectedOdds(
    fixture: FixtureFromAPIDto,
    tournamentId: string,
    turnId: string
  ) {
    if (!this.selectionData) {
      this.selectionData = new OddsSelectionData();
    }
    this.selectionData.turnId = turnId;
    this.selectionData.tournamentId = tournamentId;
    this.selectionData.fixture = fixture;
  }

  getSelectedTournamentAndTurn(tournamentId: string, turnId: string) {
    if (!this.selectionData) {
      this.selectionData = new OddsSelectionData();
    }
    this.selectionData.turnId = turnId;
    this.selectionData.tournamentId = tournamentId;
  }

  getSelectedOdds() {
    return this.selectedOdds;
  }

  getCouponSelectedOdds() {
    return this.couponSelectedOdds;
  }

  clearOdds(tournamentId: string, turnId: string) {
    this.selectedOdds = this.selectedOdds.filter(
      (odd) => odd.turnId !== turnId && odd.tournamentId !== tournamentId
    );
  }

  removeOddFromCoupon(odd: SelectedOdd) {
    const index: number = this.selectedOdds.indexOf(odd);
    if (index !== -1) {
      this.selectedOdds.splice(index, 1);
    }
  }

  removeOddsForFixture(fixtureId: string) {
    this.selectedOdds = this.selectedOdds.filter(
      (odd) => odd.fixtureId != fixtureId
    );
  }

  removeOddsForTurn(tournamentId: string, turnId: string) {
    this.selectedOdds = this.selectedOdds.filter((odd) => {
      odd.tournamentId != tournamentId;
      odd.turnId != turnId;
    });
  }

  getSelectedOddsForFixture(fixtureId) {
    return this.selectedOdds.filter((odd) => odd.fixtureId == fixtureId);
  }

  getSelectedOddsForTurn(
    fixtureId: string,
    tournamentId: string,
    turnId: string
  ) {
    return this.globalSelectedOdds.filter((odd) => {
      return (
        odd.fixtureId == fixtureId &&
        odd.tournamentId == tournamentId &&
        odd.turnId == turnId
      );
    });
  }

  postCoupon(
    coupon: CouponToPost,
    tournamentId: string,
    turnId: string
  ): Observable<CouponToPost> {
    return this.http.post<CouponToPost>(
      environment[this.apiUrlService.env] +
        `api/user-coupons/tournament/${tournamentId}/turn/${turnId}`,
      coupon
    );
  } //work in progress
}

export class SelectedOdd {
  betName: string;
  leagueId: string;
  leagueCountry: string;
  leagueName: string;
  fixtureId: string;
  localTeamName: string;
  visitorTeamName: string;
  oddId: string;
  label: string;
  total: string;
  handicap: string;
  odds: number;
  probabilityLabel: string;
  status: boolean;
  tournamentId: string;
  turnId: string;

  public constructor(init?: Partial<SelectedOdd>) {
    Object.assign(this, init);
  }
}

export class Odd {
  bet: Bet;
  value: number;
  total: string;
  label: string;
  probabilityLabel: string;
  handicap: string;
  isSelected: boolean;
}

export class Bet {
  id: string;
  name: string;
  type: string;
  isExpanded: boolean = false;
  odds: Odd[] = [];
}

export class BetToPost {
  leagueId: string;
  fixtureId: string;
  eventId: string;
  total: string;
  handicap: string;
  name: string;
  label: string;
  odds: number;
  tournamentId: string;
  turnId: string;
}

export class CouponToPost {
  value: number = 0;
  tournamentId: string;
  turnId: string;
  bets: BetToPost[] = [];
}

export class CouponsToPost {
  coupons: CouponToPost[] = [];
}

export class OddsSelectionData {
  tournamentId: string;
  turnId: string;
  fixture: FixtureFromAPIDto;
}

export class TournamentStatus {
  turnId: string;
  couponsCount: number;
  startingStack: number; //not needed
  betsSum: number;
}

export class TournamentData {
  id: string;
  tournamentName: string;
  startDateUtc: Date;
  entryFee: number;
  endDateUtc: Date;
  turns: TurnData[] = [];
}

export class TurnData {
  id: string;
  couponsCount: number;
  betsSum: number;
  title: string;
  startDateUtc: Date;
  endDateUtc: Date;
  betsCloseDateUtc: Date;
  betsOpenDateUtc: Date;
  startingStack: number;
  maxBetOdds: number;
  maxCoupons: number;
  maxBets: number;
  maxCombinedOdds: number;
  secondsToStart: number;
  secondsToEnd: number;
  status: string = "notYetStarted";
  events: SportEvent[] = [];
  fixtures: FixtureMetadata[] = [];
}
