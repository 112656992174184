<mat-card style="width: 35vw">
  <mat-card-title
    style="
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      align-items: center;
    "
    >New Tournament</mat-card-title
  >
  <!-- <mat-card-subtitle style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
        First Turn Start: {{ tournamentMetadata.startDateUtc | date:'dd-MM-yyyy' }}   <br />    
        Last Turn End: {{ tournamentMetadata.endDateUtc | date:'dd-MM-yyyy' }}
    </mat-card-subtitle> -->
  <div *ngFor="let turn of tournamentMetadata.turns">
    <mat-accordion style="margin-bottom: 5px">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ turn.title }}
          </mat-panel-title>
          <!-- <mat-panel-description > Max points in 1 Coupon: {{ turn.maxBetOdds * turn.startingStack }}
                    </mat-panel-description> -->
        </mat-expansion-panel-header>
        <mat-card>
          <mat-card-title>Fixtures</mat-card-title>
          <div class="scrollable">
            <div *ngFor="let fixture of turn.fixtures">
              <mat-checkbox
                color="primary"
                [(ngModel)]="fixture.isSelected"
              ></mat-checkbox>
              {{ fixture.title }}
            </div>
          </div>
        </mat-card>
        <mat-card>
          <mat-card-title>Events</mat-card-title>
          <div class="scrollable">
            <div *ngFor="let event of turn.events">
              <mat-checkbox
                color="warn"
                [(ngModel)]="event.isSelected"
              ></mat-checkbox>
              {{ event.eventName }}
            </div>
          </div>
        </mat-card>
        <mat-card>
          <mat-form-field class="example-form-field">
            <mat-label>Turn Stack</mat-label>
            <input matInput type="number" [(ngModel)]="turn.startingStack" />
            <button
              mat-button
              *ngIf="turn.startingStack"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="turn.startingStack = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-card>
        <mat-card>
          <mat-form-field class="example-form-field">
            <mat-label>Max Odds</mat-label>
            <input matInput type="number" [(ngModel)]="turn.maxBetOdds" />
            <button
              mat-button
              *ngIf="maxBetOdds"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="maxBetOdds = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <form
    [formGroup]="tournamentSubmit"
    (ngSubmit)="onSubmit(); openSnackBar()"
    style="text-align: center"
  >
    <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%">
      <mat-label>Tournament Name</mat-label>
      <input
        matInput
        id="Name"
        type="text"
        class="example-right-align"
        formControlName="Name"
        [(ngModel)]="tournamentMetadata.tournamentName"
      />
      <!-- <mat-label>Enter Price</mat-label>
            <input matInput id="Amount" type="number" class="example-right-align" formControlName="Amount" [(ngModel)]="tournamentMetadata.entryFee">
            <span matPrefix>Oddsee Coins &nbsp;</span> -->
      <!-- <span matSuffix>.00</span> -->
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%">
      <mat-label>Tournament Period</mat-label>
      <input
        matInput
        id="Period"
        type="text"
        class="example-right-align"
        formControlName="Period"
        [(ngModel)]="tournamentMetadata.period"
      />
    </mat-form-field>

    <br />
    <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%">
      <mat-label>Tournament Type</mat-label>
      <input
        matInput
        id="Type"
        type="text"
        class="example-right-align"
        formControlName="Type"
        [(ngModel)]="tournamentMetadata.type"
      />
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%">
      <mat-label>Tournament Description</mat-label>
      <textarea
        matInput
        class="example-right-align"
        formControlName="Description"
        [(ngModel)]="tournamentMetadata.description"
      ></textarea>
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%">
      <mat-label>Leaderboard Bonus</mat-label>
      <textarea
        matInput
        class="example-right-align"
        formControlName="LeaderboardBonus"
        [(ngModel)]="tournamentMetadata.leaderboardBonus"
      ></textarea>
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" style="margin-top: 20px; width: 60%">
      <mat-label>Leaderboard Id</mat-label>
      <textarea
        matInput
        class="example-right-align"
        formControlName="LeaderboardId"
        [(ngModel)]="tournamentMetadata.LeaderboardId"
      ></textarea>
    </mat-form-field>

    <br />

    <div>
      <div style="font-weight: bold">Registration Open Date</div>
      <div style="width: 100%">
        <input
          matInput
          [ngxMatDatetimePicker]="pickerRegistrationOpenDateUtc"
          formControlName="registrationOpenDateUtc"
          [(ngModel)]="tournamentMetadata.registrationOpenDateUtc"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerRegistrationOpenDateUtc"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #pickerRegistrationOpenDateUtc
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="1"
          [stepMinute]="1"
          [stepSecond]="1"
          [touchUi]="true"
          [color]="'primary'"
          [enableMeridian]="false"
          [disableMinute]="false"
          [hideTime]="false"
        >
        </ngx-mat-datetime-picker>
      </div>
    </div>

    <!-- <div> 
            <div style="font-weight: bold; margin-right: 50px;">Registration Close Date</div> 
            <div style="width: 100%;">
                <input matInput [ngxMatDatetimePicker]="pickerRegistrationCloseDateUtc" formControlName="registrationCloseDateUtc"  [(ngModel)]="tournamentMetadata.registrationCloseDateUtc">
                <mat-datepicker-toggle matSuffix [for]="pickerRegistrationCloseDateUtc"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickerRegistrationCloseDateUtc [showSpinners]="true" [showSeconds]="false"
                    [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                    [touchUi]="true" [color]="primary" [enableMeridian]="false" 
                    [disableMinute]="false" [hideTime]="false">
                </ngx-mat-datetime-picker>
            </div>
        </div> -->

    <!-- <div> 
            <div style="font-weight: bold; margin-right: 50px;">//WIP Registration Close Margin (in minutes)</div> 
            <div style="width: 100%;">
                <input matInput [ngxMatDatetimePicker]="pickerRegistrationCloseMarginInMinutes" formControlName="registrationCloseMarginInMinutes"  [(ngModel)]="tournamentMetadata.registrationCloseMarginInMinutes">
                <mat-datepicker-toggle matSuffix [for]="pickerRegistrationCloseMarginInMinutes"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickerRegistrationCloseMarginInMinutes [showSpinners]="true" [showSeconds]="false"
                    [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                    [touchUi]="true" [color]="primary" [enableMeridian]="false" 
                    [disableMinute]="false" [hideTime]="false">
                </ngx-mat-datetime-picker>
            </div>
        </div> -->
    <div>
      <div style="font-weight: bold">bets open margin (in minutes)</div>
      <input
        style="width: 100px"
        matInput
        type="number"
        formControlName="registrationCloseMarginInMinutes"
        [(ngModel)]="tournamentMetadata.registrationCloseMarginInMinutes"
      />
    </div>

    <!-- <div> 
            <div style="font-weight: bold; margin-right: 50px;">bets open margin (in minutes</div> 
            <div style="width: 100%;">
                <input matInput [ngxMatDatetimePicker]="pickerBetsOpenMarginInMinutes" formControlName="betsOpenMarginInMinutes"  [(ngModel)]="tournamentMetadata.betsOpenMarginInMinutes">
                <mat-datepicker-toggle matSuffix [for]="pickerBetsOpenMarginInMinutes"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickerBetsOpenMarginInMinutes [showSpinners]="true" [showSeconds]="false"
                    [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                    [touchUi]="true" [color]="primary" [enableMeridian]="false" 
                    [disableMinute]="false" [hideTime]="false">
                </ngx-mat-datetime-picker>
            </div>
        </div> -->
    <div>
      <div style="font-weight: bold">bets open margin (in minutes)</div>
      <input
        style="width: 100px"
        matInput
        type="number"
        formControlName="betsOpenMarginInMinutes"
        [(ngModel)]="tournamentMetadata.betsOpenMarginInMinutes"
      />
    </div>

    <!-- <div> 
            <div style="font-weight: bold; margin-right: 50px;">bets close margin (in minutes)</div> 
            <div style="width: 100%;">
                <input matInput [ngxMatDatetimePicker]="pickerBetsCloseMarginInMinutes" formControlName="betsCloseMarginInMinutes"  [(ngModel)]="tournamentMetadata.betsCloseMarginInMinutes">
                <mat-datepicker-toggle matSuffix [for]="pickerBetsCloseMarginInMinutes"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickerBetsCloseMarginInMinutes [showSpinners]="true" [showSeconds]="false"
                    [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                    [touchUi]="true" [color]="primary" [enableMeridian]="false" 
                    [disableMinute]="false" [hideTime]="false">
                </ngx-mat-datetime-picker>
            </div>
        </div> -->
    <div>
      <div style="font-weight: bold">bets close margin (in minutes)</div>
      <input
        style="width: 100px"
        matInput
        type="number"
        formControlName="betsCloseMarginInMinutes"
        [(ngModel)]="tournamentMetadata.betsCloseMarginInMinutes"
      />
    </div>

    <div>
      <div style="font-weight: bold">Entry Fee:</div>
      <input
        style="width: 100px"
        matInput
        type="number"
        formControlName="entryFee"
        [(ngModel)]="tournamentMetadata.entryFee"
      />
    </div>

    <br />

    <!-- <mat-form-field>
            <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [formControl]="dateControl"
               [min]="minDate" [max]="maxDate" [disabled]="disabled" [(ngModel)]="tournamentMetadata.displayDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
               [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
               [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" 
               [disableMinute]="disableMinute" [hideTime]="hideTime">
            </ngx-mat-datetime-picker>
         </mat-form-field> -->

    <button
      mat-raised-button
      [routerLink]="['/oddsee']"
      [disabled]="!tournamentMetadata.tournamentName"
      style="margin-bottom: 10px; margin-top: 10px"
      color="success"
      type="submit"
    >
      Submit Tournament!
    </button>
  </form>
</mat-card>

<!-- <ng-template #postedSuccesfully let-close="close" style="z-index: 2200">
    <div class="modal-header">
        <h5 class="modal-title">Do you want to submit this tournament?</h5>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="close()">
                Yes
            </button>
            <button type="button" class="btn btn-outline-secondary" (click)="close()">
                No
            </button>
        </div>
    </div>
  </ng-template> -->
