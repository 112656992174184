import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  startOfDay,
  endOfDay,
  addDays,
  isSameDay,
  isSameMonth,
  fromUnixTime,
  getDate,
  addMonths,
  getHours,
  getMinutes,
  addWeeks,
} from "date-fns";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import "flatpickr/dist/flatpickr.css";
import { SpinnerService } from "../../../infrastructure/network/spinner.service";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "angular-calendar";
import { EventColor, EventAction } from "calendar-utils";
import { MatDialog } from "@angular/material/dialog";
import { TurnSetupComponent } from "../turn-setup/turn-setup.component";
import { TournamentSetupComponent } from "../tournament-setup/tournament-setup.component";
import {
  TurnMetadata,
  FixtureMetadata,
  BasicService,
} from "../../../modules/common/basic.service";
import { Guid } from "guid-typescript";
import { FormControl } from "@angular/forms";
import { CalendarService, League } from "../common/calendar.service";

const colors: any = {
  red: () => {
    return {
      primary: "#ad2121",
      secondary: "#FAE3E3",
    };
  },
  violet: () => {
    return {
      primary: "#6A1B9A",
      secondary: "#BC87BA",
    };
  },
  blue: () => {
    return {
      primary: "#1e90ff",
      secondary: "#D1E8FF",
    };
  },
  yellow: () => {
    return {
      primary: "#e3bc08",
      secondary: "#FDF1BA",
    };
  },
  gray: () => {
    return {
      primary: "#888888",
      secondary: "#bbbbbb",
    };
  },
  black: () => {
    return {
      primary: "#000000",
      secondary: "#333333",
    };
  },
  green: () => {
    return {
      primary: "#06A108",
      secondary: "#88D969",
    };
  },
  brown: () => {
    return {
      primary: "#964B00",
      secondary: "#964B00",
    };
  },
};

export class FixtureForCalendar<MetaType = any> implements CalendarEvent {
  leagueName: string;
  leagueId: string;
  leagueCountry: string;
  id: string;
  type: string;
  start: Date;
  end?: Date;
  displayDate: Date;
  startDateUtc: Date;
  endDateUtc?: Date;
  title: string;
  titleShort: string;
  color?: EventColor;
  status: string;
  price: number;
  actions?: EventAction[];
  verificationStatus: string;
  allDay?: boolean;
  isSelected: boolean = true;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  meta?: MetaType;
}

@Component({
  selector: "app-calendar",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit {
  @ViewChild("eventDetails", { static: true }) eventDetails: TemplateRef<any>;
  @ViewChild("createTournament", { static: true })
  createTournament: TemplateRef<any>;
  @ViewChild("createTurn", { static: true }) createTurn: TemplateRef<any>;
  @ViewChild("changeMetaEvent", { static: true })
  changeMetaEvent: TemplateRef<any>;

  @Output()
  TurnSetupClosed: EventEmitter<any> = new EventEmitter<any>();
  TournamentSetupClosed: EventEmitter<any> = new EventEmitter<any>();

  view: CalendarView = CalendarView.Month;
  showMenu = false;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: FixtureForCalendar;
  };

  openMenu() {
    this.showMenu = true;
  }

  closeMenu() {
    this.showMenu = false;
  }

  actions: EventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: "Edit",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.showEventDetails("Edited", event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: "Delete",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.activeDayIsOpen = false;
      },
    },
  ];

  refreshCalendar: Subject<any> = new Subject();
  turnTitle = "Turn 1";
  tempEvent: CalendarEvent;
  tempStart: Date;
  tempEnd: Date;
  betsOpenMargin = 1440;
  betsCloseMargin = 30;
  registrationCloseMargin = 60;
  registrationOpenDate = new Date();
  registrationCloseDate = addDays(new Date(), 3);
  tournamentStart = addDays(new Date(), 4);
  tournamentEnd = addDays(new Date(), 6);
  turnStart = startOfDay(addDays(new Date(), 1));
  turnEnd = endOfDay(addDays(new Date(), 1));
  events: FixtureForCalendar[] = [];
  allEvents: FixtureForCalendar[] = [];
  turns: TurnMetadata[] = [];
  currentDateView: Date;
  activeDayIsOpen: boolean = true;
  leagues = new FormControl();
  leaguesList: League[] = [
    {
      id: "106",
      name: "Ekstraklasa",
      country: "Poland",
      isSelected: true,
    },
    {
      id: "38",
      name: "UEFA U21 Championship",
      country: "World",
      isSelected: true,
    },
    {
      id: "42",
      name: "League Two",
      country: "England",
      isSelected: true,
    },
    {
      id: "5",
      name: "UEFA Nations League",
      country: "World",
      isSelected: true,
    },
    {
      id: "107",
      name: "I Liga",
      country: "Poland",
      isSelected: true,
    },
    {
      id: "108",
      name: "Cup",
      country: "Poland",
      isSelected: true,
    },
    {
      id: "3",
      name: "UEFA Europa League",
      country: "World",
      isSelected: true,
    },
    {
      id: "2",
      name: "UEFA Champions League",
      country: "World",
      isSelected: true,
    },
    {
      id: "61",
      name: "Ligue 1",
      country: "France",
      isSelected: true,
    },
    {
      id: "39",
      name: "Premier League",
      country: "England",
      isSelected: true,
    },
    {
      id: "78",
      name: "Bundesliga 1",
      country: "Germany",
      isSelected: true,
    },
    {
      id: "140",
      name: "Primera Division",
      country: "Spain",
      isSelected: true,
    },
    {
      id: "135",
      name: "Serie A",
      country: "Italy",
      isSelected: true,
    },
    {
      id: "144",
      name: "Jupiler Pro League",
      country: "Belgium",
      isSelected: true,
    },
    {
      id: "88",
      name: "Eredivisie",
      country: "Netherlands",
      isSelected: true,
    },
    {
      id: "94",
      name: "Primeira Liga",
      country: "Portugal",
      isSelected: true,
    },
    {
      id: "179",
      name: "Premiership",
      country: "Scotland",
      isSelected: true,
    },
    {
      id: "333",
      name: "Premier League",
      country: "Ukraine",
      isSelected: true,
    },
    {
      id: "203",
      name: "Super Lig",
      country: "Turkey",
      isSelected: true,
    },
    {
      id: "119",
      name: "Superligaen",
      country: "Denmark",
      isSelected: true,
    },
    {
      id: "40",
      name: "Championship",
      country: "England",
      isSelected: true,
    },
    {
      id: "253",
      name: "Major League Soccer",
      country: "USA",
      isSelected: true,
    },
    {
      id: "235",
      name: "Premier League",
      country: "Russia",
      isSelected: true,
    },
    {
      id: "4",
      name: "Euro Championship",
      country: "World",
      isSelected: true,
    },
    {
      id: "21",
      name: "Confederations Cup",
      country: "World",
      isSelected: true,
    },
    {
      id: "1",
      name: "World Cup",
      country: "World",
      isSelected: true,
    },
    {
      id: "15",
      name: "FIFA Club World Cup",
      country: "World",
      isSelected: true,
    },
    {
      id: "480",
      name: "Olympics Men",
      country: "World",
      isSelected: true,
    },
    {
      id: "37",
      name: "World Cup - Qualification Intercontinental Play-offs",
      country: "World",
      isSelected: true,
    },
    {
      id: "6",
      name: "Africa Cup of Nations",
      country: "World",
      isSelected: true,
    },
    {
      id: "9",
      name: "Copa America",
      country: "World",
      isSelected: true,
    },
    {
      id: "46",
      name: "EFL Trophy",
      country: "England",
      isSelected: true,
    },
    {
      id: "45",
      name: "FA Cup",
      country: "England",
      isSelected: true,
    },
    {
      id: "47",
      name: "FA Trophy",
      country: "England",
      isSelected: true,
    },
    {
      id: "48",
      name: "League Cup",
      country: "England",
      isSelected: true,
    },
    {
      id: "529",
      name: "Super Cup",
      country: "Germany",
      isSelected: true,
    },
    {
      id: "66",
      name: "Coupe de France",
      country: "France",
      isSelected: true,
    },
    {
      id: "65",
      name: "Coupe de la Ligue",
      country: "France",
      isSelected: true,
    },
    {
      id: "547",
      name: "Super Cup",
      country: "Italy",
      isSelected: true,
    },
    {
      id: "137",
      name: "Coppa Italia",
      country: "Italy",
      isSelected: true,
    },
    {
      id: "257",
      name: "US Open Cup",
      country: "USA",
      isSelected: true,
    },
    {
      id: "335",
      name: "Cup",
      country: "Ukraine",
      isSelected: true,
    },
    {
      id: "206",
      name: "Cup",
      country: "Turkey",
      isSelected: true,
    },
    {
      id: "551",
      name: "Super Cup",
      country: "Turkey",
      isSelected: true,
    },
    {
      id: "10",
      name: "Friendlies",
      country: "World",
      isSelected: false,
    },
    {
      id: "667",
      name: "Friendlies Clubs",
      country: "World",
      isSelected: false,
    },
    {
      id: "71",
      name: "Serie A",
      country: "Brazil",
      isSelected: false,
    },
    {
      id: "62",
      name: "Ligue 2",
      country: "France",
      isSelected: true,
    },
    {
      id: "199",
      name: "Cup",
      country: "Greece",
      isSelected: true,
    },
    {
      id: "197",
      name: "Super League",
      country: "Greece",
      isSelected: true,
    },
    {
      id: "79",
      name: "Bundesliga 2",
      country: "Germany",
      isSelected: true,
    },
    {
      id: "81",
      name: "DFB Pokal",
      country: "Germany",
      isSelected: true,
    },
    {
      id: "273",
      name: "Magyar Kupa",
      country: "Hungary",
      isSelected: true,
    },
    {
      id: "41",
      name: "League One",
      country: "England",
      isSelected: true,
    },
    {
      id: "244",
      name: "Veikkausliiga",
      country: "Finland",
      isSelected: true,
    },
    {
      id: "246",
      name: "Suomen Cup",
      country: "Finland",
      isSelected: true,
    },
    {
      id: "526",
      name: "Trophée des Champions",
      country: "France",
      isSelected: true,
    },
    {
      id: "116",
      name: "Vysshaya Liga",
      country: "Belarus",
      isSelected: true,
    },
    {
      id: "121",
      name: "DBU Pokalen",
      country: "Denmark",
      isSelected: true,
    },
    {
      id: "528",
      name: "Community Shield",
      country: "England",
      isSelected: true,
    },
    {
      id: "19",
      name: "African Nations Championship",
      country: "World",
      isSelected: true,
    },
    {
      id: "519",
      name: "Super Cup",
      country: "Belgium",
      isSelected: true,
    },
    {
      id: "128",
      name: "Primera Division",
      country: "Argentina",
      isSelected: true,
    },
    {
      id: "22",
      name: "CONCACAF Gold Cup",
      country: "World",
      isSelected: true,
    },
    {
      id: "497",
      name: "Japan Football League",
      country: "Japan",
      isSelected: false,
    },
    {
      id: "141",
      name: "Segunda Division",
      country: "Spain",
      isSelected: true,
    },
    {
      id: "136",
      name: "Serie B",
      country: "Italy",
      isSelected: true,
    },
    {
      id: "103",
      name: "Eliteserien",
      country: "Norway",
      isSelected: true,
    },
    {
      id: "113",
      name: "Allsvenskan",
      country: "Sweden",
      isSelected: true,
    },
    {
      id: "169",
      name: "Super League",
      country: "China",
      isSelected: false,
    },
    {
      id: "207",
      name: "Super League",
      country: "Switzerland",
      isSelected: true,
    },
    {
      id: "210",
      name: "Prva HNL",
      country: "Croatia",
      isSelected: true,
    },
    {
      id: "73",
      name: "Copa Do Brasil",
      country: "Brazil",
      isSelected: true,
    },
    {
      id: "90",
      name: "KNVB Beker",
      country: "Netherlands",
      isSelected: true,
    },
    {
      id: "667",
      name: "Super Liga",
      country: "World",
      isSelected: true,
    },
    {
      id: "286",
      name: "Friendlies Clubs",
      country: "World",
      isSelected: false,
    },
    {
      id: "531",
      name: "UEFA Super Cup",
      country: "Serbia",
      isSelected: true,
    },
    {
      id: "543",
      name: "Super Cup",
      country: "Netherlands",
      isSelected: true,
    },
    {
      id: "550",
      name: "Super Cup",
      country: "Portugal",
      isSelected: true,
    },
    {
      id: "556",
      name: "Super Cup",
      country: "Spain",
      isSelected: true,
    },
    {
      id: "96",
      name: "Taça de Portugal",
      country: "Portugal",
      isSelected: true,
    },
    {
      id: "490",
      name: "World Cup - U20",
      country: "World",
      isSelected: true,
    },
    {
      id: "143",
      name: "Copa del Rey",
      country: "Spain",
      isSelected: true,
    },
    {
      id: "212",
      name: "Cup",
      country: "Croatia",
      isSelected: true,
    },
    {
      id: "32",
      name: "World Cup - Qualification Europe",
      country: "World",
      isSelected: true,
    },
    {
      id: "34",
      name: "World Cup - Qualification South America",
      country: "World",
      isSelected: true,
    },
    {
      id: "332",
      name: "Super Liga",
      country: "Slovakia",
      isSelected: true,
    },
    {
      id: "345",
      name: "Czech Liga",
      country: "Czech-Republic",
      isSelected: true,
    },
    {
      id: "147",
      name: "Cup",
      country: "Belgium",
      isSelected: true,
    },
    {
      id: "97",
      name: "Taça da Liga",
      country: "Portugal",
      isSelected: true,
    },
    {
      id: "727",
      name: "II Liga - East",
      country: "Poland",
      isSelected: true,
    },
    {
      id: "732",
      name: "Cup",
      country: "Serbia",
      isSelected: true,
    },
    {
      id: "848",
      name: "UEFA Europa Conference League",
      country: "World",
      isSelected: true,
    },
    {
      id: "960",
      name: "Euro Championship - Qualification",
      country: "World",
      isSelected: true,
    },
  ];

  constructor(
    private modal: NgbModal,
    public calendarService: CalendarService,
    private basicService: BasicService,
    public spinnerService: SpinnerService,
    public dialog: MatDialog
  ) {}

  contains(val, arr) {
    return arr.indexOf(val) >= 0;
  }

  async ngOnInit() {
    await this.reloadCalendar();
  }

  async reloadCalendar() {
    this.spinnerService.show();
    const calendarFixtures = await Promise.all(
      this.leaguesList
        .filter((l) => l.isSelected)
        .map((l) => this.calendarService.getLeague(l.id))
    ).then((allLeagues) => {
      const fixtures = allLeagues.reduce(
        (accumulator, value) => accumulator.concat(value),
        []
      );
      let calendarFixtures1: FixtureForCalendar[] = [];
      for (let fixture of fixtures) {
        let calendarFixture = new FixtureForCalendar();
        calendarFixture.id = fixture.id;
        calendarFixture.type = "Fixture";
        calendarFixture.start = calendarFixture.startDateUtc = fromUnixTime(
          fixture.startingAtUnixTimestampInMilliseconds / 1000
        );
        calendarFixture.end = calendarFixture.endDateUtc = fromUnixTime(
          6600 + fixture.startingAtUnixTimestampInMilliseconds / 1000
        );
        calendarFixture.status = fixture.status;
        calendarFixture.leagueId = fixture.leagueId;
        calendarFixture.leagueName = this.leaguesList.find(
          (l) => l.id == fixture.leagueId
        ).name;
        calendarFixture.leagueCountry = this.leaguesList.find(
          (l) => l.id == fixture.leagueId
        ).country;
        if (this.contains(fixture.status, ["NS"])) {
          calendarFixture.color = colors.blue();
        } else if (this.contains(fixture.status, ["TBD"])) {
          calendarFixture.color = colors.violet();
        } else if (
          this.contains(fixture.status, ["1H", "HT", "2H", "ET", "P", "BT"])
        ) {
          calendarFixture.color = colors.green();
        } else if (
          this.contains(fixture.status, ["FT", "AET", "PEN", "AWD", "WO"])
        ) {
          calendarFixture.color = colors.gray();
        } else if (
          this.contains(fixture.status, ["SUSP", "INT", "PST", "CANC", "ABD"])
        ) {
          calendarFixture.color = colors.black();
        }

        if (this.contains(fixture.status, ["FT", "AET", "PEN", "AWD", "WO"])) {
          calendarFixture.titleShort =
            fixture.localTeamName + " - " + fixture.visitorTeamName;
          calendarFixture.title =
            fixture.localTeamName +
            " - " +
            fixture.visitorTeamName +
            ` (${this.leaguesList.find((l) => l.id == fixture.leagueId).name}/${
              this.leaguesList.find((l) => l.id == fixture.leagueId).country
            })` +
            " " +
            getHours(
              fromUnixTime(fixture.startingAtUnixTimestampInMilliseconds / 1000)
            ) +
            ":" +
            (
              "" +
              getMinutes(
                fromUnixTime(
                  fixture.startingAtUnixTimestampInMilliseconds / 1000
                )
              )
            ).padStart(2, "0");
        } else if (fixture.status == "NS") {
          calendarFixture.titleShort =
            fixture.localTeamName + " - " + fixture.visitorTeamName;
          calendarFixture.title =
            fixture.localTeamName +
            " - " +
            fixture.visitorTeamName +
            ` (${this.leaguesList.find((l) => l.id == fixture.leagueId).name}/${
              this.leaguesList.find((l) => l.id == fixture.leagueId).country
            })` +
            " " +
            getHours(
              fromUnixTime(fixture.startingAtUnixTimestampInMilliseconds / 1000)
            ) +
            ":" +
            (
              "" +
              getMinutes(
                fromUnixTime(
                  fixture.startingAtUnixTimestampInMilliseconds / 1000
                )
              )
            ).padStart(2, "0");
        } else {
          calendarFixture.titleShort =
            fixture.localTeamName + " - " + fixture.visitorTeamName;
          calendarFixture.title =
            fixture.localTeamName +
            " - " +
            fixture.visitorTeamName +
            ` [${fixture.status}]` +
            ` (${this.leaguesList.find((l) => l.id == fixture.leagueId).name}/${
              this.leaguesList.find((l) => l.id == fixture.leagueId).country
            })` +
            " " +
            getHours(
              fromUnixTime(fixture.startingAtUnixTimestampInMilliseconds / 1000)
            ) +
            ":" +
            (
              "" +
              getMinutes(
                fromUnixTime(
                  fixture.startingAtUnixTimestampInMilliseconds / 1000
                )
              )
            ).padStart(2, "0");
        } // how to sort in calendar by hour?
        calendarFixture.draggable = false;
        calendarFixtures1.push(calendarFixture);
      }
      this.showMenu = false;
      return calendarFixtures1;
    });
    const selected = this.leaguesList.filter((l) => l.isSelected);
    if (selected.length < 21) {
      const now = new Date().getTime();
      const fixturesWithOddsFlag = (
        (await this.calendarService.checkFixtureOdds(
          calendarFixtures
            .map((event) => ({
              startDateUtc: event.startDateUtc,
              id: event.id,
              leagueId: event.leagueId,
            }))
            .filter((x) => new Date(x.startDateUtc).getTime() > now)
        )) as any
      ).fixturesInfo;
      calendarFixtures
        .filter((f) =>
          fixturesWithOddsFlag.some(
            (fix) => fix.fixtureId === f.id && !fix.hasAnyOdds
          )
        )
        .forEach((f) => {
          f.color = colors.brown();
        });
    }

    this.allEvents = calendarFixtures;
    this.currentDateView = new Date();
    this.refreshEventsForDate(this.currentDateView, 6);
    this.spinnerService.hide();
  }

  refreshEventsForDate(dateTime: Date, dayMargin: number) {
    const date = new Date(
      dateTime.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate()
    );
    let firstDayofThisMonth = addDays(date, -getDate(date) + 1);
    let firstDayOfNextMonth = addMonths(firstDayofThisMonth, 1);
    let from = addDays(firstDayofThisMonth, -dayMargin);

    let to = addDays(firstDayOfNextMonth, dayMargin);
    console.log(`refreshing events from: ${from} to: ${to}`);
    this.events = this.allEvents.filter(
      (fixture) =>
        (fixture.start >= from && fixture.end < to) ||
        (fixture.start < from && fixture.end > from) ||
        (fixture.start < to && fixture.end > to)
    );
    this.refreshCalendar.next();
  }

  refreshEventsForNextView() {
    if (this.view === CalendarView.Month) {
      this.currentDateView = addMonths(this.currentDateView, 1);
      this.refreshEventsForDate(this.currentDateView, 6);
    } else if (this.view === CalendarView.Week) {
      this.currentDateView = addWeeks(this.currentDateView, 1);
      this.refreshEventsForDate(this.currentDateView, 6);
    } else if (this.view === CalendarView.Day) {
      this.currentDateView = addDays(this.currentDateView, 1);
      this.refreshEventsForDate(this.currentDateView, 6);
    }
  }

  refreshEventsForPreviousView() {
    if (this.view === CalendarView.Month) {
      this.currentDateView = addMonths(this.currentDateView, -1);
      this.refreshEventsForDate(this.currentDateView, 6);
    } else if (this.view === CalendarView.Week) {
      this.currentDateView = addWeeks(this.currentDateView, -1);
      this.refreshEventsForDate(this.currentDateView, 6);
    } else if (this.view === CalendarView.Day) {
      this.currentDateView = addDays(this.currentDateView, -1);
      this.refreshEventsForDate(this.currentDateView, 6);
    }
  }

  refreshEventsForTodayView() {
    this.currentDateView = new Date();
    this.refreshEventsForDate(this.currentDateView, 6);
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: FixtureForCalendar[];
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  getTournamentMetaevents(): FixtureForCalendar[] {
    let tournamentMetaevents = this.events.filter(
      (event) => event.type != "Fixture"
    );
    return tournamentMetaevents;
  }

  async addTurnMetaData(turn: TurnMetadata) {
    let inRangeFixtures = this.events.filter(
      (fixture) =>
        fixture.type == "Fixture" &&
        fixture.start > turn.startDateUtc &&
        fixture.start < turn.endDateUtc
    );
    let fixtureMetadata: FixtureMetadata[] = [];
    for (let fix of inRangeFixtures) {
      let fixtureData = new FixtureMetadata();
      fixtureData.id = fix.id;
      if (fix.status == "NS") {
        fixtureData.isSelected = fix.isSelected;
      }
      fixtureData.title = fix.titleShort;
      fixtureData.startDateUtc = fix.start;
      fixtureData.leagueName = fix.leagueName;
      fixtureData.leagueId = fix.leagueId;
      fixtureData.leagueCountry = fix.leagueCountry;
      fixtureMetadata.push(fixtureData);
    }
    await this.calendarService.setSelectedTurn(turn, fixtureMetadata);
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return <FixtureForCalendar>{
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.allEvents = this.events;
    this.showEventDetails("Dropped or resized", event);
  }

  showEventDetails(action: string, calendarEvent: CalendarEvent): void {
    //named "handleEvent" before
    const fixtureEvent = this.allEvents.find(
      (event) => calendarEvent.id == event.id
    );
    this.modalData = { event: fixtureEvent, action: action };
    this.modal.open(this.eventDetails, { size: "lg" });
  }

  showCreateTournamentWindow(): void {
    this.modal.open(this.createTournament, {
      size: "lg",
      windowClass: "custom-class",
    });
  }

  showCreateTurnWindow(): void {
    this.modal.open(this.createTurn, { size: "lg" });
  }

  addTurn(startDate: Date, endDate: Date, title: string): void {
    let t = new TurnMetadata();
    t.id = Guid.raw();
    t.title = title;
    t.startDateUtc = startOfDay(startDate);
    t.endDateUtc = endOfDay(endDate);
    t.verificationStatus = "unverified";
    this.turns.push(t);
    this.allEvents = [
      ...this.allEvents,
      <FixtureForCalendar>{
        id: t.id,
        title: t.title,
        titleShort: t.title,
        start: t.startDateUtc,
        end: t.endDateUtc,
        verificationStatus: t.verificationStatus,
        type: "Turn",
        actions: this.actions,
        color: colors.yellow(),
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
    this.events = this.allEvents;
  }

  addTournament(
    startDate: Date,
    endDate: Date,
    openDate: Date,
    closeDate: Date,
    registrationCloseMargin: number,
    betsOpenMargin: number,
    betsCloseMargin: number
  ): void {
    this.calendarService.createTournament(
      startDate,
      endDate,
      openDate,
      closeDate,
      registrationCloseMargin,
      betsOpenMargin,
      betsCloseMargin,
      Guid.raw()
    );
    let timeDifference =
      new Date(startOfDay(startDate)).getTime() -
      new Date(endOfDay(endDate)).getTime();
    let days = Math.round(Math.abs(timeDifference / (1000 * 60 * 60 * 24)));
    this.allEvents = [
      ...this.allEvents,
      <FixtureForCalendar>{
        id: Guid.raw(),
        titleShort: "New tournament",
        title: "New tournament",
        start: startOfDay(startDate),
        displayDate: startDate,
        end: endOfDay(endDate),
        type: "Fixture",
        actions: this.actions,
        price: 100,
        color: colors.red(),
        draggable: true,
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
    let metaTurns: TurnMetadata[] = [];
    let turns: FixtureForCalendar[] = [];
    for (let i = 0; i < days; i++) {
      let newTurn = new FixtureForCalendar();
      let newMetadata = new TurnMetadata();
      newTurn.id = newMetadata.id = Guid.raw();
      newTurn.titleShort = newTurn.title = newMetadata.title = `Turn ${i + 1}`;
      newTurn.type = "Turn";
      (newTurn.verificationStatus = "unverified"),
        (newTurn.actions = this.actions),
        (newTurn.start = newMetadata.startDateUtc =
          startOfDay(addDays(startDate, i)));
      newTurn.end = newMetadata.endDateUtc = endOfDay(newTurn.start);
      newTurn.color = colors.yellow();
      newTurn.draggable = true;
      newTurn.resizable = {
        beforeStart: true,
        afterEnd: true,
      };
      turns.push(newTurn);
      metaTurns.push(newMetadata);
    }
    this.allEvents.push(...turns);
    this.events = this.allEvents;
    this.turns.push(...metaTurns);
  }

  deleteEvent(eventToDelete: TurnMetadata) {
    this.calendarService.tournamentMetadata.turns =
      this.calendarService.tournamentMetadata.turns.filter(
        (event) => event !== eventToDelete
      );
  }

  deleteEventFromView(eventToDelete: FixtureForCalendar) {
    this.allEvents = this.allEvents.filter((event) => event !== eventToDelete);
    this.events = this.allEvents;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  async turnSetupOpen(turn: FixtureForCalendar) {
    let openedTurn = this.turns.find((openedTurn) => openedTurn.id == turn.id);
    console.log("openedTurn");
    await this.addTurnMetaData(openedTurn);
    this.dialog.open(TurnSetupComponent);
  }

  tournamentSetupOpen(): void {
    const dialogRef = this.dialog.open(TournamentSetupComponent, {});

    dialogRef.afterClosed().subscribe((_) => this.TournamentSetupClosed.emit());
  }

  setAllLeagues(value: boolean) {
    this.leaguesList.forEach((l) => (l.isSelected = value));
  }
}
