import { CalendarService } from "../common/calendar.service";
import { TournamentMetadata } from "../../../modules/common/basic.service";
import { FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../environments/environment";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Component({
  selector: "app-tournament-setup",
  templateUrl: "./tournament-setup.component.html",
  styleUrls: ["./tournament-setup.component.scss"],
})
export class TournamentSetupComponent implements OnInit {
  tournamentMetadata: TournamentMetadata;
  tournamentSubmit;

  constructor(
    private calendarService: CalendarService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public dialogRef: MatDialogRef<TournamentSetupComponent>,
    private _snackBar: MatSnackBar,
    private apiUrlService: ApiUrlService
  ) {
    this.tournamentSubmit = this.formBuilder.group({
      Name: [this.tournamentMetadata, Validators.required],
      registrationOpenDateUtc: [this.tournamentMetadata, Validators.required],
      registrationCloseMarginInMinutes: [
        this.tournamentMetadata,
        Validators.required,
      ],
      betsOpenMarginInMinutes: [this.tournamentMetadata, Validators.required],
      betsCloseMarginInMinutes: [this.tournamentMetadata, Validators.required],
      entryFee: [this.tournamentMetadata, Validators.required],
      Period: [this.tournamentMetadata, Validators.required],
      Type: [this.tournamentMetadata, Validators.required],
      Description: [this.tournamentMetadata, Validators.required],
      LeaderboardBonus: [this.tournamentMetadata, Validators.required],
      LeaderboardId: [this.tournamentMetadata, Validators.required],
    });
  }

  ngOnInit(): void {
    this.tournamentMetadata = this.calendarService.returnTournamentMetadata();
  }

  putTournament(
    tournamentMetadata: TournamentMetadata
  ): Observable<TournamentMetadata> {
    return this.http.put<TournamentMetadata>(
      environment[this.apiUrlService.env] +
        `api/tournaments/put/${tournamentMetadata.id}`,
      tournamentMetadata
    );
  } //work in progress

  onSubmit() {
    console.warn("Your tournament has been submitted", this.tournamentMetadata);
    this.calendarService.postTournament({
      ...this.tournamentMetadata,
      leaderboardIds: [this.tournamentMetadata.LeaderboardId],
      LeaderboardId: undefined,
    });
    this.putTournament({
      ...this.tournamentMetadata,
      leaderboardIds: [this.tournamentMetadata.LeaderboardId],
      LeaderboardId: undefined,
    }).subscribe(
      (_) => {
        this.tournamentMetadata = null;
        this.calendarService.eraseTournament();
        this.tournamentSubmit.reset();
      },
      (err) => {
        console.error(err);
      }
    );
    this.onNoClick();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar() {
    this._snackBar.open(
      "Your tournament has been succesfully submitted!",
      "Close",
      {
        duration: 2000,
      }
    );
  }
}
