import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "../../../infrastructure/network/spinner.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Component({
  selector: "app-cancel-fixture",
  templateUrl: "./cancel-fixture.component.html",
  styleUrls: ["./cancel-fixture.component.css"],
})
export class CancelFixtureComponent implements OnInit {
  cancelFixtureId: string;
  cancelFixture: any;

  constructor(
    public spinnerService: SpinnerService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private apiUrlService: ApiUrlService
  ) {
    this.cancelFixture = this.formBuilder.group({
      cancelFixtureId: [this.cancelFixture, Validators.required],
    });
  }

  async postCancelledFixture(cancelFixture: string): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] +
          `api/tournaments/cancel-fixture/${cancelFixture}`,
        null
      )
      .toPromise();
  }

  ngOnInit(): void {}

  async cancelFixtureSubmit() {
    await this.postCancelledFixture(this.cancelFixtureId).then(
      (_) => {
        this.cancelFixture = null;
        this.cancelFixtureId = null;
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
