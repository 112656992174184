<div class="card">
    <h4 class="card-header">Change Password</h4>
    <div class="card-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="login">Login</label>
                <input oninput="this.value = this.value.toUpperCase()" type="text" formControlName="login" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.login.errors }" />
                <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                    <div *ngIf="f.login.errors.required">Old password is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="newPassword">New Password</label>
                <input type="newPassword" formControlName="newPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
                <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.newPassword.errors.required"> New password is required</div>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Change Password
                </button>
                <button [routerLink]="['']" class="btn btn-primary">
                    Login
                </button>
            </div>
        </form>
    </div>
</div>