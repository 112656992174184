<app-top-bar-tournament></app-top-bar-tournament>

<div style="height: 55px; width: 100%; background: #6200EE; color: white;">   
  <div style="text-align: center; display: flex; align-items: center"> 
    <div style="width: 10vw; padding-top: 10px;">
      <mat-icon style="font-size: 32px;  -webkit-text-fill-color:white;" 
      [routerLink]="['/oddsee/tournaments/',tournamentMetadata.id]" 
      [state]="{tournamentHeader: tournamentMetadata}">
        chevron_left
      </mat-icon>
    </div>  
    <div style="width: 10vw; font-size: 10px; padding-top: 5px;"> 
      MBpC: {{ turn.maxBets }}
    </div>
    <div style="width: 50vw; margin-left: 5vw ">
      <div style="font-size: 20px; padding-top: 5px;"> 
        Fixtures from {{ turn.title }}
      </div>
    </div>
    <div style="font-size: 10px; padding-top: 5px; position: absolute; right: 5px; top: 82px; margin-right: 5px;"> 
      CS: {{ couponService.turnWallet }}
    </div>
  </div>
</div>


<mat-progress-spinner *ngIf="spinnerService.visibility | async" style="margin-top: 30vh; margin-left: auto; margin-right: auto; align-items: center; text-align: center;" [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>


<button class="mine" mat-fab color="basic"
  [hidden]="!couponService.displayCoupon" matBadge="{{couponService.couponSelectedOdds.length}}"
  [matBadgeHidden]="!couponService.couponSelectedOdds.length" matBadgePosition="after" matBadgeColor="primary" 
  (click)="openDialog(turn); couponService.hideCoupon()">
    <i class="material-icons" style="color:#222222;">receipt</i>
  </button>

<mat-nav-list style="padding-top: 0px;" *ngFor="let fixture of fixtures"
[routerLink]="['/oddsee/tournaments', tournamentMetadata.id, 'turn', turn.id, 'odds' , fixture.localTeamName + fixture.visitorTeamName]" 
[state]="{tournamentMetadata: tournamentMetadata, turn: turn, fixture: fixture}">
  <mat-list-item >
    <div style="align-items: center; width: 100%;">  
      <div style="margin-left: auto; margin-right: auto; align-items: center; text-align: center;">
        <div class="fixtureLabel" style="font-weight: 700;">
          {{ fixture.localTeamName }} - {{ fixture.visitorTeamName }} 
        </div>
        <div class="fixtureTime">
          Starting at: {{ fixture.startingAtUnixTimestampInMilliseconds | date:'HH:mm dd-MM-yyyy' }}
        </div>
      </div>  
    </div>
    <mat-divider  [inset]="true" *ngIf="!last"></mat-divider>   
  </mat-list-item> 
</mat-nav-list>