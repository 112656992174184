<app-top-bar-tournament></app-top-bar-tournament>

<mat-card style="margin-bottom: 2vh; max-width: 400px; margin-left: auto; margin-right: auto;">
    <mat-card-title style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
        Cancel fixture
    </mat-card-title>
    <form [formGroup]="cancelFixture" (ngSubmit)="cancelFixtureSubmit();" style="text-align: center;">
    
        <div> 
            <!-- <div style="font-weight: bold;">Withdraw amount</div>  -->
            <input 
            style="width: 100px"
            matInput
            type="string"
            formControlName="cancelFixtureId"  
            [(ngModel)]="cancelFixtureId"
            >
        </div> 

        <button mat-raised-button [disabled]="!cancelFixtureId" style="margin-bottom: 10px; margin-top: 10px;" color="success" type="submit" >
            Cancel fixture
        </button>

    </form>
</mat-card>
