<app-top-bar-tournament></app-top-bar-tournament>



<div style="height: 55px; width: 100%; background: #6200EE; color: white;">   
   
  <div style="text-align: center; display: flex; align-items: center"> 
    <div style="width: 10vw; padding-top: 10px;">
      <mat-icon style="font-size: 32px;  -webkit-text-fill-color:white;" 
      [routerLink]="['/oddsee/tournaments/']">
        chevron_left
      </mat-icon>
    </div>  
    <div style="margin-left: 20vw; width: 40vw">
      <div style="font-size: 20px; padding-top: 5px;"> 
        Turns
      </div>
    </div>
    <div style="width: 10vw;">
      <button class="button fancy-button" 
      [routerLink]="['/oddsee/tournaments', tournamentData?.id, 'table']" 
      [state]="{tournamentMetadata: tournamentData}" 
      style="margin-right: 5vw; height: 40px; text-align: center; margin-top: 5px; position: absolute; right: 3px; top: 70px; margin-right: 3px;" >
        Table
      </button>
    </div>
  </div>
</div>

<mat-progress-spinner *ngIf="spinnerService.visibility | async" style="margin-top: 30vh; margin-left: auto; margin-right: auto; align-items: center; text-align: center;" [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>

<mat-nav-list style="padding-top: 0px;" *ngFor="let turn of tournamentData?.turns" >
    <div *ngIf="turn.status !== 'open' ; else elseBlock"> 
      <mat-list-item [disabled]="turn.status !== 'open'" style="height: 175px;">
        <div style="display: flex; align-items: center; width: 100%;">             
          <div style="margin-left: auto; margin-right: auto; align-items: center; text-align: center;">
            <div *ngIf="turn.couponsCount; else elseBlock" >           
              <div style="justify-content: center; align-items: center;">
                <div *ngIf="turn.startingStack - turn.betsSum == 0; else elseBlock1" >
                  <div class="fixtureLabel" style="font-size: 20px; font-weight: 700;">{{ turn.title }}</div> 
                  <div style="color: green; font-weight: 700;">Whole stack has been submitted!</div>
                </div>       
                <ng-template #elseBlock1>
                  <div *ngIf="turn.couponsCount >= turn.maxCoupons; else elseBlock2">
                    <div class="fixtureLabel" style="font-size: 20px; font-weight: 700;">{{ turn.title }}</div> 
                    <div *ngIf="turn.startingStack > turn.betsSum; else elseBlock3" >
                      <div>
                        <span style="font-weight: 700;">Maximum number of coupons reached!</span>  Remaining stack:
                        <span style="font-weight: 700;">
                          {{ turn.startingStack - turn.betsSum }}
                        </span>
                      </div>
                    </div>
                    <ng-template #elseBlock3>
                      <div>
                        <span style="color: green; font-weight: 700;">
                          Maximum number of coupons reached!
                        </span>
                      </div>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template #elseBlock2>
                  <div>
                    <div class="fixtureLabel" style="font-size: 20px; font-weight: 700;">{{ turn.title }} </div> 
                    Remaining stack: <span style="font-weight: 700;">{{ turn.startingStack - turn.betsSum }}</span>
                  </div>
                </ng-template>
              </div>
              <div>
                Posted coupons: {{ turn.couponsCount }}/{{ turn.maxCoupons }}
              </div>
            </div>    
            <ng-template #elseBlock style="display: flex; justify-content: center; align-items: center;">
              <div class="fixtureLabel" style="font-size: 20px; font-weight: 700;">{{ turn.title }}</div>
              Starting stack: <span style="font-weight: 700;">{{ turn.startingStack }}</span> 
              <div>
                Max coupons: {{ turn.maxCoupons }}
              </div>
            </ng-template>
              <div *ngIf='turn.status == "notYetStarted"'>
                <div style= "display: flex; justify-content: center; align-items: center; text-align: center; font-size: 11px;">
                  <div style="margin-right: 0.5vw;">
                      Max Odds: 
                      <span style="font-weight: 700;">
                        {{ turn.maxBetOdds }}
                      </span>
                  </div>
                  <div>
                    Max Combined Odds per Coupon: 
                    <span style="font-weight: 700;">
                      {{ turn.maxCombinedOdds }}
                    </span>
                  </div>
                </div>
              </div>  
              <div class="fixtureTime" style="display: flex; justify-content: center; align-items: center; text-align: center;">
                <div *ngIf="now > turn.betsCloseDateUtc ; else elseBlock6">
                  <span style="font-weight: 700;margin-right: 0.5vw;">End Date: </span> {{ turn.betsCloseDateUtc | date:'HH:mm dd-MM-yyyy' }}
                </div>
                <ng-template #elseBlock6>
                  <div *ngIf="turn.secondsToStart > 86400 ; else elseBlock7">
                    <span style="font-weight: 700;margin-right: 0.5vw;">Start Date: </span> {{ turn.betsOpenDateUtc | date:'HH:mm dd-MM-yyyy' }}
                  </div>
                  <ng-template #elseBlock7>
                    <div *ngIf="turn.status != 'open'">
                      <span style="font-weight: 600;margin-right: 0.5vw;"> Turn starts in: </span> <countdown [config]="{leftTime: turn.secondsToStart}"></countdown>
                      <span style="margin-left: 0.5vw;font-weight: 700;margin-right: 0.5vw;">Start Date: </span> {{ turn.betsOpenDateUtc | date:'HH:mm dd-MM-yyyy' }}
                    </div>
                  </ng-template>           
                </ng-template>
              </div>
          </div>    
        </div>
        <mat-divider  [inset]="true" *ngIf="!last"></mat-divider>   
      </mat-list-item> 
    </div>
    <ng-template #elseBlock>
      <mat-list-item style="height: 175px;"
      [routerLink]="['/oddsee/tournaments', tournamentData.id, 'turn', turn.id]" 
      (click) ="couponService.allignTurnOdds(tournamentData.id, turn.id); couponService.recalcTurnWallet(turn.startingStack, turn.betsSum); couponService.showCoupon()"
      [state]="{tournamentMetadata: tournamentData, turn: turn, turnWallet: couponService.turnWallet}">
        <div style="display: flex; align-items: center; width: 100%;">    
          <div style="margin-left: auto; margin-right: auto; align-items: center; text-align: center;">
            <div *ngIf="turn.couponsCount; else elseBlock" >
              <div style="justify-content: center; align-items: center;">
                <div *ngIf="turn.startingStack - turn.betsSum == 0; else elseBlock1" >
                  <div class="fixtureLabel" style="font-size: 20px; font-weight: 700;">{{ turn.title }}</div> 
                  <div style="color: green; font-weight: 700;">Whole stack has been submitted!</div>
                </div> 
                <ng-template #elseBlock1>
                  <div>
                    <div class="fixtureLabel" style="font-size: 20px; font-weight: 700;">{{ turn.title }}</div> 
                    Remaining stack: <span style="font-weight: 700;">{{ turn.startingStack - turn.betsSum }}</span>
                  </div>
                </ng-template>
              </div>
              <div>
                Posted coupons: {{ turn.couponsCount }}/{{ turn.maxCoupons }}
              </div>
            </div>    
            <ng-template #elseBlock style="display: flex; justify-content: center; align-items: center;">
              <div class="fixtureLabel" style="font-size: 20px; font-weight: 700;">{{ turn.title }}</div> 
              Starting stack: <span style="font-weight: 700;">{{ turn.startingStack }}</span> 
              <div>
                Max coupons: {{ turn.maxCoupons }}
              </div>
            </ng-template>          
            <div style= "display: flex; justify-content: center; align-items: center; text-align: center; font-size: 11px;">
              <div style="margin-right: 0.5vw;">
                  Max Odds: 
                  <span style="font-weight: 700;">
                    {{ turn.maxBetOdds }}
                  </span>
              </div>
              <div>
                Max Combined Odds per Coupon: 
                <span style="font-weight: 700;">
                  {{ turn.maxCombinedOdds }}
                </span>
              </div>
            </div> 
            <div *ngIf="turn.secondsToEnd > 86400; else elseBlock5">
              <div class="fixtureTime" style="display: flex; justify-content: center; align-items: center; text-align: center;">
                <!-- <span style="font-weight: 700; margin-right: 0.5vw;">Start Date: </span>{{ turn.betsOpenDateUtc | date:'HH:mm dd-MM-yyyy' }}  -->
                <span style="font-weight: 700;margin-left: 0.5vw;margin-right: 0.5vw;">End Date: </span> {{ turn.betsCloseDateUtc | date:'HH:mm dd-MM-yyyy' }}
              </div>
            </div>
              <ng-template #elseBlock5>
                <div class="fixtureTime" style="display: flex; justify-content: center; align-items: center; text-align: center;">
                  <!-- <span style="font-weight: 700; margin-right: 0.5vw;">Start Date: </span>{{ turn.betsOpenDateUtc | date:'HH:mm dd-MM-yyyy' }}  -->
                  <span style="font-weight: 600;margin-right: 0.5vw;">Turn ends in: </span> <countdown [config]="{leftTime: turn.secondsToEnd}"></countdown>
                  <span style="font-weight: 700;margin-left: 0.5vw;margin-right: 0.5vw;">End Date: </span> {{ turn.betsCloseDateUtc | date:'HH:mm dd-MM-yyyy' }}
                </div>
              </ng-template>
          </div>       
        </div>
        <mat-divider  [inset]="true" *ngIf="!last"></mat-divider>   
      </mat-list-item> 
    </ng-template>
</mat-nav-list>

