import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../common/account.service';
import { AlertService } from '../common/alert.service';
import { ConfigService } from '../../../infrastructure/config.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private configService: ConfigService
    ) {
        // redirect to home if already logged in
        // if (this.accountService.isUserLoggedIn()) {
        //     this.router.navigate(['oddsee/tournaments']);
        // }
    }

    // async ngOnInit() {
    //     await this.configService.init();
    //   }

    ngOnInit() {
        this.form = this.formBuilder.group({
            login: ['', Validators.required],
            email: ['', Validators.required],
            password: ['',
              [
                Validators.required, 
                Validators.minLength(8), 
                Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9  !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~€£¥₩])(?=.*?[A-Z 0-9]).{8,}$"), 
              ]
            ]
        });

        this.returnUrl = this.route.snapshot.queryParams[''] || '';
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.accountService.register(this.form.value)
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', { keepAfterRouteChange: true });
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
