import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError, Subject } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import {
  AccountService,
  LoggedInUser,
} from "../../modules/navigation/common/account.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { ApiUrlService } from "../menu/top-bar/top-bar.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorInputService implements HttpInterceptor {
  refreshTokenInProgress: boolean = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private accountService: AccountService,
    private router: Router,
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService
  ) {}

  addAuthHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const loggedInUser = this.accountService.getLoggedInUser();
    const request2 = request.clone({
      setHeaders: {
        Authorization: `Bearer ${loggedInUser.accessToken}`,
      },
    });

    return request2;
  }

  logout() {
    this.accountService.logout();
    this.router.navigate([""]);
  }

  refreshTokenRequest(): Observable<RefreshTokenResponse> {
    const user = this.accountService.getLoggedInUser();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${user.accessToken}`,
    });
    return this.httpClient.post<RefreshTokenResponse>(
      environment[this.apiUrlService.env] + "api/users/refresh-token",
      { refreshToken: user.refreshToken },
      { headers: headers }
    );
  }

  refreshToken(): Observable<unknown> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.refreshTokenRequest().pipe(
        tap((response: any) => {
          const newLoggedInUser = new LoggedInUser();
          newLoggedInUser.accessToken = response.accessToken;
          newLoggedInUser.refreshToken = response.refreshToken;
          newLoggedInUser.roles = response.roles;
          newLoggedInUser.userName = response.userName;

          this.accountService.reLogin(newLoggedInUser);

          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError((error) => {
          this.refreshTokenInProgress = false;
          this.logout();
          return Observable.throw(error.statusText);
        })
      );
    }
  }

  handleResponseError(
    error: any,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Business error
    if (error.status === 400) {
      // Show message
    }

    // Invalid token error
    else if (!error.url.includes("refresh-token") && error.status === 401) {
      return this.refreshToken().pipe(
        switchMap((aa) => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError((e) => {
          this.logout();
          return Observable.throw(error.statusText);
        })
      );
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      // Logout
      this.logout();
    }

    // Server error
    else if (error.status === 500) {
      // Show message
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
    }

    return throwError(error);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        return this.handleResponseError(error, request, next);
      })
    );
  }
}

class RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
  roles: string[];
  userName: string;
}
