import { Component, OnInit } from '@angular/core';
import { BasicService, TournamentMetadata, TournamentHeader, FixtureFromAPIDto, TurnMetadata, FixtureMetadata } from '../../common/basic.service';
import { SpinnerService } from '../../../infrastructure/network/spinner.service';
import { CouponService } from './../../betting/common/coupon.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CouponComponent } from '../../../modules/betting/coupon/coupon.component';

@Component({
  selector: 'app-tournaments-fixtures',
  templateUrl: './tournaments-fixtures.component.html',
  styleUrls: ['./tournaments-fixtures.component.scss']
})
export class TournamentsFixturesComponent implements OnInit {
  tournamentMetadata: TournamentMetadata;
  tournamentHeader: TournamentHeader;
  turn: TurnMetadata;
  leagueId: string = "39";
  allFixturesFromAPI: FixtureFromAPIDto[] = [];
  fixtures: FixtureFromAPIDto[];

  constructor(
    private basicService: BasicService,
    public spinnerService: SpinnerService,
    public couponService: CouponService,
    private router: Router,
    public dialog: MatDialog,
  ) { }
  

  getFixtures() { 
    let fixturesFromSelectedTurn: FixtureFromAPIDto[] = [];
    for (let fixture of this.turn.fixtures){
      let fixtureMeta = new FixtureFromAPIDto()
      let fix = this.allFixturesFromAPI.find(fixture1 => fixture1.id == fixture.id);
      if(fixture.isSelected){
        fixtureMeta.id = fix.id;
        fixtureMeta.localTeamName = fix.localTeamName;
        fixtureMeta.name = fix.localTeamName;
        fixtureMeta.season = fix.season;
        fixtureMeta.standingsLocalTeamPosition = fix.standingsLocalTeamPosition;
        fixtureMeta.standingsVisitorTeamPosition = fix.standingsVisitorTeamPosition;
        fixtureMeta.startingAtUnixTimestampInMilliseconds = fix.startingAtUnixTimestampInMilliseconds;
        fixtureMeta.visitorTeamName = fix.visitorTeamName
        fixturesFromSelectedTurn.push(fixtureMeta)
      }
    }
    this.fixtures = fixturesFromSelectedTurn;
  }

  openDialog(turn: TurnMetadata ): void {
    const dialogRef = this.dialog.open(CouponComponent);
    this.couponService.setTurn(turn);
    dialogRef.afterClosed().subscribe(
      _ => {
        this.couponService.showCoupon();
      }
    ); 
  }

  ngOnInit() {
    this.spinnerService.show();
    if(!history.state.tournamentMetadata || !history.state.turn) {
      this.router.navigateByUrl('',{skipLocationChange:true});
      return;
    }
    this.tournamentMetadata = history.state.tournamentMetadata;
    this.turn = history.state.turn;
    this.basicService.getFixturesFromTurn(this.tournamentMetadata.id, this.turn.id).subscribe(fixtures => {
      this.fixtures = fixtures;
      this.spinnerService.hide();
    });   
  } 
  
}
