import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Injectable()
export class OddsService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) {}

  getPagedBetsFromAPI(
    fixtureId: string,
    leagueId: string,
    page: number
  ): Observable<BetFromAPIDto[]> {
    return this.http.get<BetFromAPIDto[]>(
      environment[this.apiUrlService.env] +
        "api/events/league/" +
        leagueId +
        "/fixture/" +
        fixtureId +
        "?page=" +
        page
    );
  }

  getPagedTournamentsBetsFromAPI(
    fixtureId: string,
    leagueId: string,
    page: number,
    tournamentId: string,
    turnId: string
  ): Observable<BetFromAPIDto[]> {
    return this.http.get<BetFromAPIDto[]>(
      environment[this.apiUrlService.env] +
        "api/events/league/" +
        leagueId +
        "/fixture/" +
        fixtureId +
        "?page=" +
        page +
        "&tournamentId=" +
        tournamentId +
        "&turnId=" +
        turnId
    );
  }
}

export class OddFromAPIDto {
  odds: number;
  total: string;
  label: string;
  probabilityLabel: string;
  handicap: string;
}

export class BetFromAPIDto {
  id: string;
  name: string;
  type: string;
  bets: OddFromAPIDto[];
}
