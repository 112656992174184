<div class="card">
    <h4 class="card-header">Register</h4>
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="login">Login</label>
                <input type="text" formControlName="login" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.login.errors }" />
                <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                    <div *ngIf="f.login.errors.required">Login is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="email">E-mail</label>
                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">E-mail is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters</div>
                    <div *ngIf="f.password.errors.pattern">Password must contain at least one upper character and one special sign</div>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Register
                </button>

                <div>
                    <a [routerLink]="['']" class="btn btn-link">Login</a>
                </div>
            </div>
        </form>
    </div>
</div>


