import { Component, OnInit, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CouponComponent } from '../../../modules/betting/coupon/coupon.component';
import { CouponService, SelectedOdd,} from '../../../modules/betting/common/coupon.service';
import { AccountService, LoggedInUser } from '../../../modules/navigation/common/account.service';
import { BasicService } from '../../../modules/common/basic.service';
import { AppComponent } from '../../../app.component/app.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

export interface DialogData {
  selectedOdds;
  betSubmit;
  max;
  min;
  step;
  value;
}

@Component({
  selector: 'app-top-bar-tournament',
  templateUrl: './top-bar-tournament.component.html',
  styleUrls: ['./top-bar-tournament.component.css']
})
export class TopBarTournamentComponent implements OnInit {
  @ViewChild('bugReport', { static: true }) bugReport: TemplateRef<any>;

  get selectedOdds():SelectedOdd[]{
    return this.couponService.selectedOdds
  }
  betSubmit;
  max = 1000;
  min = 0;
  step = 10;
  thumbLabel = true;
  couponValue = 300;
  mainWallet:number;
  loggedInUser: LoggedInUser;

  constructor(
    public couponService: CouponService, 
    public dialog: MatDialog,
    public accountService: AccountService,
    private basicService: BasicService,
    private modal: NgbModal,
    private router: Router,
    private location: Location,
    private http: HttpClient
    ) {    
  };

  @Output()
  couponDialogClosed: EventEmitter<string> = new EventEmitter<string>();


  openDialog(): void {
    const dialogRef = this.dialog.open(CouponComponent, {
      // panelClass: 'my-class',
      // data: {
      //   selectedOdds: this.couponService.selectedOdds,
      //   betSubmit: this.betSubmit,
      //   max: this.max,
      //   min: this.min,
      //   step: this. step,
      //   value: this.couponValue
      // }
    });

    dialogRef.afterClosed().subscribe(couponValue => {
      this.couponValue = couponValue;
      this.couponDialogClosed.emit("test");
      
    });
  }

  reportABug(): void {
    this.modal.open(this.bugReport, { size: 'lg', windowClass: 'custom-class' });
  }

  // private refresh(): void {
  //   this.router.navigateByUrl('',{skipLocationChange:true}).then(() => {
  //     this.router.navigate([decodeURI(this.location.path())]);
  //   });
  // }

  ngOnInit() {
    this.loggedInUser = this.accountService.getLoggedInUser();
    this.mainWallet = this.basicService.getMainWalletValue();
  }

}
