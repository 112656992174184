import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';  
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CouponService, SelectedOdd, CouponToPost, BetToPost } from '../common/coupon.service';
import { FormBuilder, Validators } from '@angular/forms';
import { BasicService, TournamentHeader, TurnMetadata } from '../../common/basic.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from '../../../infrastructure/network/spinner.service';
import { InPlayService, InPlayFromAPIDto } from '../in-play/in-play.service';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})

export class CouponComponent implements OnInit {
  @ViewChild('couponDelete', { static: true }) couponDelete: TemplateRef<any>; 
  @ViewChild('sidenav') sidenav;
  couponSelectedOdds: SelectedOdd[];
  betSubmit;
  rateControl;
  couponToPost: CouponToPost;
  max = this.couponService.turnWallet;
  min = 0;
  step = 10;
  value = this.couponService.turnWallet;
  turn: TurnMetadata;
  coupons: InPlayFromAPIDto[];
  showMenu = false;
  myTournaments: string[];
  myTournamentsData: TournamentHeader[];
  tournaments: TournamentHeader[];
  turnCoupons: InPlayFromAPIDto[];

  removeOddFromCoupon(tournamentId: string, turnId:string, label: string, betName: string, fixtureId: string) {
    this.couponService.deleteBetFromCoupon(tournamentId, turnId, label, betName, fixtureId);
    this.couponSelectedOdds = this.couponService.getCouponSelectedOdds();
    this.createCoupon(this.couponSelectedOdds);
    this.couponService.allignOddsCollections(tournamentId, turnId);
    if(this.couponSelectedOdds.length == 0){
      this.dialogRef.close();
      this.couponService.deleteCoupon(tournamentId, turnId);
    }
  }


  constructor(
    public couponService: CouponService,
    private formBuilder: FormBuilder,
    private basicService: BasicService,
    private router: Router,
    public dialogRef: MatDialogRef<CouponComponent>,
    private _snackBar: MatSnackBar,
    public spinnerService: SpinnerService,
    private inPlayService: InPlayService,
    private modal: NgbModal,
    public dialog: MatDialog,
  ) 
  
  {
    this.betSubmit = this.formBuilder.group({
      Amount:  [this.value, Validators.required], 
      
    });
  }

  modalData: {
    couponId: string;
  }; 

  async deleteCoupon(couponId: string){
    await this.inPlayService.deleteCoupon(couponId); //try with broken req
    this.deleteCouponFromTurnCoupons(couponId); //instead delete coupon by couponId
    this.openSnackBar2();
    if(this.turnCoupons?.length == 0 ){
      this.closeMenu()
    }
  }

  deleteCouponFromTurnCoupons(couponId: string){
    const coupon = this.turnCoupons
      .find(c => c.id == couponId)

   
    const index: number = this.turnCoupons.indexOf(coupon)
    if (index !== -1) {
      this.turnCoupons.splice(index, 1);
    }  

  }

  openSnackBar2() { 
    this._snackBar.open("Your Coupon was successfully deleted!", "Close", {
      duration: 3000,
    });
  }

  intentionToDeleteCoupon(couponId: string): void {
    this.modalData = { couponId: couponId };
    this.modal.open(this.couponDelete, { size: 'lg' });
  }

  async ngOnInit() {
      this.couponSelectedOdds = this.couponService.getCouponSelectedOdds();
      this.turn = await this.couponService.getTurn();
      this.createCoupon(this.couponSelectedOdds);
      this.coupons =  await this.inPlayService.getInPlayFromAPI();
      this.myTournaments = await this.basicService.getMyTournaments();
      this.basicService.getActiveTournaments().pipe(map(
        tournamentsDto =>{
        let tournaments: TournamentHeader[] = [];
        for (let tournament of tournamentsDto){
          let tournamentData = new TournamentHeader();
          tournamentData.id = tournament.id;
          tournamentData.name = tournament.name;
          tournamentData.betsCloseDateUtcForFirstTurn = tournament.betsCloseDateUtcForFirstTurn;
          tournamentData.betsOpenDateUtcForFirstTurn = tournament.betsOpenDateUtcForFirstTurn;
          tournamentData.registrationCloseDateUtc = tournament.registrationCloseDateUtc;
          tournamentData.registrationOpenDateUtc = tournament.registrationOpenDateUtc;
          tournamentData.entryFee = tournament.entryFee;
          tournamentData.startDateUtc = tournament.startDateUtc;
          tournamentData.endDateUtc = tournament.endDateUtc
          if(this.myTournaments.some(tournament => {
            return tournamentData.id == tournament
          })){
            tournamentData.joined = true;
          }
          for(let turn of tournament.turns){
            let turnData = new TurnMetadata();
            turnData.id = turn.id;
            turnData.title = turn.title;
            for(let coupon of this.coupons){
              if(coupon.turnId == turnData.id){
                turnData.coupons.push(coupon);
              }
            }
            tournamentData.turns.push(turnData);
          }
          tournaments.push(tournamentData)
        }
        return tournaments
      }
      )).subscribe(tournaments => {
        this.tournaments = tournaments;
        let myTournamentsData: TournamentHeader[] = [];
        this.myTournamentsData = myTournamentsData;
          for (let tournament of this.tournaments){
            let tournamentData = new TournamentHeader();
            tournamentData.id = tournament.id;
            tournamentData.name = tournament.name;
            tournamentData.entryFee = tournament.entryFee;
            tournamentData.betsCloseDateUtcForFirstTurn = tournament.betsCloseDateUtcForFirstTurn;
            tournamentData.betsOpenDateUtcForFirstTurn = tournament.betsOpenDateUtcForFirstTurn;
            tournamentData.registrationCloseDateUtc = tournament.registrationCloseDateUtc;
            tournamentData.registrationOpenDateUtc = tournament.registrationOpenDateUtc;
            tournamentData.startDateUtc = tournament.startDateUtc;
            tournamentData.endDateUtc = tournament.endDateUtc;
            tournamentData.joined = tournament.joined;
            for(let turn of tournament.turns){
              let turnData = new TurnMetadata();
              turnData.id = turn.id;
              turnData.title = turn.title;
              for(let coupon of this.coupons){
                if(coupon.turnId == turnData.id){
                  if(coupon.couponStatus == "Won"){
                    turnData.won += coupon.winningsInTournamentPoints;
                  }
                  turnData.coupons.push(coupon);
                }
              }
              tournamentData.turns.push(turnData);
            }
            
            if(this.myTournaments.some(tournament => {
              return tournament == tournamentData.id
            })){
              this.myTournamentsData.push(tournamentData)
            }
          }
        this.turnCoupons = this.myTournamentsData.find(t => t.id == this.couponSelectedOdds[0].tournamentId)
          .turns.find(t => t.id == this.turn.id).coupons;
      });     
      
  }

  openMenu(){
    this.showMenu = true
    this.sidenav.toggle();
  }
  
  closeMenu(){
    this.showMenu = false
    this.sidenav.toggle();
  }

  navigateToTournament(tournamentId: string) {
    this.router.navigateByUrl(`/oddsee/tournaments/${tournamentId}`,{skipLocationChange:true});
    return;
  }

  // openDialog(turn: TurnMetadata ): void {
  //   const dialogRef = this.dialog.open(CouponComponent);
  //   this.couponService.setTurn(turn);
  //   dialogRef.afterClosed().subscribe(
  //     _ => {
  //       this.couponService.showCoupon();
  //     }
  //   ); 
  // }
  

  createCoupon(x: SelectedOdd[]) {
    this.couponToPost = new CouponToPost();
    this.couponToPost.value = this.value;
    this.couponToPost.tournamentId = x[0]?.tournamentId;
    this.couponToPost.turnId = x[0]?.turnId;

    for (let bet of x){
      let betToPost = new BetToPost();
      betToPost.leagueId = bet.leagueId;
      betToPost.fixtureId = bet.fixtureId;
      betToPost.eventId = bet.oddId;
      betToPost.total = bet.total;
      betToPost.handicap = bet.handicap;
      betToPost.name = bet.betName;
      betToPost.label = bet.label;
      betToPost.odds = bet.odds;
     ;
      this.couponToPost.bets.push(betToPost);
    }    
  }    

  openSnackBar() {
    this._snackBar.open("Your coupon has been succesfully submitted!", "Close", {
      duration: 2000,
    });
  }


  onSubmit() {
    this.createCoupon(this.couponSelectedOdds);
    console.warn('Your order has been submitted', this.couponToPost);
    this.spinnerService.show();
    this.couponService.postCoupon(this.couponToPost,this.couponSelectedOdds[0].tournamentId,this.couponSelectedOdds[0].turnId)
      .subscribe( _ => {
        const coupon = this.couponSelectedOdds[0];

        this.couponSelectedOdds = []; 
        this.betSubmit.reset();
        this.couponService.deleteCoupon(coupon.tournamentId, coupon.turnId);   

        this.spinnerService.hide();
        this.dialogRef.close();
        this.navigateToTournament(coupon.tournamentId); 
        this.openSnackBar(); 
      }, err => { 
        console.error(err);
        const coupon = this.couponSelectedOdds[0];

        this.couponSelectedOdds = []; 
        this.betSubmit.reset();
        this.couponService.deleteCoupon(coupon.tournamentId, coupon.turnId);   

        this.spinnerService.hide();
        this.dialogRef.close();
        this.navigateToTournament(coupon.tournamentId);  
        // todo notify anout error
      }); 
    
    
  }
  
}
