import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin } from "rxjs";
import { environment } from "../../environments/environment";
import { ApiUrlService } from "./menu/top-bar/top-bar.service";

@Injectable()
export class ConfigService {
  private configPromise: ConfigDto;
  private allowedEventsNames: string[];
  private isConfigInitialized: boolean = false;
  private areEventsInitialized: boolean = false;
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) {}

  async getConfig(): Promise<ConfigDto> {
    if (!this.isConfigInitialized) {
      this.configPromise = await this.http
        .get<ConfigDto>(environment[this.apiUrlService.env] + "api/config")
        .toPromise();
      this.isConfigInitialized = true;
    }

    return this.configPromise;
  }

  async getAllowedEventsNames(): Promise<string[]> {
    if (!this.areEventsInitialized) {
      this.allowedEventsNames = await this.http
        .get<string[]>(
          environment[this.apiUrlService.env] + "api/allowed-events"
        )
        .toPromise();
      this.areEventsInitialized = true;
    }

    return this.allowedEventsNames;
  }
}

class ConfigDto {
  betsPageSize: number;
}
