<app-top-bar-tournament></app-top-bar-tournament>

<mat-progress-spinner
  *ngIf="spinnerService.visibility | async; else Main"
  style="
    margin-top: 30vh;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
  "
  [diameter]="150"
  color="primary"
  mode="indeterminate"
></mat-progress-spinner>

<br />
<ng-template #Main>
  <mat-sidenav-container style="background-color: white">
    <mat-sidenav #sidenav mode="side" style="width: 15vw">
      <div *ngIf="showMenu; else elseB1">
        <!-- <button class="btn btn-primary" (click)="sidenav.toggle();openMenu()">Show</button> -->
        <mat-icon
          style="font-size: 32px; height: 3vh; margin-left: 85%"
          (click)="sidenav.toggle(); closeMenu()"
        >
          chevron_left
        </mat-icon>
      </div>
      <div
        style="
          margin-bottom: 3vh;
          text-align: center;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
          font-weight: 700;
          font-size: 20px;
        "
      >
        Select League
      </div>
      <div class="set-all-container">
        <button (click)="setAllLeagues(true)" class="btn btn-primary">
          Select All
        </button>
        <button (click)="setAllLeagues(false)" class="btn btn-primary">
          Deselect All
        </button>
      </div>
      <mat-list *ngFor="let league of leaguesList">
        <mat-list-item>
          <mat-checkbox
            color="warn"
            [(ngModel)]="league.isSelected"
            style="font-size: 2vh; margin-right: 1vw"
          ></mat-checkbox>
          {{ league.name }} ({{ league.country }})
        </mat-list-item>
      </mat-list>

      <div
        style="
          text-align: center;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <button
          (click)="reloadCalendar()"
          class="btn btn-primary"
          style="margin-top: 5vh"
        >
          Reload calendar
        </button>
      </div>
    </mat-sidenav>

    <mat-sidenav-content style="overflow: hidden">
      <div *ngIf="!showMenu; else elseB1">
        <!-- <button class="btn btn-primary" (click)="sidenav.toggle();closeMenu()">Hide</button> -->
        <mat-icon
          style="font-size: 32px; height: 3vh"
          (click)="sidenav.toggle(); openMenu()"
        >
          chevron_right
        </mat-icon>
      </div>
      <ng-template #elseB1>
        <div style="font-size: 32px; height: 3vh"></div>
      </ng-template>

      <div class="row text-center">
        <div class="col-md-4">
          <div class="btn-group">
            <div
              class="btn btn-primary"
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDate"
              (click)="refreshEventsForPreviousView()"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              Previous
            </div>
            <div
              class="btn btn-outline-secondary"
              mwlCalendarToday
              (click)="refreshEventsForTodayView()"
              [(viewDate)]="viewDate"
            >
              Today
            </div>
            <div
              class="btn btn-primary"
              mwlCalendarNextView
              (click)="refreshEventsForNextView()"
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              Next
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h3>{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</h3>
        </div>
        <div class="col-md-4">
          <div class="btn-group">
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Month)"
              [class.active]="view === CalendarView.Month"
            >
              Month
            </div>
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Week)"
              [class.active]="view === CalendarView.Week"
            >
              Week
            </div>
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Day)"
              [class.active]="view === CalendarView.Day"
            >
              Day
            </div>
          </div>
        </div>
      </div>
      <br />

      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refreshCalendar"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="showEventDetails('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refreshCalendar"
          (eventClicked)="showEventDetails('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refreshCalendar"
          (eventClicked)="showEventDetails('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
      </div>

      <!-- <div [ngSwitch]="view">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </div> -->

      <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

      <br /><br /><br />

      <div *ngIf="!calendarService.tournamentMetadata; else elseBlock">
        <div
          style="
            text-align: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <button
            class="btn btn-primary"
            (click)="showCreateTournamentWindow()"
          >
            Create Tournament
          </button>
        </div>

        <br /><br /><br />
      </div>

      <ng-template #elseBlock>
        <h3 style="max-width: 600px; margin-left: auto; margin-right: auto">
          Edit events

          <!-- <button class="btn btn-info" [disabled]="!calendarService.turnWasAdded" style="margin-left: 10px;" (click)="tournamentSetupOpen()"> -->
          <button
            class="btn btn-info"
            style="margin-left: 10px"
            (click)="tournamentSetupOpen()"
          >
            Review Tournament
          </button>

          <button
            class="btn btn-primary float-right"
            style="margin-left: 10px"
            (click)="showCreateTurnWindow()"
          >
            Add Turn
          </button>

          <div class="clearfix"></div>
        </h3>

        <div
          class="table-responsive"
          style="max-width: 600px; margin-left: auto; margin-right: auto"
        >
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Title</th>
                <!-- <th>Primary color</th>
            <th>Secondary color</th> -->
                <!-- <th>Status</th> -->
                <!-- <th>Starts at</th>
            <th>Ends at</th> -->
                <th>Remove</th>
                <th>Verify</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let event of getTournamentMetaevents()">
                <td>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="event.titleShort"
                    (keyup)="refreshCalendar.next()"
                  />
                </td>
                <!-- <td>
              <input
                type="color"
                [(ngModel)]="event.color.primary"
                (change)="refreshCalendar.next()"
              />
            </td>
            <td>
              <input
                type="color"
                [(ngModel)]="event.color.secondary"
                (change)="refreshCalendar.next()"
              />
            </td> -->
                <!-- <td>
              <input style="font-weight: bold;"
                disabled
                type="text"
                class="form-control"
                [(ngModel)]="event.verificationStatus"
              />
            </td> -->
                <!-- <td>
              <input 
                matInput [matDatepicker]="pickerStart"
                type="text"
                [(ngModel)]="event.start"
                (ngModelChange)="refreshCalendar.next()"
                >
              <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
              <mat-datepicker #pickerStart></mat-datepicker>
              <input
                class="form-control"
                type="text"
                mwlFlatpickr
                [(ngModel)]="event.start"
                (ngModelChange)="refreshCalendar.next()"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="true"
                dateFormat="Y-m-dTH:i"
                altFormat="F j, Y H:i"
                placeholder="Not set"
              />
            </td> -->
                <!-- <td>
              <input 
                matInput [matDatepicker]="pickerEnd"
                type="text"
                [(ngModel)]="event.end"
                (ngModelChange)="refreshCalendar.next()"
                >
              <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
              <mat-datepicker #pickerEnd></mat-datepicker>
              <input
                class="form-control"
                type="text"
                mwlFlatpickr
                [(ngModel)]="event.end"
                (ngModelChange)="refreshCalendar.next()"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="true"
                dateFormat="Y-m-dTH:i"
                altFormat="F j, Y H:i"
                placeholder="Not set"
              />
            </td> -->
                <td>
                  <button
                    class="btn btn-danger"
                    [disabled]="!event.type"
                    (click)="deleteEvent(event); deleteEventFromView(event)"
                  >
                    Delete
                  </button>
                </td>
                <td>
                  <div *ngIf="!event.type; else elseBlock">
                    <!-- <button class="btn btn-info" [disabled]="!calendarService.allTurnsSettedUp" (click)="tournamentSetupOpen()" > -->
                    <button
                      class="btn btn-info"
                      (click)="tournamentSetupOpen()"
                    >
                      Show Tournament
                    </button>
                  </div>
                  <ng-template #elseBlock>
                    <!-- <button class="btn btn-info" (click)="getFixturesInRange(event.start, event.end)"> -->
                    <button class="btn btn-info" (click)="turnSetupOpen(event)">
                      Verify
                    </button>
                  </ng-template>
                  <!-- I need to add an alternative route ngIf for tournament row -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>

      <!-- <button mat-raised-button [disabled]="!selectedOdds.length" style="margin-bottom: 10px; margin-top: 10px;" color="success" type="submit" >Submit my Coupon!</button> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>

<ng-template #eventDetails let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #createTournament let-close="close">
  <div class="modal-header">
    <h5
      class="modal-title"
      style="
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%;
      "
    >
      Create Tournament
    </h5>
    <!-- <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <!-- <div>
      Action:
      <pre> wow</pre>
    </div>
    <div>
      Event:
      <pre> nice</pre>
    </div> -->
    <div
      style="
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%;
      "
    >
      <div>
        <div style="font-weight: bold; margin-right: 50px">
          Add Fixtures from Date:
        </div>
        <!-- <div style="width: 100%;">
          <input matInput [ngxMatDatetimePicker]="pickerTournamentStart" [(ngModel)]="tournamentStart">
            <mat-datepicker-toggle matSuffix [for]="pickerTournamentStart"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerTournamentStart [showSpinners]="true" [showSeconds]="false"
              [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
              [touchUi]="true" [color]="primary" [enableMeridian]="false" 
              [disableMinute]="false" [hideTime]="false">
            </ngx-mat-datetime-picker>
        </div> -->
        <input
          style="width: 100px"
          matInput
          [matDatepicker]="pickerTournamentStart"
          type="text"
          [(ngModel)]="tournamentStart"
          placeholder="From Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTournamentStart"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTournamentStart></mat-datepicker>
      </div>
      <div>
        <div style="font-weight: bold; margin-right: 50px">
          Add Fixtures to Date:
        </div>
        <!-- <div style="width: 100%;">
          <input matInput [ngxMatDatetimePicker]="pickerTournamentEnd" [(ngModel)]="tournamentEnd">
            <mat-datepicker-toggle matSuffix [for]="pickerTournamentEnd"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerTournamentEnd [showSpinners]="true" [showSeconds]="false"
              [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
              [touchUi]="true" [color]="primary" [enableMeridian]="false" 
              [disableMinute]="false" [hideTime]="false">
            </ngx-mat-datetime-picker>
        </div> -->
        <input
          style="width: 100px"
          matInput
          [matDatepicker]="pickerTournamentEnd"
          type="text"
          [(ngModel)]="tournamentEnd"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTournamentEnd"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTournamentEnd></mat-datepicker>
      </div>
      <div>
        <div style="font-weight: bold; margin-right: 50px">
          Registration Open Date
        </div>
        <div style="width: 100%">
          <input
            matInput
            [ngxMatDatetimePicker]="pickerRegistrationOpenDate"
            [(ngModel)]="registrationOpenDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerRegistrationOpenDate"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #pickerRegistrationOpenDate
            [showSpinners]="true"
            [showSeconds]="false"
            [stepHour]="1"
            [stepMinute]="1"
            [stepSecond]="1"
            [touchUi]="true"
            [color]="primary"
            [enableMeridian]="false"
            [disableMinute]="false"
            [hideTime]="false"
          >
          </ngx-mat-datetime-picker>
        </div>
        <!-- <input 
              style="width: 100px"
              matInput [matDatepicker]="pickerRegistrationOpenDate"
              type="text"
              [(ngModel)]="registrationOpenDate"
              >
            <mat-datepicker-toggle matSuffix [for]="pickerRegistrationOpenDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerTournamentEnd></mat-datepicker> -->
      </div>
      <!-- <div> 
        <div style="font-weight: bold; margin-right: 50px;">Registration Closed Date</div> 
        <div style="width: 100%;">
          <input matInput [ngxMatDatetimePicker]="pickerRegistrationCloseDate" [(ngModel)]="registrationCloseDate">
            <mat-datepicker-toggle matSuffix [for]="pickerRegistrationCloseDate"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerRegistrationCloseDate [showSpinners]="true" [showSeconds]="false"
              [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
              [touchUi]="true" [color]="primary" [enableMeridian]="false" 
              [disableMinute]="false" [hideTime]="false">
            </ngx-mat-datetime-picker>
        </div>
        <input 
              style="width: 100px"
              matInput [matDatepicker]="pickerRegistrationClosedDate"
              type="text"
              [(ngModel)]="registrationClosedDate"
              >
            <mat-datepicker-toggle matSuffix [for]="pickerRegistrationClosedDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerTournamentEnd></mat-datepicker>
      </div> -->
      <div>
        <div style="font-weight: bold; margin-right: 50px">
          Registration Close Margin (in minutes)
        </div>
        <input
          style="width: 100px"
          matInput
          type="number"
          [(ngModel)]="registrationCloseMargin"
        />
      </div>
      <div>
        <div style="font-weight: bold; margin-right: 50px">
          bets open margin (in minutes)
        </div>
        <input
          style="width: 100px"
          matInput
          type="number"
          [(ngModel)]="betsOpenMargin"
        />
      </div>
      <div>
        <div style="font-weight: bold; margin-right: 50px">
          bets close margin (in minutes)
        </div>
        <input
          style="width: 100px"
          matInput
          type="number"
          [(ngModel)]="betsCloseMargin"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="
        addTournament(
          tournamentStart,
          tournamentEnd,
          registrationOpenDate,
          registrationCloseDate,
          registrationCloseMargin,
          betsOpenMargin,
          betsCloseMargin
        );
        close()
      "
    >
      Create
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      style="margin-left: 10px"
      (click)="close()"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #createTurn let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Create Turn</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div>
      Action:
      <pre> wow</pre>
    </div>
    <div>
      Event:
      <pre> nice</pre>
    </div> -->
    <div>
      <input
        matInput
        [matDatepicker]="pickerTurnStart"
        type="text"
        [(ngModel)]="turnStart"
        placeholder="From Date"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerTurnStart"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerTurnStart></mat-datepicker>
    </div>
    <div>
      <input
        matInput
        [matDatepicker]="pickerTurnEnd"
        type="text"
        [(ngModel)]="turnEnd"
        placeholder="To Date"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerTurnEnd"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerTurnEnd></mat-datepicker>
    </div>
    <mat-form-field class="example-form-field">
      <mat-label>Clearable input</mat-label>
      <input matInput type="text" [(ngModel)]="turnTitle" />
      <button
        mat-button
        *ngIf="turnTitle"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="turnTitle = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="addTurn(turnStart, turnEnd, turnTitle); close()"
    >
      Create
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      style="margin-left: 10px"
      (click)="close()"
    >
      OK
    </button>
  </div>
</ng-template>

<ng-template #changeMetaEvent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Create Tournament</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div>
      Action:
      <pre> wow</pre>
    </div>
    <div>
      Event:
      <pre> nice</pre>
    </div> -->

    <div>
      <mat-form-field>
        <input
          matInput
          [ngxMatDatetimePicker]="picker"
          placeholder="Tournament Start Date"
          [(ngModel)]="tempStart"
          [disabled]="false"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="1"
          [stepMinute]="1"
          [stepSecond]="1"
          [touchUi]="true"
          [color]="primary"
          [enableMeridian]="false"
          [disableMinute]="false"
          [hideTime]="false"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <!-- <input 
            matInput [matDatepicker]="pickerMetaEventStart"
            type="text"
            [(ngModel)]="tempStart"
            placeholder="From Date" 
            >
          <mat-datepicker-toggle matSuffix [for]="pickerMetaEventStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerMetaEventStart></mat-datepicker> -->
    </div>
    <div>
      <input
        matInput
        [matDatepicker]="pickerMetaEventEnd"
        type="text"
        [(ngModel)]="tempEnd"
        placeholder="To Date"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerMetaEventEnd"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerMetaEventEnd></mat-datepicker>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="eventTimesChanged2(tempEvent, tempStart, tempEnd)"
    >
      Change
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      style="margin-left: 10px"
      (click)="close()"
    >
      OK
    </button>
  </div>
</ng-template>
