<app-top-bar-tournament></app-top-bar-tournament>

<div style="font-size: 16px; color:#6200EE; font-weight: 600; width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
  <div style="height: max-content; margin-top: 25vh; margin-left: 40vw; padding-bottom: 100vh;" *ngIf="spinnerService.visibility | async">
    <mat-progress-spinner [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<div style="height: 55px; width: 100%; background: #6200EE; color: white;">   
  <div style="text-align: center; display: flex; align-items: center"> 
    <div style="width: 10%; padding-top: 10px;">
      <mat-icon style="font-size: 32px;  -webkit-text-fill-color:white;" 
      [routerLink]="['/oddsee/tournaments']">
        chevron_left
      </mat-icon>
    </div>  
    <div style="margin-left: auto; width: 80%; margin-right: auto;">
      <div style="font-size: 20px; padding-top: 5px;"> 
        My Tournaments
      </div>
    </div>
    <div style="width: 10%;">
    </div>
  </div>
</div>




<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Active tournaments">
    <div *ngIf="myTournamentsData?.length == 0">
      <div style="text-align: center; margin-top: 1vh;">
        You aren't registered for any active tournament. Please register to tournament <span style="font-weight: 700;" [routerLink]="['']">here</span> and come back again!  
      </div>
    </div>
    <div>
      <div style="padding-top: 0px;" *ngFor="let tournament of myTournamentsData">
        <mat-card style="margin-bottom: 2vh; margin-top: 2vh;">
            <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
              {{ tournament.name }} 
            </div>
          <mat-card-actions>
              <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                  <button color="success" mat-raised-button class="button fancy-button" style="height: 40px; text-align: center;" [routerLink]="['/oddsee/tournaments', tournament.id]" (click)="couponService.specifySelectedTournament(tournament.id)">
                  Open
                  </button>
                  <button color="success" mat-raised-button class="button fancy-button" 
                  [routerLink]="['/oddsee/tournaments', tournament?.id, 'table']" 
                  [state]="{tournamentMetadata: tournament}" 
                  style="margin-left: 4vw; height: 40px; text-align: center;" >
                  Table
                  </button>
              </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </mat-tab>


  <mat-tab label="Closed tournaments">
    <div>
      <div style="padding-top: 0px;" *ngFor="let tournament of myClosedTournamentsData">
        <div *ngIf="myClosedTournamentsData?.length == 0">
          <div style="text-align: center; margin-top: 1vh;">
            You aren't registered for any closed tournament. Please register to tournament <span style="font-weight: 700;" [routerLink]="['']">here</span> create a coupon and after tournament will be closed come back again! 
          </div>
        </div>
        <mat-card style="margin-bottom: 2vh; margin-top: 2vh;">
            <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
              {{ tournament.name }} 
            </div>
          <mat-card-actions>
              <div style="width:100%; text-align: center; align-items: center; margin-left: auto; margin-right: auto;">
                  <!-- <button color="success" mat-raised-button class="button fancy-button" style="height: 40px; text-align: center;" [routerLink]="['/oddsee/tournaments', tournament.id]" (click)="couponService.specifySelectedTournament(tournament.id)">
                  Open
                  </button> -->
                  <button color="success" mat-raised-button class="button fancy-button" 
                  [routerLink]="['/oddsee/tournaments', tournament?.id, 'table']" 
                  [state]="{tournamentMetadata: tournament}" 
                  style="height: 40px; text-align: center;" >
                  Table
                  </button>
              </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>