<app-top-bar-tournament (click)="dismissSnackBar()"></app-top-bar-tournament>

<div style="height: 55px; width: 100%; background: #6200EE; color: white;">        
  <div style="text-align: center; display: flex; align-items: center"> 
    <div style="width: 10%; padding-top: 10px;">
      <mat-icon style="font-size: 32px;  -webkit-text-fill-color:white;" 
      [routerLink]="['/oddsee/tournaments/',tournamentMetadata.id,'turn',turn.id]"
      [state]="{tournamentMetadata: tournamentMetadata, turn: turn}"
      (click)="couponService.allignOddsCollections(tournamentMetadata.id,turn.id); dismissSnackBar()">
        chevron_left
      </mat-icon>
    </div>
    <div style="width: 10%; font-size: 10px; padding-top: 5px;"> 
      MB per C: {{ turn.maxBets }}
    </div>
    <div style="width: 50%;margin-left: auto; margin-right: auto;">
      <div style="font-size: 15px; padding-top: 5px;"> 
      </div> 
      <div style="font-size: 12px; padding-top: 5px;"> 
        {{ fixture?.localTeamName }} - {{ fixture?.visitorTeamName }}
      </div>
    </div>
    <div style="width: 10%; padding-top: 8px">    
      <mat-icon style="-webkit-text-fill-color:white;" (click)="refreshFromAPI()">
        refresh
      </mat-icon>
    </div>
    <div style="width: 10%; font-size: 10px; padding-top: 5px;"> 
      CS: {{ couponService.turnWallet }}
    </div>
  </div>
</div>

<mat-progress-spinner *ngIf="spinnerService.visibility | async" style="margin-top: 30vh; margin-left: auto; margin-right: auto; align-items: center; text-align: center;" [diameter]="150" color="primary" mode="indeterminate"></mat-progress-spinner>

<div *ngIf="bets && bets.length == 0; else ElseBlockN1" style="text-align: center; margin-left: auto; margin-right: auto; margin-top: 5vh;">
   Right now there are no odds for this fixture. <br />Please check again later, odds will be here very soon.
</div>
<ng-template #ElseBlockN1>
  <div>  
    <button class="mine" mat-fab color="basic"
    [hidden]="!couponService.displayCoupon" matBadge="{{couponSelectedOdds.length}}"
    [matBadgeHidden]="!couponSelectedOdds.length" matBadgePosition="after" matBadgeColor="primary" 
    (click)="openDialog(); couponService.hideCoupon()">
      <i class="material-icons" style="color:#222222;">
        receipt
      </i>
    </button> 
    <!-- <button mat-raised-button class="button fancy-button" style="height: 40px;" [disabled]="!couponSelectedOdds.length" matBadge="{{couponSelectedOdds.length}}" [matBadgeHidden]="!couponSelectedOdds.length" matBadgePosition="after" matBadgeColor="warn" (click)="openDialog()"  >
      <i class="material-icons" >receipt</i>
      </button> -->
    <cdk-virtual-scroll-viewport itemSize="20" class="example-viewport">
        <mat-accordion *cdkVirtualFor="let bet of ds" [title]="bet?.id + ' details'" [id]="bet?.id" >
          <div *ngIf="bet?.isSelected">
            <mat-expansion-panel [expanded]="bet?.isExpanded" style="max-width: 680px; margin-left: auto; margin-right: auto;">
              <mat-expansion-panel-header (click)="bet.isExpanded = !bet.isExpanded">
                <mat-panel-title>
                  <span style="font-weight: 600;">{{ bet?.name || 'Loading...' }} </span>
                </mat-panel-title>
                <mat-panel-description >
                  <div style=" width: 100%; text-align: right;">
                    {{ getBetLabel(bet) }}
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div [className]="bet?.odds.length > 3 ? 'scrollable' : ''" *ngIf="bet?.isExpanded">          
                <div *ngFor="let odd of bet?.odds" >
                  <div>              
                    <div class="betBlock" style="text-align: center; display: flex; align-items: center">
                      <div style="margin-right: 20px">
                        <mat-checkbox color = "primary" (click)="disabledOddsSnackBar(odd.isSelected)" (change)="refreshCoupon()" [(ngModel)]="odd.isSelected" [(disabled)] = "!odd.isSelected && disableOdds"></mat-checkbox>
                      </div>      
                      <div style=" width:50vw; text-align: center; display: flex; align-items: center">           
                        <div *ngIf="turn.maxBetOdds>=odd?.value; else elseBlock">
                          <div class="betDesc">
                            <span style="font-weight: 600; font-size: 15px">
                              {{ odd?.value | number: '1.2-2' }}
                            </span> 
                          </div>
                        </div>
                        <ng-template #elseBlock>
                          <div class="betDesc">
                            <span style="font-weight: 600; font-size: 15px">
                              {{turn.maxBetOdds | number: '1.2-2'}}
                            </span> 
                            <span style="color: red; font-size: 15px; text-decoration-line: line-through;">
                              {{ odd?.value | number: '1.2-2' }}
                            </span> 
                          </div>
                        </ng-template>
                        <div class="betLabel" style="margin-left: 10px; font-size: 18px">
                        {{ odd?.label }}
                        </div>  
                      </div>
                    </div>  
                  </div>
                </div>
              </div> 
            </mat-expansion-panel>  
          </div>     
        </mat-accordion>
    </cdk-virtual-scroll-viewport>       
  </div>  
</ng-template>

