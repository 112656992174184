<app-top-bar-tournament></app-top-bar-tournament>

<mat-tab-group mat-align-tabs="start">
    <mat-tab label="Active tournaments">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let tournament of activeTournaments"
            style="margin-bottom: 1vh; max-width: 700px; margin-left: auto; margin-right: auto;">
                <mat-expansion-panel-header style="height: 60px;">
                    <mat-panel-title>
                        <div style="display: grid; margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                            <div> <span style="font-weight: 600;margin-right: 1vw;">Tournament name:</span> {{ tournament.name }} </div>
                            <div >
                                <div *ngIf="tournament.status == '2'">
                                    <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                    <span [className]="tournament.status == '2' ? 'Won' : ''"> Open </span>
                                </div>
                                <div *ngIf="tournament.status == '3'" >
                                    <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                    <span [className]="tournament.status == '3' ? 'Lost' : ''"> Closed </span>
                                </div>
                                <div *ngIf="tournament.status == '4'" >
                                    <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                    <span [className]="tournament.status == '4' ? 'InPlay' : ''"> Settled </span>
                                </div>
                            </div>
                        </div>
                    </mat-panel-title>  
                </mat-expansion-panel-header>
                <mat-selection-list *ngFor="let turn of tournament.turns">
                    <div style="display: grid; margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                        <div> <span style="font-weight: 600;margin-right: 1vw;">Turn title:</span> {{ turn.title }} </div>
                        <div >
                            <div *ngIf="turn.status == '2'">
                                <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                <span [className]="turn.status == '2' ? 'Won' : ''"> Open </span>
                            </div>
                            <div *ngIf="turn.status == '3'" >
                                <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                <span [className]="turn.status == '3' ? 'Lost' : ''"> Closed </span>
                            </div>
                        </div>
                    </div>
                </mat-selection-list> 
                <div style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                    <button mat-raised-button color="success" style="margin-top: 2vh;"  (click)="intentionToCloseTournament(tournament)">
                        Close Tournament
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
  </mat-tab>
  <mat-tab label="Closed tournaments">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let tournament of closedTournaments"
        style="margin-bottom: 1vh; max-width: 700px; margin-left: auto; margin-right: auto;">
                <mat-expansion-panel-header style="height: 60px;">
                    <mat-panel-title>
                        <div style="display: grid; margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                            <div> <span style="font-weight: 600;margin-right: 1vw;">Tournament name:</span> {{ tournament.name }} </div>
                            <div >
                                <div *ngIf="tournament.status == '2'">
                                    <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                    <span [className]="tournament.status == '2' ? 'Won' : ''"> Open </span>
                                </div>
                                <div *ngIf="tournament.status == '3'" >
                                    <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                    <span [className]="tournament.status == '3' ? 'Lost' : ''"> Closed </span>
                                </div>
                                <div *ngIf="tournament.status == '4'" >
                                    <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                    <span [className]="tournament.status == '4' ? 'InPlay' : ''"> Settled </span>
                                </div>
                            </div>
                        </div>
                    </mat-panel-title>  
                </mat-expansion-panel-header>
                <mat-selection-list *ngFor="let turn of tournament.turns">
                    <div style="display: grid; margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                        <div> <span style="font-weight: 600;margin-right: 1vw;">Turn title:</span> {{ turn.title }} </div>
                        <div >
                            <div *ngIf="turn.status == '2'">
                                <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                <span [className]="turn.status == '2' ? 'Won' : ''"> Open </span>
                            </div>
                            <div *ngIf="turn.status == '3'" >
                                <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                                <span [className]="turn.status == '3' ? 'Lost' : ''"> Closed </span>
                            </div>
                        </div>
                    </div>
                </mat-selection-list> 
            <div style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                <button mat-raised-button color="success" style="margin-top: 2vh;" (click)="intentionToSettleTournament(tournament)">
                    Settle Tournament
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
  </mat-tab>
  <mat-tab label="Settled tournaments">
    <mat-accordion >
        <mat-expansion-panel *ngFor="let tournament of settledTournaments" 
        style="margin-bottom: 1vh; max-width: 700px; margin-left: auto; margin-right: auto;">
        <mat-expansion-panel-header style="height: 60px;">
            <mat-panel-title>
                <div style="display: grid; margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                    <div> <span style="font-weight: 600;margin-right: 1vw;">Tournament name:</span> {{ tournament.name }} </div>
                    <div >
                        <div *ngIf="tournament.status == '2'">
                            <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                            <span [className]="tournament.status == '2' ? 'Won' : ''"> Open </span>
                        </div>
                        <div *ngIf="tournament.status == '3'" >
                            <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                            <span [className]="tournament.status == '3' ? 'Lost' : ''"> Closed </span>
                        </div>
                        <div *ngIf="tournament.status == '4'" >
                            <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                            <span [className]="tournament.status == '4' ? 'InPlay' : ''"> Settled </span>
                        </div>
                    </div>
                </div>
            </mat-panel-title>  
        </mat-expansion-panel-header>
        <mat-selection-list *ngFor="let turn of tournament.turns">
            <div style="display: grid; margin-left: auto; margin-right: auto; text-align: center; align-items: center">
                <div> <span style="font-weight: 600;margin-right: 1vw;">Turn title:</span> {{ turn.title }} </div>
                <div >
                    <div *ngIf="turn.status == '2'">
                        <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                        <span [className]="turn.status == '2' ? 'Won' : ''"> Open </span>
                    </div>
                    <div *ngIf="turn.status == '3'" >
                        <span style="font-weight: 600;margin-right: 1vw;">Status:</span> 
                        <span [className]="turn.status == '3' ? 'Lost' : ''"> Closed </span>
                    </div>
                </div>
            </div>
        </mat-selection-list> 
        </mat-expansion-panel>
    </mat-accordion>
  </mat-tab>
</mat-tab-group>


<ng-template #tournamentClose let-close="close">
    <div class="modal-header">
      <h5 class="modal-title" style="text-align: center;">Do you want to close this tournament?</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <div style="display: flex;">
        <button type="button" class="btn btn-outline-secondary" (click)="close();closeTournament(modalData?.tournament.id)" >
          Yes
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="close()">
          No
        </button>
      </div>        
    </div>
  </ng-template>

  <ng-template #tournamentSettle let-close="close">
    <div class="modal-header">
      <h5 class="modal-title" style="text-align: center;">Do you want to settle this tournament?</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <div style="display: flex;">
        <button type="button" class="btn btn-outline-secondary" (click)="close();settleTournament(modalData?.tournament.id)" >
          Yes
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="close()">
          No
        </button>
      </div>        
    </div>
  </ng-template>
