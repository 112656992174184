import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BasicService, TurnMetadata, TournamentMetadata, FixtureFromAPIDto } from '../../common/basic.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CouponService, SelectedOdd } from './../../betting/common/coupon.service';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ConfigService } from '../../../infrastructure/config.service';
import { OddsService } from '../../betting/common/odds.service';
import { SpinnerService } from '../../../infrastructure/network/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { CouponComponent } from '../../../modules/betting/coupon/coupon.component';
import {MatSnackBar} from '@angular/material/snack-bar';

export class Odd {
  bet: Bet
  value: number;
  total: string;
  label: string;
  probabilityLabel: string;
  handicap: string;
  isSelected: boolean;
}

export class Bet {
  id: string;
  name: string;
  type: string;
  isExpanded: boolean = false;
  isSelected: boolean = false;
  turnId: string;
  tournamentId: string;
  odds: Odd[] = [];
}

@Component({
  selector: 'app-tournaments-odds',
  templateUrl: './tournaments-odds.component.html',
  styleUrls: ['./tournaments-odds.component.scss'],
})

export class TournamentsOddsComponent implements OnInit {
  ds: MyDataSource;
  fixture: FixtureFromAPIDto;
  tournamentMetadata: TournamentMetadata;
  fixtures: FixtureFromAPIDto[];
  turn: TurnMetadata;
  disableOdds = false;
  bets: Bet[] = null;

  refreshCoupon() {
    this.couponService.specifySelectedOdds(this.fixture, this.tournamentMetadata.id, this.turn.id);
    this.couponService.removeOddsForTurn(this.tournamentMetadata.id, this.turn.id);
    
    for(let odd of this.ds.getSelectedOdds()){
      let selectedOdd = new SelectedOdd({
        leagueId: this.fixture.leagueId,
        leagueCountry: this.fixture.leagueCountry, 
        leagueName: this.fixture.leagueName, 
        betName: odd.bet.name,
        oddId: odd.bet.id,
        fixtureId: this.fixture.id,
        localTeamName: this.fixture.localTeamName,
        visitorTeamName: this.fixture.visitorTeamName,
        label: odd.label,
        total: odd.total,
        handicap: odd.handicap,
        odds: odd.value,
        probabilityLabel: odd.probabilityLabel,
        tournamentId: this.tournamentMetadata.id,
        turnId: this.turn.id
      });
      this.couponService.addOddToCouponsList(selectedOdd);
    }
    this.couponService.allignOddsCollections(this.tournamentMetadata.id, this.turn.id);
    this.couponService.showCoupon();
    if(this.couponSelectedOdds.length >= this.turn.maxBets || this.couponSelectedOdds.find(c => c.fixtureId == this.fixture.id) ){
    // if(this.couponSelectedOdds.length >= this.turn.maxBets){
      this.disableOdds = true;
      // this.openSnackBar();
    } else {
      this.disableOdds = false;
      // this.dismissSnackBar();
    };
  }  

  @Output() couponDialogClosed: EventEmitter<any> = new EventEmitter<any>();

  openSnackBar() {
    // this._snackBar.open("Reached a maximum of one bet per fixture or a maximum of bets per Coupon", "Close", {
    this._snackBar.open("Reached a maximum of bets per Coupon", "Close", {
      duration: 3000,
    });
  }

  dismissSnackBar() {
    this._snackBar.dismiss();
  }

  disabledOddsSnackBar(selected: boolean){
    if(this.disableOdds){
      if(!selected){      
        this.openSnackBar(); 
      }
      // if(this.couponService.couponSelectedOdds.length != 0){
      //   this.openSnackBar();
      // }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CouponComponent);
    this.couponService.setTurn(this.turn);
    dialogRef.afterClosed().subscribe(
      _ => {
        this.couponService.showCoupon();
        if(this.couponSelectedOdds.length >= this.turn.maxBets || this.couponSelectedOdds.find(c => c.fixtureId == this.fixture.id)){
        // if(this.couponSelectedOdds.length >= this.turn.maxBets){
          this.disableOdds = true;
          // this.openSnackBar();
        } else {
          this.disableOdds = false;
          // this.dismissSnackBar();
        };
      }
    ); 

    dialogRef.afterClosed().subscribe(_ => 
      this.couponDialogClosed.emit(this.ds.refreshAllFromCoupon())
      );
  }

  constructor(
    private basicService: BasicService,
    private route: ActivatedRoute,
    public couponService: CouponService,
    private configService: ConfigService,
    private router: Router,
    private location: Location,
    private oddsService: OddsService,
    public spinnerService: SpinnerService,
    public dialog: MatDialog,  
    private _snackBar: MatSnackBar
  ) { }

  get couponSelectedOdds():SelectedOdd[]{
    return this.couponService.couponSelectedOdds;
  }

  private refresh(): void {
    this.router.navigateByUrl('',{skipLocationChange:true}).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
    });
  }

  private getBetLabel(bet: Bet): string {
    if(bet) {
      if(bet.isExpanded) {
        return "";
      } else {
        return bet.odds.length + " bets";
      }
    } else {
      return "Loading..."
    }
  }


  refreshFromAPI() {
    this.basicService.getFixtureFromAPI(this.fixture.id,this.fixture.leagueId)
      .subscribe(fixture => this.fixture = fixture);
  }

  async ngOnInit() {   
    if(!history.state.fixture || !history.state.tournamentMetadata || !history.state.turn) {
      this.router.navigateByUrl('',{skipLocationChange:true});
      return;
    }
    
    this.tournamentMetadata = history.state.tournamentMetadata;
    this.turn = history.state.turn;
    this.fixture = history.state.fixture;
    const config = await this.configService.getConfig();
    this.ds = new MyDataSource(
      this.basicService, 
      this.couponService, 
      this.fixture, 
      config.betsPageSize,
      this.oddsService,
      this.turn,
      this.tournamentMetadata,
      this.spinnerService,
      this
    );
    this.refreshFromAPI()
    this.couponService.allignTurnOdds(this.tournamentMetadata.id, this.turn.id)   
    this.couponService.showCoupon();
    if(this.couponSelectedOdds.length >= this.turn.maxBets  || this.couponSelectedOdds.find(c => c.fixtureId == this.fixture.id)){
    // if(this.couponSelectedOdds.length >= this.turn.maxBets){
      this.disableOdds = true;
      // this.openSnackBar();
    } else {
      this.disableOdds = false;
      // this.dismissSnackBar();
    };

  }

}

export class MyDataSource extends DataSource<Bet | undefined> {
  private _lastPage = 0;
  private _cachedData = Array.from<Bet>({ length:0 });
  private _dataStream = new BehaviorSubject <(Bet | undefined)[]>(this._cachedData);
  private _subscription = new Subscription();

  constructor(
    private basicService: BasicService,
    private couponService: CouponService,
    private fixture: FixtureFromAPIDto,
    private saveBetsPageSize: number,
    private oddsService: OddsService,
    private turn: TurnMetadata,
    private tournamentMetadata: TournamentMetadata,
    private spinnerService: SpinnerService,
    private tournamentsOddsComponent: TournamentsOddsComponent,
  ) {   
    super();
    this._fetchPage();
  }

  public getSelectedOdds(): Odd[] {
    let oddsToReturn: Odd[] = [];
    for(let bet of this._cachedData) {
      if(bet) {
        for(let odd of bet.odds) {
          if(odd.isSelected) {
            oddsToReturn.push(odd);           
          }
        }
      } else {
        continue;
      }
    }
    return oddsToReturn;
  }

  public clearSelectedOdds(): Odd[] {
    let oddsToReturn: Odd[] = [];
    for(let bet of this._cachedData) {
      if(bet) {
        for(let odd of bet.odds) {
          odd.isSelected = false
        }
      } else {
        continue;
      }
    }
    return oddsToReturn;
  }

  connect(collectionViewer: CollectionViewer): Observable<(Bet | undefined)[] | ReadonlyArray<Bet | undefined>> {
    this._subscription.add(collectionViewer.viewChange.subscribe(range => {
      const currentPage = this._getPageForIndex(range.end);

      if (currentPage > this._lastPage) {
        this._lastPage = currentPage;
        this._fetchPage();
      }
      
    }));
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this.saveBetsPageSize);
  }

  public refreshFromCoupon(bets: Bet[]) {
    
    
    let oddsFromCoupon = this.couponService.getSelectedOddsForTurn(
      this.fixture.id, 
      this.tournamentMetadata.id, 
      this.turn.id
    )
      for (let bet of bets){
        if(!bet) {
          break;
        }
        for (let odd of bet.odds){
          let isOddFromCoupon = oddsFromCoupon.some(oddFromCoupon => 
            oddFromCoupon.label == odd.label 
            && oddFromCoupon.handicap  == odd.handicap
            && oddFromCoupon.total  == odd.total
            && oddFromCoupon.betName  == odd.bet.name)
          odd.isSelected = isOddFromCoupon;
        }                                                   
      }
    
  }

  public refreshAllFromCoupon() {
    this.refreshFromCoupon(this._cachedData)
  }

  private _fetchPage() {
    this.spinnerService.show();
     this.oddsService.getPagedTournamentsBetsFromAPI(
       this.fixture.id, 
       this.fixture.leagueId,
       this._lastPage, 
       this.tournamentMetadata.id, 
       this.turn.id
      ).pipe(map(
        betsDtos => {
          let bets:Bet[] = [];
          for (let betDto of betsDtos) {
            let bet = new Bet();
            bet.id = betDto.id;
            bet.name = betDto.name;
            bet.type = betDto.type;
            bet.isExpanded = true;
            if(this.turn.events.find(e => e.eventName == bet.name).isSelected){
              bet.isSelected = true;
            }
            bet.odds = [];
            for (let oddFromBetDto of betDto.bets){
              let odd = new Odd();
              odd.bet = bet;
              odd.label = oddFromBetDto.label;
              odd.value = oddFromBetDto.odds;
              bet.odds.push(odd);
            }
            bets.push(bet)          
          }
          return bets;
        }
      ))
      .subscribe(bets => {
        this.spinnerService.hide();
        this.refreshFromCoupon(bets);
        if(!this.tournamentsOddsComponent.bets){
          this.tournamentsOddsComponent.bets = bets;
        } 
        else
        {
          this.tournamentsOddsComponent.bets = this.tournamentsOddsComponent.bets.concat(bets)
        }
        this._cachedData = this._cachedData.concat(bets)
        this._dataStream.next(this._cachedData);   
        
      });    
  }
}

