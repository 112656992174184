<app-top-bar-tournament></app-top-bar-tournament>
<div class="card">
    <h4 class="card-header">Change Password</h4>
    <div class="card-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group" style="max-width: 400px; margin-left: auto; margin-right: auto;">
                <label for="login">Login</label>
                <input type="text" formControlName="login" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.login.errors }" />
                <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                    <div *ngIf="f.login.errors.required">Old password is required</div>
                </div>
            </div>
            <div class="form-group" style="max-width: 400px; margin-left: auto; margin-right: auto;">
                <label for="oldPassword">Old Password</label>
                <input type="password" formControlName="oldPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" />
                <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.oldPassword.errors.required"> Old password is required</div>
                </div>
            </div>
            <div class="form-group" style="max-width: 400px; margin-left: auto; margin-right: auto;">
                <label for="newPassword">New Password</label>
                <input type="text" formControlName="newPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
                <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.newPassword.errors.required"> New password is required</div>
                </div>
            </div>
            <!-- <div class="form-group" style="max-width: 400px; margin-left: auto; margin-right: auto;">
                <label for="repeatedNewPassword">Repeat New Password</label>
                <input type="text" formControlName="repeatedNewPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.repeatedNewPassword.errors }" />
                <div *ngIf="submitted && f.repeatedNewPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.repeatedNewPassword.errors.required"> New password is required</div>
                </div>
            </div>
            <div *ngIf="f.repeatedNewPassword != f.newPassword">
                f.repeatedNewPassword != f.newPassword
            </div> -->
            <div class="form-group" style="max-width: 400px; margin-left: auto; margin-right: auto;">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Change Password
                </button>
            </div>
        </form>
    </div>
</div>

<ng-template #incorrectLoginAndPassword let-close="close" style="margin-top: 30vh;">
    <div class="modal-header">
        <div>Incorrect combination of Login & Password</div>
        <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="close();clearForm()">
            Ok
        </button>
    </div>        
</ng-template>