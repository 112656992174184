<mat-card style="width: 35vw;margin-bottom: 2vh; margin-top: 2vh;">
  <mat-card-title style="margin-left: auto; margin-right: auto; text-align: center; align-items: center">{{ turnData.title }}</mat-card-title>
  <!-- <mat-card-subtitle>Fixtures</mat-card-subtitle> -->
  <!-- <div class="scrollable"> -->
  <!-- <div class="scrollable" style="height: 25vh">
    <div *ngFor="let fixture of sortBy(startDateUtc)">      
      <div style="margin-bottom: 3px; display: flex;">
        <div style="padding-top: 25px; padding-left: 10px; padding-right: 10px;">
          <mat-checkbox color = "primary" [(ngModel)]="fixture.isSelected"></mat-checkbox>
        </div> 
        <div style="display: grid; font-size: 12px;">
          <div> <span style="font-weight: 600;"> Fixture:  </span>{{ fixture.title }}  </div>
          <div> <span style="font-weight: 600;"> Fixture start:  </span>{{ fixture.startDateUtc| date:'HH:mm dd-MM-yyyy'  }}</div>
          <div> <span style="font-weight: 600;"> League name:  </span> {{ fixture.leagueName }} </div> 
          <div> <span style="font-weight: 600;"> League country:  </span>{{ fixture.leagueCountry }} </div>
        </div>
      </div>
      <div style="height: 6px;"></div>
    </div>
  </div> -->
<h1 class=".my-component " >
  <mat-accordion> 
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Fixtures
      </mat-expansion-panel-header>

        <mat-selection-list [multiple]="false" *ngFor="let fixture of sortBy('startDateUtc')" > 
          <div style="margin-bottom: 3px; display: flex;">
            <div style="padding-top: 25px; padding-left: 10px; padding-right: 10px;">
              <mat-checkbox color = "primary" [(ngModel)]="fixture.isSelected"></mat-checkbox>
            </div> 
            <div style="display: grid; font-size: 12px;">
              <div> <span style="font-weight: 600;"> Fixture:  </span>{{ fixture.title }} </div>
              <div> <span style="font-weight: 600;"> Fixture start:  </span> {{ fixture.startDateUtc| date:'HH:mm dd-MM-yyyy'  }} </div>
              <div> <span style="font-weight: 600;"> League name:  </span> {{ fixture.leagueName }} </div> 
              <div> <span style="font-weight: 600;"> League country:  </span> {{ fixture.leagueCountry }} </div>
            </div>
          </div>
          <div style="height: 6px;"></div>
        </mat-selection-list>

    </mat-expansion-panel>
  </mat-accordion>
</h1>

  <!-- <mat-card-subtitle style="margin-top: 3vh;">Events</mat-card-subtitle> -->
  <!-- <div class="scrollable" stye="height: 25vh" >
    <div *ngFor="let event of turnData.events" >      
      <div >
        <mat-checkbox color = "warn" [(ngModel)]="event.isSelected"></mat-checkbox>
        {{ event.eventName}}
      </div>  
    </div>
  </div> -->

  <mat-accordion> 
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Events
      </mat-expansion-panel-header>

        <mat-selection-list [multiple]="false" *ngFor="let event of turnData.events" > 
          <div >
            <mat-checkbox color = "warn" [(ngModel)]="event.isSelected"></mat-checkbox>
            {{ event.eventName}}
          </div>
        </mat-selection-list>

    </mat-expansion-panel>
  </mat-accordion>
  
  <!-- <div style="margin-top: 2vh;" >
    <div style="display: flex;" >
      <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto;margin-bottom: 1vh; ">
        <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
          <mat-label >Max Odds</mat-label>
          <input matInput type="number" [(ngModel)]="turnData.maxBetOdds">
        </mat-form-field>
      </div>

      <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh; ">
        <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
          <mat-label>Max Combined Odds</mat-label>
          <input matInput type="number" [(ngModel)]="turnData.maxCombinedOdds">
        </mat-form-field>
      </div>
      
    </div>
    <div style="display: flex;">


      <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh; ">
        <mat-form-field class="example-form-field" style="width: 10vw;text-align: center;">
          <mat-label>Max Coupons</mat-label>
          <input matInput type="number" [(ngModel)]="turnData.maxCoupons">
        </mat-form-field>
      </div>

      

      <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh; ">
        <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
          <mat-label>Max Bets</mat-label>
          <input matInput type="number" [(ngModel)]="turnData.maxBets">
        </mat-form-field>
      </div>
    </div>
    <div>
      <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh;">
        <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
          <mat-label>Turn Stack</mat-label>
          <input matInput type="number" [(ngModel)]="turnData.startingStack">
        </mat-form-field>
      </div>
    </div>
  </div> -->

  <mat-accordion> 
    <mat-expansion-panel>
      <mat-expansion-panel-header >
        Settings
      </mat-expansion-panel-header>
        <mat-selection-list [multiple]="false"> 
          <div style="margin-top: 2vh;" >
            <div style="display: flex;" >
              <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto;margin-bottom: 1vh; ">
                <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
                  <mat-label >Max Odds</mat-label>
                  <input matInput type="number" [(ngModel)]="turnData.maxBetOdds">
                </mat-form-field>
              </div>
              <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh; ">
                <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
                  <mat-label>Max Combined Odds</mat-label>
                  <input matInput type="number" [(ngModel)]="turnData.maxCombinedOdds">
                </mat-form-field>
              </div>
            </div>
            <div style="display: flex;">
              <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh; ">
                <mat-form-field class="example-form-field" style="width: 10vw;text-align: center;">
                  <mat-label>Max Coupons</mat-label>
                  <input matInput type="number" [(ngModel)]="turnData.maxCoupons">
                </mat-form-field>
              </div>
              <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh; ">
                <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
                  <mat-label>Max Bets</mat-label>
                  <input matInput type="number" [(ngModel)]="turnData.maxBets">
                </mat-form-field>
              </div>
            </div>
            <div>
              <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 1vh;">
                <mat-form-field class="example-form-field" style="width:10vw;text-align: center;">
                  <mat-label>Turn Stack</mat-label>
                  <input matInput type="number" [(ngModel)]="turnData.startingStack">
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-selection-list>

    </mat-expansion-panel>
  </mat-accordion>


  <div style="text-align: center; align-items: center; margin-left: auto; margin-right: auto; margin-bottom: 2vh;margin-top: 1vh;">
    <mat-card-actions >
      <!-- <div class="modal-footer">  -->
        <button type="button" class="btn btn-info" (click)="calendarService.addSettedUpTurn(turnData); onNoClick()">
          add to Tournament
        </button>
      <!-- </div> -->
    </mat-card-actions>
  </div>
</mat-card>

