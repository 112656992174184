import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config.service";
import { tap } from "rxjs/operators";
import { SpinnerService } from "./spinner.service";
import { LoggedInUser } from "../../modules/navigation/common/account.service";
import { environment } from "../../../environments/environment";
import { ApiUrlService } from "../menu/top-bar/top-bar.service";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigService,
    private apiUrlService: ApiUrlService // private spinnerService: SpinnerService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // this.spinnerService.show();
    const loggedInUser = <LoggedInUser>(
      JSON.parse(localStorage.getItem("loggedInUser"))
    );
    const isApiUrl = req.url.startsWith(environment[this.apiUrlService.env]);

    if (loggedInUser && loggedInUser.accessToken && isApiUrl) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${loggedInUser.accessToken}` },
      });
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // this.spinnerService.hide();
          }
        },
        (error) => {
          // this.spinnerService.hide();
        }
      )
    );
  }
}
