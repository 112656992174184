import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import {
  BasicService,
  TournamentHeader,
  TurnMetadata,
} from "../../common/basic.service";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Component({
  selector: "app-tournament-manager",
  templateUrl: "./tournament-manager.component.html",
  styleUrls: ["./tournament-manager.component.css"],
})
export class TournamentManagerComponent implements OnInit {
  @ViewChild("tournamentClose", { static: true })
  tournamentClose: TemplateRef<any>;
  @ViewChild("tournamentSettle", { static: true })
  tournamentSettle: TemplateRef<any>;
  activeTournaments: TournamentHeader[];
  closedTournaments: TournamentHeader[];
  settledTournaments: TournamentHeader[];

  constructor(
    private basicService: BasicService,
    private modal: NgbModal,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private apiUrlService: ApiUrlService
  ) {}

  modalData: {
    tournament: TournamentHeader;
  };

  intentionToCloseTournament(tournament: TournamentHeader): void {
    this.modalData = { tournament: tournament };
    this.modal.open(this.tournamentClose, { size: "lg" });
  }

  intentionToSettleTournament(tournament: TournamentHeader): void {
    this.modalData = { tournament: tournament };
    this.modal.open(this.tournamentSettle, { size: "lg" });
  }

  postTournamentForClosure(tournamentId: string): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] +
          `api/tournament/${tournamentId}/close`,
        ""
      )
      .toPromise();
  }

  postTournamentForSettle(tournamentId: string): Promise<void> {
    return this.http
      .post<void>(
        environment[this.apiUrlService.env] +
          `api/tournament/${tournamentId}/settle`,
        ""
      )
      .toPromise();
  }

  async closeTournament(tournamentId: string) {
    let s = true;
    await this.postTournamentForClosure(tournamentId).then(
      () => {},
      (error) => {
        this.openSnackBarNoClosure();
        s = false;
      }
    );
    if (s) {
      this.refreshView();
      this.openSnackBarClosure();
    }
  }

  async settleTournament(tournamentId: string) {
    let s = true;
    await this.postTournamentForSettle(tournamentId).then(
      () => {},
      (error) => {
        this.openSnackBarNoSettle();
        s = false;
      }
    );
    if (s) {
      this.refreshView();
      this.openSnackBarSettle();
    }
  }

  openSnackBarClosure() {
    this._snackBar.open("You successfully closed the tournament!", "Close", {
      duration: 4000,
    });
  }

  openSnackBarSettle() {
    this._snackBar.open("You successfully settled the tournament!", "Close", {
      duration: 4000,
    });
  }

  openSnackBarNoClosure() {
    this._snackBar.open("You can't close this tournament!", "Close", {
      duration: 4000,
    });
  }

  openSnackBarNoSettle() {
    this._snackBar.open("You can't settle this tournament!", "Close", {
      duration: 4000,
    });
  }

  refreshView() {
    this.basicService
      .getActiveTournaments()
      .pipe(
        map((tournamentsDto) => {
          let activeTournaments: TournamentHeader[] = [];
          for (let tournament of tournamentsDto) {
            let tournamentData = new TournamentHeader();
            tournamentData.id = tournament.id;
            tournamentData.status = tournament.status;
            tournamentData.name = tournament.name;
            tournamentData.betsCloseDateUtcForFirstTurn =
              tournament.betsCloseDateUtcForFirstTurn;
            tournamentData.betsOpenDateUtcForFirstTurn =
              tournament.betsOpenDateUtcForFirstTurn;
            tournamentData.registrationCloseDateUtc =
              tournament.registrationCloseDateUtc;
            tournamentData.registrationOpenDateUtc =
              tournament.registrationOpenDateUtc;
            tournamentData.entryFee = tournament.entryFee;
            tournamentData.startDateUtc = tournament.startDateUtc;
            tournamentData.endDateUtc = tournament.endDateUtc;
            for (let turn of tournament.turns) {
              let turnData = new TurnMetadata();
              turnData.id = turn.id;
              turnData.title = turn.title;
              turnData.status = turn.status;
              tournamentData.turns.push(turnData);
            }
            activeTournaments.push(tournamentData);
          }
          return activeTournaments;
        })
      )
      .subscribe((activeTournaments) => {
        this.activeTournaments = activeTournaments;
      });

    this.basicService
      .getClosedTournaments()
      .pipe(
        map((tournamentsDto) => {
          let closedTournaments: TournamentHeader[] = [];
          for (let tournament of tournamentsDto) {
            let tournamentData = new TournamentHeader();
            tournamentData.id = tournament.id;
            tournamentData.status = tournament.status;
            tournamentData.name = tournament.name;
            tournamentData.betsCloseDateUtcForFirstTurn =
              tournament.betsCloseDateUtcForFirstTurn;
            tournamentData.betsOpenDateUtcForFirstTurn =
              tournament.betsOpenDateUtcForFirstTurn;
            tournamentData.registrationCloseDateUtc =
              tournament.registrationCloseDateUtc;
            tournamentData.registrationOpenDateUtc =
              tournament.registrationOpenDateUtc;
            tournamentData.entryFee = tournament.entryFee;
            tournamentData.startDateUtc = tournament.startDateUtc;
            tournamentData.endDateUtc = tournament.endDateUtc;
            for (let turn of tournament.turns) {
              let turnData = new TurnMetadata();
              turnData.id = turn.id;
              turnData.title = turn.title;
              turnData.status = turn.status;
              tournamentData.turns.push(turnData);
            }
            if (tournamentData.status == "3") {
              closedTournaments.push(tournamentData);
            }
          }
          return closedTournaments;
        })
      )
      .subscribe((closedTournaments) => {
        this.closedTournaments = closedTournaments;
      });

    this.basicService
      .getClosedTournaments()
      .pipe(
        map((tournamentsDto) => {
          let settledTournaments: TournamentHeader[] = [];
          for (let tournament of tournamentsDto) {
            let tournamentData = new TournamentHeader();
            tournamentData.id = tournament.id;
            tournamentData.status = tournament.status;
            tournamentData.name = tournament.name;
            tournamentData.betsCloseDateUtcForFirstTurn =
              tournament.betsCloseDateUtcForFirstTurn;
            tournamentData.betsOpenDateUtcForFirstTurn =
              tournament.betsOpenDateUtcForFirstTurn;
            tournamentData.registrationCloseDateUtc =
              tournament.registrationCloseDateUtc;
            tournamentData.registrationOpenDateUtc =
              tournament.registrationOpenDateUtc;
            tournamentData.entryFee = tournament.entryFee;
            tournamentData.startDateUtc = tournament.startDateUtc;
            tournamentData.endDateUtc = tournament.endDateUtc;
            for (let turn of tournament.turns) {
              let turnData = new TurnMetadata();
              turnData.id = turn.id;
              turnData.title = turn.title;
              turnData.status = turn.status;
              tournamentData.turns.push(turnData);
            }
            if (tournamentData.status == "4") {
              settledTournaments.push(tournamentData);
            }
          }
          return settledTournaments;
        })
      )
      .subscribe((settledTournaments) => {
        this.settledTournaments = settledTournaments;
      });
  }

  ngOnInit(): void {
    this.refreshView();
  }
}
