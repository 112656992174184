import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { ConfigService } from "../../../infrastructure/config.service";
import {
  TournamentMetadata,
  SportEvent,
  TurnMetadata,
  FixtureMetadata,
} from "../../../modules/common/basic.service";
import { environment } from "../../../../environments/environment";
import { LeagueDto } from "../../common/basic.service";
import { ApiUrlService } from "src/app/infrastructure/menu/top-bar/top-bar.service";

@Injectable()
export class CalendarService {
  turnId: string | number;
  allTurnsSettedUp: boolean = false;
  tournamentMetadata: TournamentMetadata;
  selectedTurn: TurnMetadata;
  turnWasAdded: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private configService: ConfigService,
    private apiUrlService: ApiUrlService
  ) {}

  getAllPremierLeagueFixtures(): Observable<FixtureFromAPIDto[]> {
    return this.http.get<FixtureFromAPIDto[]>(
      environment[this.apiUrlService.env] + "api/fixtures/league/39"
    );
  }

  getAllPrimeiraLiga(): Observable<FixtureFromAPIDto[]> {
    return this.http.get<FixtureFromAPIDto[]>(
      environment[this.apiUrlService.env] + "api/fixtures/league/94"
    );
  }

  async checkFixtureOdds(
    fixtures: {
      startDateUtc: Date;
      id: string;
      leagueId: string;
    }[]
  ) {
    return await this.http
      .post<FixtureFromAPIDto[]>(
        environment[this.apiUrlService.env] +
          "api/fixtures/check-specified-odds",
        { fixtures }
      )
      .toPromise();
  }

  async getLeague(leagueId: string): Promise<FixtureFromAPIDto[]> {
    return await this.http
      .get<FixtureFromAPIDto[]>(
        environment[this.apiUrlService.env] + "api/fixtures/league/" + leagueId
      )
      .toPromise();
  }

  createTournament(
    startDate: Date,
    endDate: Date,
    openDate: Date,
    closeDate: Date,
    registrationCloseMargin: number,
    betsOpenMargin: number,
    betsCloseMargin: number,
    tournamentId: string
  ) {
    this.tournamentMetadata = new TournamentMetadata();
    this.tournamentMetadata.id = tournamentId;
    this.tournamentMetadata.startDateUtc = startDate;
    this.tournamentMetadata.registrationOpenDateUtc = openDate;
    this.tournamentMetadata.registrationCloseDateUtc = closeDate;
    this.tournamentMetadata.betsOpenMarginInMinutes = betsOpenMargin;
    this.tournamentMetadata.betsCloseMarginInMinutes = betsCloseMargin;
    this.tournamentMetadata.registrationCloseMarginInMinutes =
      registrationCloseMargin;
    this.tournamentMetadata.entryFee = 200;
    this.tournamentMetadata.endDateUtc = endDate;
    this.tournamentMetadata.turns = [];
  }

  async setSelectedTurn(
    turn: TurnMetadata,
    inRangeFixtures: FixtureMetadata[]
  ) {
    let matchingTurn = this.tournamentMetadata.turns.find(
      (turn1) => turn.id == turn1.id
    );
    if (matchingTurn) {
      this.selectedTurn = matchingTurn;
      return;
    }
    let newTurn = new TurnMetadata();
    newTurn.id = turn.id;
    newTurn.startDateUtc = turn.startDateUtc;
    newTurn.title = turn.title;
    newTurn.endDateUtc = turn.endDateUtc;
    newTurn.maxBetOdds = 6;
    newTurn.maxCoupons = 3;
    newTurn.maxBets = 3;
    newTurn.maxCombinedOdds = 15;
    newTurn.startingStack = 1000;
    newTurn.fixtures = inRangeFixtures;
    newTurn.events = await this.getSportEvents();
    this.selectedTurn = newTurn;
  }
  addTurnSelectedTurntoTournament() {
    this.tournamentMetadata.turns.push(this.selectedTurn);
  }

  returnFixturesForMetaevent(id: string | number) {
    let turn = this.tournamentMetadata.turns.find((turn1) => id == turn1.id);
    return turn.fixtures;
  }

  uncheckAllEvents() {
    for (let i = 0; i <= this.tournamentMetadata.turns.length; i++) {
      for (
        let j = 0;
        j <= this.tournamentMetadata.turns[i].events.length;
        j++
      ) {
        this.tournamentMetadata.turns[i].events[j].isSelected = false;
      }
    }
  }

  async getSportEvents(): Promise<SportEvent[]> {
    let allowedEventsNames = await this.configService.getAllowedEventsNames();
    let sportEvents: SportEvent[] = [];
    for (let allowedEventName of allowedEventsNames) {
      let sportEvent = new SportEvent();
      sportEvent.eventName = allowedEventName;
      sportEvent.isSelected = true;
      sportEvents.push(sportEvent);
    }
    return sportEvents;
  }

  eraseTournament() {
    this.tournamentMetadata = null;
  }

  addSettedUpTurn(turnData: TurnMetadata) {
    if (this.tournamentMetadata.turns.find((turn) => turnData.id == turn.id)) {
      this.tournamentMetadata.turns = this.tournamentMetadata.turns.filter(
        (event) => event !== turnData
      ); // I am not sure if this is correct approach
    }
    this.tournamentMetadata.turns.push(turnData);
    this.turnWasAdded = true;
  }

  getSelectedTurnData(): TurnMetadata {
    console.log("here 33");
    return this.selectedTurn;
  }

  returnTournamentMetadata() {
    return this.tournamentMetadata;
  }

  postTournament(tournamentData: TournamentMetadata) {
    tournamentData;
  }

  getLeaguesList(): Promise<League[]> {
    return this.http.get<League[]>("/assets/leaguesList.json").toPromise();
  }
}

export class League implements LeagueDto {
  id: string;
  name: string;
  country: string;
  isSelected: boolean;
}

export class FixtureFromAPIDto {
  id: string;
  name: string;
  season: string;
  localTeamName: string;
  status: string;
  leagueId: string;
  leagueName: string;
  visitorTeamName: string;
  fulltimeAwayGoals: number;
  fulltimeHomeGoals: number;
  halftimeAwayGoals: number;
  halftimeHomeGoals: number;
  startingAtUnixTimestampInMilliseconds: number;
  standingsLocalTeamPosition: number;
  standingsVisitorTeamPosition: number;
}
